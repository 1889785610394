import { GenericResponseModel } from './../../../models/utilities/generic.response.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { PolicyModel } from 'models/entities/policy-model';
import { RemissionModel } from 'models/entities/remission-model';
import { RemissionComponent } from 'app/printing/remission/remission.component';
import swal from 'sweetalert2';

@Component({
  selector: 'app-list-remissions',
  templateUrl: './list-remissions.component.html',
  styleUrls: ['./list-remissions.component.scss']
})
export class ListRemissionsComponent implements OnInit {

  @Input() policyModel: PolicyModel = new PolicyModel();
  remissionsList: Array<RemissionModel> = new Array();

  remissionColumns = [
    { prop: 'expeditionDate' },
    { prop: 'id' },
    { prop: 'dueDate' },
    { prop: 'totalValue' }
  ]

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.getRemissionsList();
  }

  getRemissionsList() {
    const pathRemissionsListUrl = 'remision/listar-remisiones-por-poliza?policyId=' + this.policyModel.id;
    this.remissionsList = new Array();
    this.crudService.getModel(pathRemissionsListUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.remissionsList = genericResponse.answerList;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las remisiones',
            'Problema consultando las remisiones');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al consultar las remisiones: ' + JSON.stringify(error))
      }
    );
  }

  cancelRemission(remissionToCancel: RemissionModel) {
    swal({
      title: '¿Está seguro que desea anular la remisión?',
      text: 'Esta opción no podrá reversarse luego',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, anular!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        remissionToCancel.state = 'CANCELED'
        this.crudService.createModel('remision/crear-remision', remissionToCancel).subscribe(
          (genericResponse: GenericResponseModel) => {
            if (genericResponse.code === 200) {
              this.messageService.getInfoMessagePersonalized('Success', 'Cancelación exitosa',
                'El proceso de cancelación fue exitoso');
              this.getRemissionsList();
            } else {
              this.messageService.getInfoMessagePersonalized('warning', 'No se pudo guardar el recaudo con exito',
                'Problema creando el recaudo');
            }
          }, (error) => {
            this.messageService.getInfoMessageError();
            console.error('Error, no se pudo guardar el recaudo con exito: ' + JSON.stringify(error))
          }
        )
      }
    })
  }

  printRemission(remissionToPrint: RemissionModel) {
    const modal = this.modalService.open(RemissionComponent, {
      windowClass: 'printingModal', size: 'lg', backdrop: 'static'
    });
    modal.componentInstance.policyId = this.policyModel;
    modal.componentInstance.remissionModel = remissionToPrint;
    modal.componentInstance.isPrinting = true;
    modal.componentInstance.passEntry.subscribe(() => {
      modal.dismiss();
    });
  }

}
