import { ManageClientsComponent } from './manage-clients/manage-clients.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxLoadingModule } from 'ngx-loading';
import { SharedComponentsModule } from './../shared/components/shared-components.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActivitiesRoutingModule } from './activities-routing.module';
import { CreateActivityComponent } from './create-activity/create-activity.component';
import { FormsModule } from '@angular/forms';
import { NGXFormWizardModule } from 'app/forms/ngx-wizard/ngx-wizard.module';
import { ArchwizardModule } from 'angular-archwizard';
import { CustomFormsModule } from 'ng2-validation';
import { MatchHeightModule } from 'app/shared/directives/match-height.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ManagmentActivityComponent } from './managment-activity/managment-activity.component';
import { ListActivitysComponent } from './list-activitys/list-activitys.component';
import { CrudUserModule } from 'app/usercomponents/crud-user.module';
import { PoliciesModule } from 'app/policies/policies.module';
import { CruClientComponent } from './cru-client/cru-client.component';
import { ListActivityManagmentComponent } from './list-activity-managment/list-activity-managment.component';
import { CreateActivityManagmentComponent } from './create-activity-managment/create-activity-managment.component';
import { ViewActivityComponent } from './view-activity/view-activity.component';



@NgModule({
  declarations: [CreateActivityComponent, ManagmentActivityComponent, ListActivitysComponent,ManageClientsComponent, CruClientComponent, ListActivityManagmentComponent, CreateActivityManagmentComponent, ViewActivityComponent],
  imports: [
    CommonModule,
    ActivitiesRoutingModule,
    FormsModule,
    NGXFormWizardModule,
    ArchwizardModule,
    CustomFormsModule,
    MatchHeightModule,
    NgbModule,
    NgxDatatableModule,
    NgxLoadingModule,
    SharedComponentsModule,
    PoliciesModule
    
   
    ],
  exports: [
    ListActivitysComponent
  ],
  entryComponents: [
    ListActivitysComponent
    
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ActivitiesModule { }
