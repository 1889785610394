import { TokenStorageService } from './../../shared/storage-services/token-storage.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivityModel } from 'models/entities/activity';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { ActivityManagmentModel } from 'models/entities/activity-managment';
import swal from 'sweetalert2';
import { ActivityStateModel } from 'models/entities/activity-state.model';
import { RelationalFileModel } from 'models/utilities/relational-type-model';

@Component({
  selector: 'app-view-activity',
  templateUrl: './view-activity.component.html',
  styleUrls: ['./view-activity.component.scss']
})
export class ViewActivityComponent implements OnInit {
  @Input() activity=new ActivityModel();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  listActivitys: Array<ActivityManagmentModel> = new Array();
  public listUsers: any [];
  public  responsableActual;
  status:boolean=false;
  public activityManagmentModel=new ActivityManagmentModel();
  public user;
  listStates:Array<ActivityStateModel> = new Array();  
  public relationalModel: RelationalFileModel = new RelationalFileModel();



  constructor(private messageCtrl:InfoMessagesService,
    private serviceCtrl:CrudServiceService,
    private tokenServiceCtrl:TokenStorageService) { }

  ngOnInit() {
    this.relationalModel.relationalId = this.activity.id;
    this.relationalModel.relationalType = 'ACTIVITY'
    this.relationalModel.relationalFileType = 'ARCHIVO';
    this.relationalModel.relationalRestrictions = new Array();
    this.getUsers();
    this.getStatusActivity();
    this.responsableActual=this.activity.responsibleUserId.completeName;
    this.user=this.activity.responsibleUserId;
    let newDate=new Date();
    let oldDate=new Date(this.activity.expireDate);
    debugger;
    if(oldDate<newDate){
      this.status=true;
    }
    
  }

  getStatusActivity(){
    let path='empresa/estado-actividad/listar-estado-actividad-no-finalizadora';
      this.serviceCtrl.getModel(path).subscribe(
        result=>{
        
          if(result.code===200)
          {
            this.listStates=result.answerList        
          }
        },
        error=>{
         this.messageCtrl.getInfoMessageDelete();
        }
      )
    }

  updateResponsable(){
    swal({
      title: 'Esta seguro que desea reasignar esta actividad?',
      text: "No puedes revertir esta acción despues!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, reasignar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        let path='actividad/crear'

        this.serviceCtrl.createModel(path,this.activity).subscribe(
          result=>{
      
            if(result.code===200)
            {

              this.createComent();
              swal(
                'Actividad reasignada!',
                'La actividad ha sido reasignada.',
                'success'
              )
            }
            else
            {
              this.messageCtrl.getInfoMessageError();
            }
      
          },
          error=>{
            this.messageCtrl.getInfoMessageError();
          }
        )
      }
    })
  }


  validateStatus(id)
  {
    let flag=true;

    this.listStates.forEach(element => {
      if(element.id===id)
      {
        flag=false;
      }
    });

    return flag;
  }

  

  createComent(){
    this.activityManagmentModel.coment='Reasignación de actividad del usuario '+this.responsableActual+ 'al usuario '+this.activity.responsibleUserId.completeName
    this.activityManagmentModel.activityId=this.activity;
    this.activityManagmentModel.creatorId=this.user;
    debugger;
    let path='gestion-actividad';
    this.serviceCtrl.createModel(path,this.activityManagmentModel).subscribe(
      result=>{
        if(result.code===200)
        {
          
         this.activityManagmentModel=new ActivityManagmentModel();
        }
      },
      error=>{
       this.messageCtrl.getInfoMessageError();
      }
    )
  }

  getUsers(){
    let path='usuarios/listar-vendedores-y-gestores';
    this.serviceCtrl.getModel(path).subscribe(
      result=>{
        if(result.code===200)
        {
         this.listUsers=result.answerList;
        }
      },
      error=>{
       this.messageCtrl.getInfoMessageDelete();
      }
    )
  }

  close(){
    this.passEntry.emit('close');
  }

  updateStatus()

  {
    swal({
      title: 'Esta seguro que desea finalizar la actividad?',
      text: "No puedes revertir esta acción despues!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, finalizar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        
        let path='actividad/actualizar-estado'

        this.serviceCtrl.createModel(path,this.activity).subscribe(
          result=>{
      
            if(result.code===200)
            {
              swal(
                'Estado de actividad actualizado!',
                'La actividad ha pasado a un estado finalizado.',
                'success'
              )
              this.passEntry.emit('close');
            }
            else
            {
              this.messageCtrl.getInfoMessageError();
            }
      
          },
          error=>{
            this.messageCtrl.getInfoMessageError();
          }
        )
        
      }
    })
    
  }

  getActivitysComents(){
    let path='gestion-actividad?activityId='+this.activity.id;
    this.serviceCtrl.getModel(path).subscribe(
      result=>{
       
        if(result.code===200)
        {
           this.listActivitys=result.answerList;
        }
      }
      ,error=>{
        this.messageCtrl.getInfoMessageError();
      }
    )
  }

  getAction(evt){
    
        this.getActivitysComents();

  }

}
