import {ClassOfInsuranceModel} from 'models/entities/class-of-insurance.model';
import {InsurerCommissiomModel} from 'models/entities/insureance-commission-model';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BudgetModel} from 'models/entities/budget-model';
import {PayStyleModel} from 'models/entities/pay-style-model';
import {NgbModal, NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Subject} from 'rxjs';
import {InfoMessagesService} from 'app/shared/messages/info-messages.service';
import {CrudServiceService} from 'app/shared/backend/cruds/crud-service.service';
import {GenericResponseModel} from 'models/utilities/generic.response.model';
import {debounceTime, distinctUntilChanged, filter, map, merge} from 'rxjs/operators';
import {CustomPayStyleModalComponent} from "../custom-pay-style-modal/custom-pay-style-modal.component";
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';

@Component({
  selector: 'app-budget-in-policy',
  templateUrl: './budget-in-policy.component.html',
  styleUrls: ['./budget-in-policy.component.scss']
})
export class BudgetInPolicyComponent implements OnInit {
  @Input() budgetModel: BudgetModel = new BudgetModel();
  @Input()
  classOfInsuranceSelected: ClassOfInsuranceModel = new ClassOfInsuranceModel();
  @Input() readonly = false;
  listPayStyles: Array<PayStyleModel> = new Array();
  listComissionsByClassOfInsurance: Array<InsurerCommissiomModel> = new Array();
  @ViewChild('instancePayStyle') instancePayStyle: NgbTypeahead;
  focusPayStyle$ = new Subject<string>();
  clickPayStyle$ = new Subject<string>();

  customPayStyle: PayStyleModel = new PayStyleModel();
  inputType = 'search';

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private modalService: NgbModal,
    private tokenStorage: TokenStorageService
  ) { }

  ngOnInit() {
    this.customPayStyle.id = '-1';
    this.customPayStyle.style = 'Otro';
    this.getPayStyles();
    this.getInsurerComissions();
  }

  getInsurerComissions() {
    const pathClassOfInsurance = 'empresa/listar-comision-ramo-empresa';
    this.crudService.getModel(pathClassOfInsurance).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          console.log(genericResponse.answerList);
          this.listComissionsByClassOfInsurance = genericResponse.answerList;
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessageError();
      }
    );
  }

  getPayStyles() {
    const companyId = this.tokenStorage.getCompanyId();
    const pathListPayStylesUrl = `estios-de-pago/listar-modos-de-pago-empresa?id=${companyId}`;
    this.listPayStyles = new Array();
    this.crudService.getModel(pathListPayStylesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listPayStyles = genericResponse.answerList;
          this.listPayStyles.push(this.customPayStyle);
        } else {
          this.messageService.getInfoMessagePersonalized(
            'warning',
            'No se pudieron listar las formas de pago del sistema',
            'Problema consultando las formas de pago del sistema'
          );
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error(
          'Error al cargar consultar las formas de pago del sistema ' +
          JSON.stringify(error)
        );
      }
    );
  }

  updatePorcents() {
    if (
      this.listComissionsByClassOfInsurance.find(insCommission => {
        return (
          insCommission.nameClassOfInsurance.id ===
          this.classOfInsuranceSelected.id
        );
      }) != undefined
    ) {
      const insurerCommissionSelected: InsurerCommissiomModel = this.listComissionsByClassOfInsurance.find(
        insCommission => {
          return (
            insCommission.nameClassOfInsurance.id ===
            this.classOfInsuranceSelected.id
          );
        }
      );
      this.budgetModel.companyCommission = insurerCommissionSelected.agencyPercentage + '';
      this.budgetModel.sellersCommission = insurerCommissionSelected.administrationPercentage + '';
    }
  }

  searchByNamePayStyle = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusPayStyle$),
      merge(
        this.clickPayStyle$.pipe(
          filter(() => !this.instancePayStyle.isPopupOpen())
        )
      ),
      map(search =>
        (search === ''
          ? this.listPayStyles
          : this.listPayStyles.filter(
            payStyle =>
              payStyle.style.toLowerCase().indexOf(search.toLowerCase()) > -1
          )
        ).slice(0, 10)
      )
    );

  formatterPayStyle = (object: { style: string }) => object.style;

  currencyInputChanged(value) {
    const num = value.replace(/[$,]/g, '');
    return Number(num);
  }

  calculateTotals() {
    this.budgetModel.totalIva =
      +this.budgetModel.premium * (+this.budgetModel.iva / 100) +
      +this.budgetModel.spendings * (+this.budgetModel.iva / 100) +
      '';

    this.budgetModel.totalWithIva =
      +this.budgetModel.premium +
      +this.budgetModel.spendings +
      +this.budgetModel.totalIva +
      '';
  }

  percentInputChanged(value) {
    const num = value.replace(/[$%]/g, '');
    if (num !== '0') {
      return Number(num);
    } else {
      return 0;
    }
  }

  containsValidation() {
    for (const payStyle of this.listPayStyles) {
      if (payStyle.id === this.budgetModel.payStyleId.id) {
        return true;
      }
    }
  }

  handlePayStyleChange() {
    if (this.budgetModel.payStyleId.id != '-1') {
      return;
    }
    const modal = this.modalService.open(CustomPayStyleModalComponent, {
      windowClass: 'modal', backdrop: 'static'
    });
    const modalSubscript = modal.componentInstance.emitter.subscribe(newPayStyle => {
      if (!newPayStyle) {
        modalSubscript.unsubscribe();
        return;
      }
      const payStylesList = this.listPayStyles.slice(0, this.listPayStyles.length - 1);
      this.listPayStyles = [...payStylesList, newPayStyle, this.customPayStyle];
      this.instancePayStyle.dismissPopup();
      document.getElementById('premium').focus();
      this.budgetModel.payStyleId = newPayStyle;
      modalSubscript.unsubscribe();
    });
  }
}
