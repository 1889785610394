import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { formatDate } from '@angular/common';
import { HappyBirthdayModel } from 'models/entities/happy-birthday.model';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { ExportExcelService } from 'app/shared/excel/export-excel.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-happy-birth-day-report',
  templateUrl: './happy-birth-day-report.component.html',
  styleUrls: ['./happy-birth-day-report.component.scss']
})
export class HappyBirthDayReportComponent implements OnInit {
  listClients: any[];
  listManagers: any[];
  listSellers: any[];
  listManagersAndSellers: any[];
  @Input() initialDate = '';
  @Input() finalDate = '';
  date = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  @Input() todayDate = this.date;
  @Input() isReminder = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
 

  row = [];
  rows = Array<HappyBirthdayModel>();
  rowsAux = Array<any>();
  temp: string[];
  errorMessage: string;
  dato: any;

  columns = [
    { prop: 'name', width: 200, name: 'Nombre Razon Social' },
    { prop: 'phone', width: 200, name: 'Telefono' },
    { prop: 'address', width: 300, name: 'Direccion' },
    { prop: 'email', width: 300, name: 'Correo electronico' },
    { prop: 'cellphone', width: 150, name: 'Celular' },
    { prop: 'sellerUserId', width: 150, name: 'Vendedor' },
    { prop: 'birthday', width: 150, name: 'Fecha de cumpleaños' }
  ];

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService,
    private excelService: ExportExcelService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.getManagersAndSellersByBirthday(this.todayDate);
    }, 200);
    setTimeout(() => {
      this.getClientsByBirthday(this.todayDate);
    }, 200);
  }

  createReport() {
   this.rows = new Array();
   setTimeout(() => {
     this.getManagersAndSellersByBetweenDates(this.initialDate, this.finalDate);
   }, 500);
   setTimeout(() => {
     this.getClientsByBetweenDates(this.initialDate, this.finalDate);
   }, 500);
  }

  exportReport() {
    this.rowsAux = new Array();
    const results = this.rows;
    results.forEach( x => {
      if (x.sellerUserId === null) {
        this.rowsAux.push({
          'Nombre': x.name,
          'Dirección': x.address,
          'Fecha de nacimiento': x.birthday,
          'Celular': x.cellphone,
          'Correo electrónico': x.email,
          'Teléfono': x.phone,
          'Vendedor': 'N/A',
          'Fecha de cumpleaños': x.birthday
        });
      } else {
        this.rowsAux.push({
          'Nombre': x.name,
          'Dirección': x.address,
          'Fecha de nacimiento': x.birthday,
          'Celular': x.cellphone,
          'Correo electrónico': x.email,
          'Teléfono': x.phone,
          'Vendedor': x.sellerUserId.username,
          'Fecha de cumpleaños': x.birthday
        });
      }
    });
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rowsAux);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'Reporte de cumpleaños ' + this.tokenStorage.getCompanyName());
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
 }

 getManagersAndSellersByBirthday(_birthday: any) {
  const path = 'reportes/listar-usuarios-vendedores-gestores-por-fecha-nacimiento?birthday=' + _birthday;
  this.crudService.getModel(path).subscribe(
    (genericResponse: GenericResponseModel) => {
      if (genericResponse.code === 200) {
        this.rows = genericResponse.answerList;
        setTimeout(() => {
          if (this.rows.length === 0) {
            this.close('accept');
          } else {
            this.rows = [...this.rows]
          }
        }, 100);
      } else {
        this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los gestores y vendedores asociados a su empresa',
          'Problema consultando los  gestores y vendedores asociados a su empresa');
      }
    },
    error => {
      this.messageService.getInfoMessageError();
      console.error('Error al cargar los  gestores y vendedores asociados a su empresa ' + JSON.stringify(error))
    }
  );
 }

 getClientsByBirthday(_birthday: string) {
    const path = 'reportes/listar-clientes-por-fecha-nacimiento?birthday=' + _birthday;
    this.crudService.getModel(path).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          genericResponse.answerList.forEach((c: any) => {
            this.rows.push(c)
          });
          this.rows = [...this.rows]
        } else {
          // tslint:disable-next-line:max-line-length
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los gestores y vendedores asociados a su empresa',
            'Problema consultando los  gestores y vendedores asociados a su empresa');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los  gestores y vendedores asociados a su empresa ' + JSON.stringify(error))
      }
    );
  }

 getManagersAndSellersByBetweenDates(_initialDate: any, _finalDate: any) {
    // Se separan los meses y días de la fecha de inicio
    const iMonth = _initialDate.split('-')[1];
    const iDay = _initialDate.split('-')[2];
    // Se separan los meses y días de la fecha final
    const fMonth = _finalDate.split('-')[1];
    const fDay = _finalDate.split('-')[2];
    // Si iMonth < fMonth && iDay < fDay, es la consulta normal
    if (fMonth >= iMonth && fDay > iDay) {
      const path = 'reportes/listar-usuarios-vendedores-gestores-entre-fechas?initialMonth=' + iMonth + '&finalMonth=' + fMonth +
      '&initialDay=' + iDay + '&finalDay=' + fDay;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            genericResponse.answerList.forEach((c: any) => {
              this.rows.push(c);
            });
            this.rows = [...this.rows]
          } else {
            // tslint:disable-next-line:max-line-length
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los gestores y vendedores asociados a su empresa',
              'Problema consultando los  gestores y vendedores asociados a su empresa');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar los  gestores y vendedores asociados a su empresa ' + JSON.stringify(error))
        }
      );
    } else if (fMonth < iMonth && fDay > iDay)  {
      setTimeout(() => {
        const path1 = 'reportes/listar-usuarios-vendedores-gestores-entre-fechas?initialMonth=' + iMonth + '&finalMonth=' + '12' +
        '&initialDay=' + iDay + '&finalDay=' + fDay;
        this.crudService.getModel(path1).subscribe(
          (genericResponse: GenericResponseModel) => {
            if (genericResponse.code === 200) {
              genericResponse.answerList.forEach((c: any) => {
                this.rows.push(c);
              });
              this.rows = [...this.rows]
            } else {
              // tslint:disable-next-line:max-line-length
              this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los gestores y vendedores asociados a su empresa',
                'Problema consultando los  gestores y vendedores asociados a su empresa');
            }
          },
          error => {
            this.messageService.getInfoMessageError();
            console.error('Error al cargar los  gestores y vendedores asociados a su empresa ' + JSON.stringify(error))
          }
        );
      }, 200);
      setTimeout(() => {
        const path2 = 'reportes/listar-usuarios-vendedores-gestores-entre-fechas?initialMonth=' + '01' + '&finalMonth=' + fMonth +
        '&initialDay=' + iDay + '&finalDay=' + fDay;
        this.crudService.getModel(path2).subscribe(
          (genericResponse: GenericResponseModel) => {
            if (genericResponse.code === 200) {
              genericResponse.answerList.forEach((u: any) => {
                this.rows.push(u);
              });
              this.rows = [...this.rows]
            } else {
              // tslint:disable-next-line:max-line-length
              this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los gestores y vendedores asociados a su empresa',
                'Problema consultando los  gestores y vendedores asociados a su empresa');
            }
          },
          error => {
            this.messageService.getInfoMessageError();
            console.error('Error al cargar los  gestores y vendedores asociados a su empresa ' + JSON.stringify(error))
          }
        );
      }, 200);
    }
  }

  getClientsByBetweenDates(_initialDate: any, _finalDate: any) {
    // this.rows = new Array();
     // Se separan los meses y días de la fecha de inicio
     const iMonth = _initialDate.split('-')[1];
     const iDay = _initialDate.split('-')[2];
     // Se separan los meses y días de la fecha final
     const fMonth = _finalDate.split('-')[1];
     const fDay = _finalDate.split('-')[2];
     // Si iMonth < fMonth && iDay < fDay, es la consulta normal
     if (fMonth >= iMonth && fDay > iDay) {
       const path = 'reportes/listar-clientes-entre-fechas?initialMonth=' + iMonth + '&finalMonth=' + fMonth +
       '&initialDay=' + iDay + '&finalDay=' + fDay;
       this.crudService.getModel(path).subscribe(
         (genericResponse: GenericResponseModel) => {
           if (genericResponse.code === 200) {
             // this.rows = new Array();
             genericResponse.answerList.forEach((c: any) => {
              this.rows.push(c);
            });
             this.rows = [...this.rows]
           } else {
             // tslint:disable-next-line:max-line-length
             this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los clientes asociados a su empresa',
               'Problema consultando los clientes asociados a su empresa');
           }
         },
         error => {
           this.messageService.getInfoMessageError();
           console.error('Error al cargar los  clientes asociados a su empresa ' + JSON.stringify(error))
         }
       );
     } else if (fMonth < iMonth && fDay > iDay)  {
       // this.rows = new Array();
       setTimeout(() => {
         const path1 = 'reportes/listar-clientes-entre-fechas?initialMonth=' + iMonth + '&finalMonth=' + '12' +
         '&initialDay=' + iDay + '&finalDay=' + fDay;
         this.crudService.getModel(path1).subscribe(
           (genericResponse: GenericResponseModel) => {
             if (genericResponse.code === 200) {
              genericResponse.answerList.forEach((c: any) => {
                this.rows.push(c);
              });
               this.rows = [...this.rows]
               // console.log('Date Range: ' + iMonth + '-> ' + '12');
               // console.log(genericResponse.answerList);
             } else {
               // tslint:disable-next-line:max-line-length
               this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los clientes asociados a su empresa',
                 'Problema consultando los  gestores y vendedores asociados a su empresa');
             }
           },
           error => {
             this.messageService.getInfoMessageError();
             console.error('Error al cargar los clientes asociados a su empresa ' + JSON.stringify(error))
           }
         );
       }, 200);
       setTimeout(() => {
         const path2 = 'reportes/listar-usuarios-vendedores-gestores-entre-fechas?initialMonth=' + '01' + '&finalMonth=' + fMonth +
         '&initialDay=' + iDay + '&finalDay=' + fDay;
         this.crudService.getModel(path2).subscribe(
           (genericResponse: GenericResponseModel) => {
             if (genericResponse.code === 200) {
               genericResponse.answerList.forEach((u: any) => {
                 this.rows.push(u);
                });
                this.rows = [...this.rows]
             } else {
               // tslint:disable-next-line:max-line-length
               this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los clientes asociados a su empresa',
                 'Problema consultando los clientes asociados a su empresa');
             }
           },
           error => {
             this.messageService.getInfoMessageError();
             console.error('Error al cargar los clientes asociados a su empresa ' + JSON.stringify(error))
           }
         );
       }, 200);
     }
  }

  close(answer: string) {
    this.passEntry.emit(answer);
    this.activeModal.close();
  }
}
