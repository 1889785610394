import { Component, OnInit, ViewChild, Output, EventEmitter, Input, TemplateRef } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { SelectionType, ColumnMode } from '@swimlane/ngx-datatable';
import { formatDate } from '@angular/common';
import { CompanyModel } from 'models/entities/company-model';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { CommissionModel } from 'models/entities/commission.model';

@Component({
  selector: 'app-result-commissions',
  templateUrl: './result-commissions.component.html',
  styleUrls: ['./result-commissions.component.scss']
})
export class ResultCommissionsComponent implements OnInit {

  @ViewChild('f') createActivityStateForm: NgForm;
  @Output() passEntry: EventEmitter< any> = new EventEmitter();
  @Input() rows: any;
  companyModel: CompanyModel = new CompanyModel();
  commissionModel: CommissionModel = new CommissionModel();

  form: any = {};
  company: any;

  createInsurerModel: FormGroup;

  loadingIndicator = true;

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  enableSummary = true;
  summaryPosition = 'bottom';

  @ViewChild('customSummaryCell')
  customSummaryCell: TemplateRef<any>;

  premiumCalculatedSum = 0;
  retefuenteSum = 0;
  icaSum = 0;
  creeSum = 0;
  ivaSum = 0;
  commissionValueSum = 0;
  commissionCalculatedSum = 0;
  total = 'TOTAL';

  date: any;

  columnsCommission = [
    { prop: 'installment', width: 100 , name: 'Id Cuota'},
    { prop: 'insurerId.name', width: 200 , name: 'Aseguradora'},
    { prop: 'policyId.policy', width: 200, name: 'Poliza' },
    { prop: 'attachedId.type', width: 200, name: 'Anexo' },
    { prop: 'premium', width: 300, name: 'Saldo' },
    { prop: 'insuredId.nameOrBusinessName', width: 300, name: 'Asegurado' },
    { prop: 'classofInsuranceId.name', width: 150, name: 'Aseguradora' },
    { prop: 'sellerId', width: 150, name: 'Vendedor' },
    { prop: 'commissionPercentage', width: 150, name: '% comisión' },
    { prop: 'agencyPercentage', width: 300, name: '% agencia' },
    { prop: 'premiumCalculated', width: 300, name: 'Abono prima' },
    { prop: 'commissionValue', width: 300, name: 'Valor comisión' },
    { prop: 'retefuentePercentage', width: 300, name: 'Retefuente 11%' },
    { prop: 'iva', width: 300, name: 'IVA' },
    { prop: 'icaPercentage', width: 300, name: 'ICA 0%' },
    { prop: 'creePercentage', width: 300, name: 'CREE 0%' },
    { prop: 'commissionCalculated', width: 300, name: 'Neto Comisión' },
    { prop: 'documentTypeId', width: 300, name: 'Tipo de documento' },
    { prop: 'TOTAL', summaryFunc: null },
  ];

  constructor(
    private activeModal: NgbActiveModal,
    private config: NgbModalConfig,
    public tokenServiceCtrl: TokenStorageService,
    private crudService: CrudServiceService,
    public tokenStorage: TokenStorageService,
    private messageService: InfoMessagesService,
  ) {
    this. date = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  }


  ngOnInit() {
    this.config.backdrop = 'static';
    this.config.keyboard = false;
    this.calculateSumm();
    this.getCompanyData();
  }

  close() {
    this .activeModal.close();
  }

  emptySumm() {
    return null;
  }

  getCompanyData() {
    const pathCompanyUrl = 'empresa/compania/ver-compania?id=' + this.tokenStorage.getCompanyId();
    this.crudService.getModel(pathCompanyUrl).subscribe(
      data => {
        this.companyModel = data.genericObject;
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar la compañia: ' + error)
      }
    );
  }

  calculateSumm() {
    this.rows.forEach((item) => {
      this.premiumCalculatedSum += +item.premiumCalculated;
      this.commissionValueSum += item.commissionValue;
      this.retefuenteSum += item.retefuentePercentageCalculated;
      this.ivaSum += item.iva;
      this.icaSum += item.icaPercentageCalculated;
      this.creeSum += item.creePercentageCalculated;
      this.commissionCalculatedSum += item.commissionCalculated;
    });
  }

  generatePayments() {
    this.rows.forEach((item) => {
      this.updateInstallment(item);
      this.saveCommission(item);
    });
    this.messageService.getInfoMessageCreate();
  }

  updateInstallment(item: any) {
    this.crudService.putModelParams('cuotas/actualizar-cuota-sin-iva', {}, {
      paymentValue: +item.premiumCalculated, 
      installmentId: item.installmentId
    }).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.close();
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo actualizar la cuota',
            'Problema creando la cuota');
        }
      }, (error) => {
        this.messageService.getInfoMessageError();
        console.error('Error, no se pudo actualizar la cuota con exito: ' + JSON.stringify(error))
      }
    )
  }

  saveCommission(commission: any) {
    const date = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    const path = 'comisiones/guardar-comision';
    this.commissionModel.installmentId = commission.installmentId;
    this.commissionModel.administrationPercentage = commission.commissionPercentage;
    this.commissionModel.agencyPercentage = commission.agencyPercentage;
    this.commissionModel.attachedId = {id: commission.attachedId.id};
    this.commissionModel.policyId =  {id: commission.policyId.id};
    this.commissionModel.classOfInsuranceId =  {id: commission.classOfInsuranceId.id};
    this.commissionModel.insurerId = {id: commission.insurerId.id};
    this.commissionModel.insuredId = {id: commission.insuredId.id};
    this.commissionModel.premium = commission.premium;
    this.commissionModel.sellerUserId = {id: commission.sellerId.id};
    this.commissionModel.createdDate = date;
    this.commissionModel.commissionCalculated = commission.commissionCalculated;
    this.commissionModel.commissionAdministrationCalculated = commission.commissionValue;
    this.commissionModel.premiumCalculated = commission.premiumCalculated;
    this.commissionModel.retefuentePercentageCalculated = commission.retefuentePercentage;
    this.commissionModel.icaPercentageCalculated = commission.icaPercentage;
    this.commissionModel.creePercentageCalculated = commission.creePercentage;
    this.commissionModel.ivaCalculated = commission.iva;

    this.crudService.createModel(path, this.commissionModel).subscribe(
      (data: GenericResponseModel) => {
        if (data.code === 200) {
          console.log('Comisión creada');
          this.passEntry.emit('ok');
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized(
          'error',
          'No se pudo guardar la comisión',
          'Error'
        );
        console.error('El error es ', JSON.stringify(error));
      }
    );
  }

  currencyInputChanged(value) {
    const num = value.toString().replace(/[$,]/g, '');
    return Number(num);
  }
}
