import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { ClassOfInsuranceModel } from 'models/entities/class-of-insurance.model';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { ExportExcelService } from 'app/shared/excel/export-excel.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { PolicyModel } from 'models/entities/policy-model';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Component({
  selector: 'app-mix-class-of-insurance-report',
  templateUrl: './mix-class-of-insurance-report.component.html',
  styleUrls: ['./mix-class-of-insurance-report.component.scss']
})
export class MixClassOfInsuranceReportComponent implements OnInit {
  @Input() classOfInsuranceModelRef = new ClassOfInsuranceModel();
  @Input() classOfInsuranceModelToConsult = new ClassOfInsuranceModel();

  listClassesRef: Array<ClassOfInsuranceModel> = new Array();
  listClassesToConsult: Array<ClassOfInsuranceModel> = new Array();

  @ViewChild('instanceClassRef') instanceClassRef: NgbTypeahead;
  @ViewChild('instanceClassToConsult') instanceClassToConsult: NgbTypeahead;

  clickClassRef$ = new Subject<string>();
  clickClassToConsult$ = new Subject<string>();

  focusClassRef$ = new Subject<string>();
  focusClassToConsult$ = new Subject<string>();

  classOfInsuranceAux = [];
  visible = false;

  listPoliciesByRef = Array<PolicyModel>();
  listPoliciesByToConsult = Array<PolicyModel>();

  rows = [];

  columns = [
    { prop: 'clientId', width: 200, name: 'Nombre Razon Social' },
    { prop: 'phone', width: 200, name: 'Telefono' },
    { prop: 'classOfInsuranceId', width: 300, name: 'Direccion' },
    { prop: 'sellerUserId', width: 300, name: 'Correo electronico' },
    { prop: 'email', width: 150, name: 'Celular' },
    { prop: 'sellerUserId', width: 150, name: 'Vendedor' }
  ];

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService,
  ) { }

  ngOnInit() {
    this.getClassesOfInsurance();
  }

  getPolicies(classOfInsuranceId: string, type: string): Promise<any>  {
    return new Promise((resolve, reject) => {
      const path = 'poliza/listar-polizas-por-ramo?classOfInsuranceId=' + classOfInsuranceId;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            if (type === 'ref') {
              this.listPoliciesByRef = genericResponse.answerList;
              console.log('this.listPoliciesByRef');
              console.log(this.listPoliciesByRef);
            } else {
              this.listPoliciesByToConsult = genericResponse.answerList;
              console.log('this.listPoliciesByToConsult');
              console.log(this.listPoliciesByToConsult);
            }
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas.',
              'Problema consultando las polizas de la empresa.');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas de la empresa.' + JSON.stringify(error))
        }
      );
    })
  }

  getClassesOfInsurance() {
    const path = 'ramos/listar-ramos';
    this.crudService.getModel(path).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listClassesRef = genericResponse.answerList;
          this.listClassesToConsult = genericResponse.answerList;
          // console.log(genericResponse.answerList);
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los ramos.',
            'Problema consultando los ramos de la empresa.');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los ramos de la empresa.' + JSON.stringify(error))
      }
    );
  }

  searchByNameClassRef = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusClassRef$),
      merge(this.clickClassRef$.pipe(filter(() => !this.instanceClassRef.isPopupOpen()))),
      map(search => (search === '' ? this.listClassesRef
        : this.listClassesRef.filter(c => c.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
  );

  searchByNameClassToConsult = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusClassToConsult$),
      merge(this.clickClassToConsult$.pipe(filter(() => !this.instanceClassToConsult.isPopupOpen()))),
      map(search => (search === '' ? this.listClassesToConsult
        : this.listClassesToConsult.filter(c => c.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
  );

  formatterClassRef = (object: { name: string }) => object.name;
  formatterClassToConsult = (object: { name: string }) => object.name;

  rebuildClassRef() {
    this.classOfInsuranceModelRef = new ClassOfInsuranceModel();
    this.classOfInsuranceModelToConsult = new ClassOfInsuranceModel();
    this.getClassesOfInsurance();
    this.visible = false;
    this.rows = new Array();
  }

  rebuildClassToConsult() {
    this.classOfInsuranceModelToConsult = new ClassOfInsuranceModel();
    this.rows = new Array();
  }

  popClass(classOfInsuranceRef: any) {
    if (classOfInsuranceRef.id !== undefined) {
      // Aqui se quita de la lista
      const idToRemove = this.listClassesRef.map(function (x) { return x.id; }).indexOf(classOfInsuranceRef.id);
      this.listClassesToConsult.splice(idToRemove, 1);
      this.visible = true;
    } else {

    }
  }

  createReport() {
    this.rows = new Array();
    const path = 'poliza/listar-polizas-por-ramo?classOfInsuranceId=';
    this.crudService.getModel(path + this.classOfInsuranceModelRef.id.id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          // Se cargan las polizas del ramo de referencia
          this.listPoliciesByRef = genericResponse.answerList;
          this.crudService.getModel(path + this.classOfInsuranceModelToConsult.id.id).subscribe(
            (genericResponse: GenericResponseModel) => {
              if (genericResponse.code === 200) {
                // Se cargan las polizas del ramo de consulta
                this.listPoliciesByToConsult = genericResponse.answerList;
                this.listPoliciesByRef.forEach(client => {
                  if (!this.listPoliciesByToConsult.some((item) => item.clientId.id === client.clientId.id)) {
                    this.rows.push(client);
                    this.rows = [...this.rows];
                  }
                });
              } else {
                this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas.',
                  'Problema consultando las polizas de la empresa.');
              }
            },
            error => {
              this.messageService.getInfoMessageError();
              console.error('Error al cargar las polizas de la empresa.' + JSON.stringify(error))
            }
          );
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas.',
            'Problema consultando las polizas de la empresa.');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar las polizas de la empresa.' + JSON.stringify(error))
      }
    );
  }
}
