import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { ViewChild} from '@angular/core';
import {formatDate} from '@angular/common'
import { PortafolioModel } from 'models/entities/portafolio.model';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { ExportExcelService } from 'app/shared/excel/export-excel.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminPrincipalRuPoliciesComponent } from 'app/policies/admin-principal-ru-policies/admin-principal-ru-policies.component';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
import { PageDataModel } from './../../../models/utilities/page-data-model';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { PolicyModel } from 'models/entities/policy-model';
import { InstallmentModel } from 'models/entities/installment-model';
import { PayReportComponent } from './../../printing/pay-report/pay-report.component';
import { ClientModel } from 'models/entities/client-model';
import { SearchSinisterModel } from 'models/types/search-sinister-model';
import { Observable, Subject } from 'rxjs';
import {  debounceTime,  distinctUntilChanged,  merge,  map,  filter} from 'rxjs/operators';
const EXCEL_EXTENSION = '.xlsx';


@Component({
  selector: 'app-portfolio-report',
  templateUrl: './portfolio-report.component.html',
  styleUrls: ['./portfolio-report.component.scss']
})
export class PortfolioReportComponent implements OnInit {
  @ViewChild('instanceSinister') instanceSinister: NgbTypeahead;
  @Input() initialDate = '';
  @Input() finalDate = '';
  date = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  @Input() todayDate = this.date;
  @Input() isModal=false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  

  public rows: Array<any> = new Array();
  rowsAux = Array<PortafolioModel>();
  rowPolicy= new Array();
  rowInstallement= new Array();
  temp: string[];
  errorMessage: string;
  dato: any;
  numberOfPages: any = 0;
  page = new PageDataModel();
  policyModel= new PolicyModel();
  installmentModel=new InstallmentModel();
  modelSearchPolicy = new SearchSinisterModel();

  focusInsurer$ = new Subject<string>();
  clickInsurer$ = new Subject<string>();
  listClients: Array<ClientModel> = new Array();

  focusSeller$ = new Subject<string>();
  clickSeller$ = new Subject<string>();
  listSeller: any[];

  listPolicyFilter: any[];
  flagp=0;
  flagi=0;

 columns = [
    {prop:'idInstallment', width: 200, name: 'cuota'},
    {prop:'daysehind', width: 200, name: 'dias de atraso'},
    {prop:'idpolicy', width: 200, name: 'identificador póliza'},
    {prop: 'policy', width: 200, name: 'Póliza' },
    {prop:'client', width: 200, name: 'cliente'},
    { prop: 'annexed', width: 200, name: 'anexo' },
    { prop: 'insurancer', width: 300, name: 'aseguradora' },
    { prop: 'classOfInsured', width: 300, name: 'ramo' },
    { prop: 'seller', width: 150, name: 'vendedor' },
    { prop: 'expirationDate', width: 150, name: 'fecha vencimiento' },
    { prop: 'premium_value', width: 150, name: 'valor prima' },
    { prop: 'balance', width: 150, name: 'saldo' },
    { prop: 'clientcellphone', width: 150, name: 'celular' }
  ];

  constructor(private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private modalService: NgbModal) {
      this.page.pageNumber = 0;
      this.page.size = 10;
     }

  ngOnInit() {
     
    this.getClient();
    this.getSeller();
    setTimeout(() => {
      this.getPortfolioStatus();
    }, 200);
    
  }

  //obtener cuotas y polizas vencidas , se captura la respuesta del servicio y la información queda en un arrary de PortfolioModel
  getPortfolioStatus(){
    const path = 'reportes/reporte-cartera';
    this.crudService.getModel(path).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.rows = genericResponse.answerList;
          this.rows.forEach(r => {
            this.rowsAux.push({
              'idInstallment':r[0],
              'daysehind':r[1],
              'expirationDate':r[2],
              'premium_value':r[3],
              'balance':r[4],
              'annexed':r[5],
              'idpolicy':r[6],
              'policy':r[7],
              'client':r[8],              
              'insurancer':r[9],
              'classOfInsured':r[10],
              'seller':r[11] ,
              'clientcellphone':r[12]           
            })
          });
          this.rows=this.rowsAux;
          console.log(this.rowsAux);

        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo listar el estado de cartera',
            'Problema consultando las cuotas vencidas de póliza');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar las cuotas vencidas de polizas ' + JSON.stringify(error))
      }
    );
  }
//segun los dias de atraso se le asigna un color al registro: <=25 verde,entre 26-30 amarillo,31-45 rojo,>45 azul
  getRowClass (row) {
    const diff=row.daysehind;
    // segun la diferencia en días se le agrega una clase por css
    if ( diff <= 25 ) {
      return 'row-color-green';
    } else if ( diff <= 30 && diff > 25 ) {
      return 'row-color-yellow';
    } else if ( diff <= 45 && diff >30 ) {
      return 'row-color-red'
    } else if ( diff >45 ) {
      return 'row-color-blue'
    }
  }
  //llama el modal para ver detalle del registro de la poliza o pagar la cuota vencida
  async getPolicy(idP,action){
    const path = 'reportes/reporte-policyId?idPolicy='+idP;
    this.crudService.getModel(path).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.rowPolicy = genericResponse.answerList;
          if(action==='detail'){
            this.viewPolicy(this.rowPolicy[0]);}
          else if(action==='pay'){
            this.policyModel=(this.rowPolicy[0]);
            this.flagp=1;
        }
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo listar el estado de cartera',
            'Problema consultando las cuotas vencidas de póliza');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar las cuotas vencidas de polizas ' + JSON.stringify(error))
      }
    );
  }
  
  // vizualizar la póliza
  viewPolicy(policy) {
    const modal = this.modalService.open(AdminPrincipalRuPoliciesComponent, {
      windowClass: 'largeModal', size: 'lg', backdrop: false
    });
    modal.componentInstance.policyModel = policy;
    modal.componentInstance.passEntry.subscribe(result => {
      if (result !== null) {
        modal.dismiss();
      } else {
        modal.dismiss();
      }
    });
  }

  // obtener cuota por medio de su id
  async getInstallment(idI){
    const path = 'cuotas/installmentId?idInstallment='+idI;
    this.crudService.getModel(path).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.rowInstallement = genericResponse.answerList;
            /*console.log(this.rowInstallement);*/
            this.installmentModel=(this.rowInstallement[0]);
            console.log(this.installmentModel);
            this.flagi=1;
            this.payInstallment(this.installmentModel);
            } else {
              this.messageService.getInfoMessagePersonalized('warning', 'No se pudo listar el estado de cartera',
                'Problema consultando las cuotas vencidas de póliza');
            }
          },
          error => {
            this.messageService.getInfoMessageError();
            console.error('Error al cargar las cuotas vencidas de polizas ' + JSON.stringify(error))
          }
        );
      }

  //pagar cuota vencida
  payInstallment(installmentToPay: InstallmentModel) {
    const modal = this.modalService.open(PayReportComponent, {
      windowClass: '', size: 'lg', backdrop: 'static'
    });
    modal.componentInstance.policyModel = this.policyModel;
    modal.componentInstance.installmentModel = installmentToPay;
    modal.componentInstance.passEntry.subscribe(retorno => {
      if (retorno === 1) {
        //this.getInstallments();
      }
    modal.dismiss();
    });
  }
    //cargar modal para pagar cuota
  async loadModalPay(idPolicy,idInstallment){
      this.getPolicy(idPolicy,'pay');
      this.getInstallment(idInstallment);
    }

  //obtener clientes de la empresa para el select del filtro
  getClient() {
    const pathClientPoloceActive = 'cliente/listar-clientes-polizas-activas';
    this.listClients = [];
    this.crudService.getModel(pathClientPoloceActive).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listClients = genericResponse.answerList;
          console.log("clientes: "+this.listClients[0].nameOrBusinessName);
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  }

//obtener vendedores de la empresa para el select del filtro
 getSeller() {
  const path = 'usuarios/listar-vendedores-por-empresa';
  this.listSeller = [];
  this.crudService.getModel(path).subscribe(
    (genericResponse: GenericResponseModel) => {
      if (genericResponse.code === 200) {
        this.listSeller = genericResponse.answerList;
      } else {
        this.messageService.getInfoMessageError();
      }
    },
    error => {
      this.messageService.getInfoMessageError();
      console.error('EL ERROR ES: ' + JSON.stringify(error));
    }
  );
}

//seleccionar un cliente
  searchClient = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusInsurer$),
      merge(this.clickInsurer$.pipe(filter(() => !this.instanceSinister.isPopupOpen()))),
      map(search => (search === '' ? this.listClients
          : this.listClients.filter(clientPolicyActive => clientPolicyActive.nameOrBusinessName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10)
      )
    );
//seleccionar un vendedor
  searchSeller = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusSeller$),
      merge(this.clickSeller$.pipe(filter(() => !this.instanceSinister.isPopupOpen()))),
      map(search => (search === '' ? this.listSeller
          : this.listSeller.filter(name => name.completeName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10)
      )
    );
    formatter = (x: { nameOrBusinessName: string }) => x.nameOrBusinessName;
    formatter2 = (x: { completeName: string }) => x.completeName;

//formar el reporte segun la información ingresada para llamar el servicio correspondiente
    createReport() {
      
      if (this.initialDate && this.finalDate){
        let client = '';
        let seller = '';
        
        if(this.modelSearchPolicy.client.nameOrBusinessName){ client=this.modelSearchPolicy.client.nameOrBusinessName}
        if(this.modelSearchPolicy.seller.completeName){seller=this.modelSearchPolicy.seller.completeName}
       
        const path = 'reportes/reporte-cartera-filtro-FechaClienteVendedor?'+
        'initialDate='+this.initialDate+
        '&finalDate='+this.finalDate+
        '&clientName='+client+
        '&sellerName='+seller;

        this.filterdata(path);
        
      }
      if (!this.initialDate && !this.finalDate){
        let client = '';
        let seller = '';
        
        if(this.modelSearchPolicy.client.nameOrBusinessName){ client=this.modelSearchPolicy.client.nameOrBusinessName}
        if(this.modelSearchPolicy.seller.completeName){seller=this.modelSearchPolicy.seller.completeName}
       
        const path = 'reportes/reporte-cartera-filtro-ClienteVendedor?'+
        'clientName='+client+
        '&sellerName='+seller;

        this.filterdata(path);
        
      }
    }
  // Borra el contenido de los input del formulario por id
  cleanFilters() {
    this.initialDate='';
    this.finalDate='';
    //$('#initialDate').val('');
    //$('#finalDate').val('');
    $('#client').val('');
    $('#seller').val('');

    this.rows=new Array();
    this.rowsAux=Array<PortafolioModel>();
    this.listClients = [];
    this.listSeller= [];
    this.modelSearchPolicy = new SearchSinisterModel();
    this.ngOnInit();
  }
  closeWindow() {
    this.modalService.dismissAll();
  }
//obtiene los datos que devuelven los filtros de busqueda
  filterdata(path){
    this.listPolicyFilter = [];
    this.crudService.getModel(path).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listPolicyFilter = genericResponse.answerList;
          console.log(this.listPolicyFilter);
          if (this.listPolicyFilter.length <= 0) {
            this.messageService.getInfoMessagePersonalized(
              'warning',
              '',
              'No hay coincidencias con la búsqueda'
            );
          } else {
            this.rows = new Array();
            this.rowsAux = Array<PortafolioModel>();
            this.listPolicyFilter.forEach(r => {
              this.rowsAux.push({
                'idInstallment': r [0],
                'daysehind': r [1],
                'expirationDate': r [2],
                'premium_value': r[3],
                'balance': r[4],
                'annexed': r[5],
                'idpolicy': r[6],
                'policy': r[7],
                'client': r[8],               
                'insurancer': r[9],
                'classOfInsured': r[10], 
                'seller': r[11],
                'clientcellphone': r[12]        
              })
            });
            this.rows = this.rowsAux;
          }
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  } }

