import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from 'app/shared/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import swal from 'sweetalert2';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';

@Component({
  selector: 'app-change-password-by',
  templateUrl: './change-password-by.component.html',
  styleUrls: ['./change-password-by.component.scss']
})
export class ChangePasswordByComponent implements OnInit {

  errorMessage: any;
  form: any = {};
  passwordValidationError = '';
  isErrorPassword = false;
  @Input() public userInfo;
  @ViewChild('f') planForm: NgForm;
  constructor(
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.form.oldPassword = '';
    this.form.newPassword = '';
  }

  changePassword() {
    if (this.form.newPassword === this.form.newPasswordConfirm) {
      const passwordInfoUpdate = {
        'idUser': this.userInfo.idUser,
        'oldPassword': this.form.oldPassword,
        'newPassword': this.form.newPassword
      }
      this.crudServices.createModel('usuarios/actualizar/contrasena', passwordInfoUpdate).subscribe(
        genericResponse => {
          if (genericResponse.code === 200) {
            this.messageService.getInfoMessageUpdate().then(
              (response) => {
                this.modalService.dismissAll();
              }
            )
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Actualizacion fallida')
          }

        },
        error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
          this.errorMessage = JSON.stringify(error.error.mensaje);
        }
      );
    } else {
      this.passwordValidationError = '¡Las Contraseñas Nuevas No Coinciden!';
      this.isErrorPassword = true;
      setTimeout(() => { this.isErrorPassword = false; }, 3000);
    }
  }

  closeWindow() {
    this.modalService.dismissAll();
  }

}
