import { CompanyModel } from 'models/entities/company-model';
import { ActivityStateModel } from './activity-state.model';
import { ActivityTypeModel } from 'models/entities/activity-type.model';
import { UserModel } from './user-model';

export class ActivityModel{
        id:String ;
		activity:String;
		relationalEntityId:any;
		relationalEntityType:any;
		responsibleUserId:UserModel;
		creatorId:UserModel;
		activityTypeId:ActivityTypeModel;
		activityStateId:ActivityStateModel;
		companyId:CompanyModel;
		lastUpdateDate:Date;
		lastUpdateId:UserModel;
		expireDate:Date;
        
        constructor(){
        this.id = '';
		this.activity = '';
		this.relationalEntityId = null;
		this.relationalEntityType = '';
		this.responsibleUserId = new UserModel();
		this.creatorId = new UserModel();
		this.activityTypeId = new ActivityTypeModel();
		this.activityStateId = new ActivityStateModel();
		this.companyId = new CompanyModel();
		this.lastUpdateDate = new Date();
		this.lastUpdateId = new UserModel();
		this.expireDate=new Date();
        }
}