import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminPrincipalCreatePoliciesComponent } from './admin-principal-create-policies/admin-principal-create-policies.component';
import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';
import { AdminCruListRisksComponent } from './admin-cru-list-risks/admin-cru-list-risks.component';
import { CreateBeneficiaryComponent } from './create-beneficiary/create-beneficiary.component';
import { AdminCruListPoliciesComponent } from './admin-cru-list-policies/admin-cru-list-policies.component';
import { AdminPrincipalRuPoliciesComponent } from './admin-principal-ru-policies/admin-principal-ru-policies.component';
import { CruAttachedComponent } from './cru-attached/cru-attached.component';
import { UpdateAttachedComponent} from './update-attached/update-attached.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'listar-polizas',
        component: AdminCruListPoliciesComponent,
        data: {
          title: '',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_USER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'create-poliza',
        component: AdminPrincipalCreatePoliciesComponent,
        data: {
          title: '',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_USER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'ver-poliza',
        component: AdminPrincipalRuPoliciesComponent,
        data: {
          title: 'Ver Póliza',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_USER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'cru-anexo',
        component: CruAttachedComponent,
        data: {
          title: 'Cru Anexo',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_USER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'update-anexo',
        component: UpdateAttachedComponent,
        data: {
          title: 'update Anexo',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_USER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'administracion-riesgos',
        component: AdminCruListRisksComponent,
        data: {
          title: '',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_USER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'crear-beneficiario',
        component: CreateBeneficiaryComponent,
        data: {
          title: '',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_USER']
        },
        canActivate: [RoleAuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PoliciesRoutingModule { }
