/**
 * An object used to get page information from the server
 */
export class PageDataModel {
    // The number of elements in the page
    size: any = 0;
    // The total number of elements
    totalElements: any = 0;
    // The total number of pages
    totalPages: any = 0;
    // The current page number
    pageNumber: any = 0;
}
