export class PymeRiskModel {
    id: string;
    policyId: any;
    clientId: any;
    state: string;
    buildingInsuredValue: string;
    furnitureInsuredValue: string;
    equipmentInsuredValue: string;
    officeInsuredValue: string;
    machineInsuredValue: string;
    validSince: string;
    validUntil: string;
    classOfInsuranceId: any;
    creatorId: any;
    lastUpdateId: any;
    createdDate: string;
    lastUpdateDate: string;

    public constructor() {
        this.id = null;
        this.policyId = '';
        this.clientId = '';
        this.state = '';
        this.buildingInsuredValue = '';
        this.furnitureInsuredValue = '';
        this.equipmentInsuredValue = '';
        this.officeInsuredValue = '';
        this.machineInsuredValue = '';
        this.validSince = '';
        this.validUntil = '';
        this.classOfInsuranceId = '';
        this.creatorId = '';
        this.lastUpdateId = '';
        this.createdDate = '';
        this.lastUpdateDate = '';
    }
}
