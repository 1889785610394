import { DepartmentModel } from './department-model';
import { CityModel } from './city-model';
import { DocumentTypeModel } from './document-type-model';
import { UserModel } from './user-model';
import { CiiuModel } from './ciiu.model';

export class ClientModel {
	id: string;
	nameOrBusinessName: String;
	documentNumber: string;
	gender: string;
	fatherOrMother: boolean;
	phone: string;
	cellphone: string;
	address: string;
	email: string;
	additionalInfo: string;
	createdDate: string;
	lastUpdateDate: string;
	birthDate: string;
	ciiuId: any;
	sellerUserId: UserModel;
	departmentId: DepartmentModel;
	cityId: CityModel;
	documentTypeId: any;
	creatorId: any;
	lastUpdateId: any;
	companyId: any;
	state: string;

	public constructor() {
		this.id = null;
		this.nameOrBusinessName = '';
		this.documentNumber = '';
		this.gender = '';
		this.fatherOrMother = false;
		this.phone = '';
		this.cellphone = '';
		this.address = '';
		this.email = '';
		this.additionalInfo = '';
		this.createdDate = '';
		this.lastUpdateDate = '';
		this.birthDate = '';
		this.ciiuId = new CiiuModel();
		this.sellerUserId = new UserModel();
		this.departmentId = new DepartmentModel();
		this.cityId = new CityModel();
		this.documentTypeId = new DocumentTypeModel();
		this.creatorId = new UserModel();
		this.lastUpdateId = '';
		this.companyId = { 'id': '', 'name': '' };
		this.state = 'Activo';
	}
}
