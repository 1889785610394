import { Injectable } from '@angular/core';
import { CompanyModel } from 'models/entities/company-model';
import {Observable, Observer, Subject} from 'rxjs';
import {ClientModel} from "../../models/entities/client-model";

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public companyModel: CompanyModel = new CompanyModel();
  public logoUrl: any;
  public policies = 0;

  riskInfoStatus = new Subject<any>();

  constructor() { }

  setCompanyModel(newCompany: CompanyModel) {
    this.companyModel = newCompany;
  }

  getCompanyModel(): CompanyModel {
    return this.companyModel;
  }

  cleanCompanyModel() {
    this.companyModel = new CompanyModel();
  }

  setNumberPolicies(policies: number) {
    this.policies = policies;
  }

  getNumberPolicies(): number {
    return this.policies;
  }

  setLogoUrlCompany(url) {
    this.logoUrl = url;
  }

  getLogoUrlCompany() {
    return this.logoUrl;
  }

  setRiskInfo(info: any) {
    this.riskInfoStatus.next(info);
  }

  getRiskInfo(): Observable<any> {
    return this.riskInfoStatus.asObservable();
  }
}
