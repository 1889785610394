import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';
import { ManageCommissionsComponent } from './manage-commissions/manage-commissions.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'gestion',
        component: ManageCommissionsComponent,
        data: {
          title: 'Gestión',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_USER']
        },
        canActivate: [RoleAuthGuard]
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [RoleAuthGuard],
  exports: [RouterModule]
})
export class CommissionsRoutingModule { }
