import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { ActivityModel } from 'models/entities/activity';
import { ClientModel } from 'models/entities/client-model';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { SearchClientModel } from 'models/types/search-client-model';
import { Subject, Observable, Observer } from 'rxjs';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { ExportExcelService } from 'app/shared/excel/export-excel.service';
import { PdfService } from 'app/shared/pdf/pdf.service';
import { SharedService } from 'app/shared/shared.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { debounceTime, distinctUntilChanged, merge, map, filter } from 'rxjs/operators';

@Component({
  selector: 'app-activities-by-manager-report',
  templateUrl: './activities-by-manager-report.component.html',
  styleUrls: ['./activities-by-manager-report.component.scss']
})
export class ActivitiesByManagerReportComponent implements OnInit {

  public canShowLoading = false;
  public listActivitys: Array<ActivityModel> = new Array();
  public client=new ClientModel();
  public filter={startDate:new Date(),finalDate:new Date(),responsable:'',status:'',client:'',companyId:this.tokenServiceCtrl.getCompanyId()};
  public listTypes: any [];
  public listStates: any [];
  public listUsers: any []; 
  dataLoad=false;
  listSeacrhClient: any;
  listClients:any;
  temp: any;
  print:boolean=false;
  justRead = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('instanceClient') instanceClient: NgbTypeahead;
  modelSearchClient: SearchClientModel = new SearchClientModel();
  focusClient$ = new Subject<string>();
  clickClient$ = new Subject<string>();
  focusClientIn$ = new Subject<string>();
  clickClientIn$ = new Subject<string>();
  logo:any
  @ViewChild('contentToConvert') contentDiv: ElementRef;

  constructor(  private messageCtrl:InfoMessagesService,
    private serviceCtrl:CrudServiceService,
    private tokenServiceCtrl:TokenStorageService,
    private excelService: ExportExcelService,
    private pdfService: PdfService,
    private sharedService:SharedService) { }

  ngOnInit() {  
    this.getStatusActivity();
    this.getUsers();
    setTimeout(() => {
      this.imageLoad();
    }, 3000);
  }


  imageLoad(){
    this.getBase64ImageFromURL(this.sharedService.getLogoUrlCompany()).subscribe(base64data => {
     this.logo = 'data:image/jpg;base64,' + base64data;
    });
  }

  getBase64ImageFromURL(url: string) {

    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
          // This will call another method that will create image from url
          img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
           observer.error(err);
        };
      } else {
          observer.next(this.getBase64Image(img));
          observer.complete();
      }
    });
 }


 getBase64Image(img: HTMLImageElement) {
  // We create a HTML canvas object that will create a 2d image

  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  var ctx = canvas.getContext("2d");
  // This will draw image    
  ctx.drawImage(img, 0, 0);
  // Convert the drawn image to Data URL
  var dataURL = canvas.toDataURL("image/png");
return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}


  captureScreen() {
    this.print=true;
    this.justRead = true;
    setTimeout(() => {
      const dataToConvert = this.contentDiv.nativeElement;
      console.log(dataToConvert);
      this.pdfService.captureScreen(dataToConvert, 295, 'landscape').then(
        () => {
          this.passEntry.emit(1);
        }
      )
    }, 1000);
    this.print=false;
  }

  submitForm() {
    
    if (  this.modelSearchClient.clientName === "" &&
          this.modelSearchClient.documentNumberClient === "" &&
          this.modelSearchClient.sellerName === "" ) {
          this.messageCtrl.getInfoMessagePersonalized(
          "warning",
          "",
          "Los Filtros Están Vacíos "
      );
    } else {
      this.getSearchClient();
    }
  }


  getSearchClient() {
   
    const pathSearchClient ="cliente/ver-por-empresa-filtro?clientName=" + this.modelSearchClient.clientName +
    "&documentoNumberClient=" + this.modelSearchClient.documentNumberClient + "&sellerName=" + this.modelSearchClient.sellerName;
      
    this.serviceCtrl.getModel(pathSearchClient).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listSeacrhClient = genericResponse.answerList;
                if (this.listSeacrhClient.length <= 0) {
            this.messageCtrl.getInfoMessagePersonalized(
              "warning",
              "",
              "No hay coincidencias con la búsqueda"
            );
          } else {
            this.temp = this.listSeacrhClient;
          }
        } else {
          this.messageCtrl.getInfoMessageError();
        }
      },
      error => {
        this.messageCtrl.getInfoMessageError();
        console.error("EL ERROR ES: " + JSON.stringify(error));
      }
    );
}

  getStatusActivity(){
    let path='empresa/estado-actividad/listar-estado-actividad';
      this.serviceCtrl.getModel(path).subscribe(
        result=>{
        
          if(result.code===200)
          {
            this.listStates=result.answerList        
          }
        },
        error=>{
         this.messageCtrl.getInfoMessageDelete();
        }
      )
    }

    exportExcel(){
      this.excelService.exportAsExcelFile(this.listActivitys,'Actividades' + this.tokenServiceCtrl.getCompanyName());

    }


  
    getUsers(){
      let path='usuarios/listar-vendedores-y-gestores';
      this.serviceCtrl.getModel(path).subscribe(
        result=>{
          if(result.code===200)
          {
             this.listUsers=result.answerList;
          }
        },
        error=>{
         this.messageCtrl.getInfoMessageDelete();
        }
      )
    }

  newActivity()
  {
    
  }



  filterActivitys(){
   debugger;
    this.filter.client=null;
    if(this.filter.responsable===''){
      this.filter.responsable=null;
    }
    let path='actividad/filtrar';
    this.serviceCtrl.createModel(path,this.filter).subscribe(
      result=>{
       if(result.code===200)
        {
    
           this.listActivitys=result.answerList;
           this.listActivitys.forEach(element => {
            if(element.relationalEntityType==='CLIENTE')
            {
              this.searchClient(element)
            }
          });
          this.dataLoad=true;
        }
      }
      ,error=>{
        this.messageCtrl.getInfoMessageError();
      }
    )
  }



  searchClient(activity)
  {
    let path='cliente/ver-por-id?clientId='+activity.relationalEntityId
    this.serviceCtrl.getModel(path).subscribe(
      result=>{
           if(result.code===200)
           {
            
            let client=result.genericObject;
            
            activity.relationalEntityType=client;
            
           }
           else{
                   this.messageCtrl.getInfoMessageError();
           }
      },
      error=>{
        this.messageCtrl.getInfoMessageError();
      }
    )
  }


}
