import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TokenStorageService} from "../../shared/storage-services/token-storage.service";
import {PayStyleModel} from "../../../models/entities/pay-style-model";
import {CompanyModel} from "../../../models/entities/company-model";
import swal from "sweetalert2";
import {CrudServiceService} from "../../shared/backend/cruds/crud-service.service";
import {GenericResponseModel} from "../../../models/utilities/generic.response.model";

@Component({
  selector: 'app-custom-pay-style-modal',
  templateUrl: './custom-pay-style-modal.component.html',
  styleUrls: ['./custom-pay-style-modal.component.scss']
})
export class CustomPayStyleModalComponent implements OnInit {

  payStyle: PayStyleModel = new PayStyleModel();

  @Output() emitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(
      public activeModal: NgbActiveModal,
      private tokenStorage: TokenStorageService,
      private crudService: CrudServiceService
  ) { }

  ngOnInit() {
    this.payStyle.companyId = new CompanyModel();
    this.payStyle.companyId.id = this.tokenStorage.getCompanyId();
  }

  onSubmit(evt) {
    evt.preventDefault();
    if (!this.validate()) {
      return;
    }
    this.createPayStyle();
  }

  validate() {
    const {numberOfInstallments, style} = this.payStyle;
    const messages = [];
    if (!style) {
      messages.push('Debes ingresar un nombre o descripción para identificar el nuevo modo de pago');
    }
    if (!numberOfInstallments || numberOfInstallments < 0) {
      messages.push('Debes ingresar un número de cuotas válido');
    }
    if (messages.length > 0) {
      swal({
        type: "error",
        text: messages.join('. ')
      });
      return false;
    }
    return true;
  }

  cancel() {
    this.emitter.emit(null);
    this.activeModal.dismiss();
  }

  createPayStyle() {
    const url = 'estios-de-pago/crear-modos-de-pago';
    this.crudService.createModel(url, this.payStyle).toPromise().then((response: GenericResponseModel) => {
      const newPayStyle = response.genericObject;
      this.emitter.emit(newPayStyle);
      this.activeModal.dismiss();
    }).catch(error => {
      console.log(error);
      swal({ type: 'error', text: 'No se pudo crear modo de pago' });
    })
  }
}
