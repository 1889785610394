import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtResponseModel } from '../../../models/auth/jwt-response-model';
import { LoginCredentialsModel } from '../../../models/auth/login-credentials-model';
import { environment } from './../../../environments/environment.prod';
import { TokenStorageService } from '../storage-services/token-storage.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Encabezados de las peticiones http con la información de la aplicación.

// Verificar esto.
@Injectable({ providedIn: 'root' })

export class AuthService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  // Declaramos una variable que me instancie el token de la sesión.
  token: TokenStorageService;
  // URL para la conexión, esta proviene del componente "environments"
  private serverURL = environment.serverUrl;

  // Debemos inyectar el httpClient dentro del constructor y de esta manera poder hacer las peticinos API REST
  constructor(private http: HttpClient,
    private tokenStorage: TokenStorageService) {
    //this.isAuthenticated()
  }

  async buildHeaderAuthenticated() {
    // const token = this.tokenStorage.getToken();
    this.httpOptions = {
      headers: await new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.tokenStorage.getToken() + ''
      })
    };
  }

  logout() {
    this.token = null;
  }

  getToken() {
    return this.token;
  }
  // getAllUsers(): Observable<string> {
  // console.log('entra al board');
  // return this.http.get(this.serverURL + this.userURL, this.httpOptions);
  // }

  // Haremos un observable de la clase JWTResponse para mostrar los datos de esta.
  // Es importante indicarle al método que devolverá un Observable y el tipo de dato que devolverá el observable
  // al hacer el subscribe, si el api rest devuelve un objeto que no corresponde 100% con los modelos que tengas
  // en Angular, es mejor indicarle el tipo any.
  // Dentro del loginPageComponent se encuentra el método subscribe en el cual recibe en su data la información
  // del token por medio de este observable de tipo JWTResponse
  attemptAuth(path, credentials: LoginCredentialsModel): Observable<JwtResponseModel> {
    console.log('ingresó a la verificación del token');
    // Recordar que los "post" tienen un cuerpo con el qué comunicarse.
    return this.http.post<JwtResponseModel>(`${this.serverURL}${path}`, credentials, this.httpOptions);
  }

  isAuthenticated() {
    // here you can check if user is authenticated or not through his token
    if (this.tokenStorage.getToken() != null) {
      this.buildHeaderAuthenticated();
      return true;
    } else {
      return false;
    }
  }
}
