
export class InsurerModel {
    id: any;
    name: string;
    nit: any;
    address: any;
    phone: any;
    createdDate: any;
    lastUpdateDate: any;
    creatorId: any;
    lastUpdateId: any;
    companyId: any;

    public constructor() {
        this.id = null;
        this.name = '';
        this.nit = '';
        this.address = '';
        this.phone = '';
        this.createdDate = '';
        this.lastUpdateDate = '';
        this.creatorId = '';
        this.lastUpdateId = '';
        this.companyId = '';
    }
}
