import { PdfService } from './../../shared/pdf/pdf.service';
import { BankAccountModel } from 'models/entities/bank-account-model';
import { PolicyModel } from 'models/entities/policy-model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { CompanyModel } from './../../../models/entities/company-model';
import { ViewChild, ElementRef } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { InstallmentModel } from './../../../models/entities/installment-model';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { RemissionModel } from 'models/entities/remission-model';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { NumberToTextService } from 'app/shared/number-to-text/number-to-text.service';
import { Observable, Observer } from 'rxjs';
import { SharedService } from 'app/shared/shared.service';

@Component({
  selector: 'app-remission',
  templateUrl: './remission.component.html',
  styleUrls: ['./remission.component.scss']
})
export class RemissionComponent implements OnInit {


  @Input() installmentModel: InstallmentModel = new InstallmentModel();
  @Input() policyId: PolicyModel = new PolicyModel();
  @Input() remissionModel: RemissionModel = new RemissionModel();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  companyModel: CompanyModel = new CompanyModel();
  bankAccountList: Array<BankAccountModel> = new Array();
  @Input() isPrinting = false;
  justRead = false;
  @ViewChild('contentToConvert') contentDiv: ElementRef;
  logo: any = '';

  constructor(
    private crudService: CrudServiceService,
    public tokenStorage: TokenStorageService,
    private messageService: InfoMessagesService,
    private pdfService: PdfService,
    private castService: NumberToTextService,
    private sharedService: SharedService
  ) {
    setTimeout(() => {
      this.imageLoad();
    }, 3000);
  }

  ngOnInit() {
    this.remissionModel.policyId = { id: this.policyId.id };
    this.getCompany();
    this.getBankAccounts();
  }

  validateClassOfInsurance() {

  }

  closeModal() {
    this.passEntry.emit(0);
  }

  onSubmit() {
    this.remissionModel.policyId = { id: this.policyId.id };
    this.remissionModel.creatorId = { id: this.tokenStorage.getId() };
    this.remissionModel.createdDate = new Date();
    this.remissionModel.state = 'CURRENT'
    this.crudService.createModel('remision/crear-remision', this.remissionModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.remissionModel = genericResponse.genericObject;
          this.isPrinting = true;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo guardar el recaudo con exito',
            'Problema creando el recaudo');
        }
      }, (error) => {
        this.messageService.getInfoMessageError();
        console.error('Error, no se pudo guardar el recaudo con exito: ' + JSON.stringify(error))
      }
    )
  }

  changeDate() {
    const selectedExpeditionDate = this.remissionModel.expeditionDate.split('-');
    const momentaryDate = new Date(selectedExpeditionDate[0],
      selectedExpeditionDate[1], selectedExpeditionDate[2]);
    momentaryDate.setTime(momentaryDate.getTime() + (24 * 60 * 60 * 1000 * 30));
    this.remissionModel.dueDate = momentaryDate.getFullYear() + '-'
      + this.remissionModel.validateCero(momentaryDate.getMonth()) + '-'
      + this.remissionModel.validateCero(momentaryDate.getDate());
  }

  calculateTotal() {
    this.remissionModel.totalValue = (
      Number(this.remissionModel.premium) + Number(this.remissionModel.spendings) +
      ((Number(this.remissionModel.premium) + Number(this.remissionModel.spendings)) * (Number(this.remissionModel.iva) / 100))) + '';
  }

  getBankAccounts() {
    const pathCompanyUrl = 'empresa/cuenta-bancaria/listar-cuentas-bancarias';
    this.crudService.getModel(pathCompanyUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        this.bankAccountList = genericResponse.answerList;
        this.remissionModel.bankAccountId = genericResponse.answerList[0];
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar cuentas bancarias: ' + error)
      }
    );
  }

  getCompany() {
    const pathCompanyUrl = 'empresa/compania/ver-compania?id=' + this.tokenStorage.getCompanyId();
    this.crudService.getModel(pathCompanyUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        console.log(genericResponse)
        this.companyModel = genericResponse.genericObject;
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar la compañia: ' + error)
      }
    );
  }

  getLyricsValue(): any {
    return this.castService.NumeroALetras(this.remissionModel.totalValue);
  }

  captureScreen() {
    this.justRead = true;
    setTimeout(() => {
      const dataToConvert = this.contentDiv.nativeElement;
      this.pdfService.captureScreen(dataToConvert, 295, 'landscape').then(
        () => {
          this.passEntry.emit(1);
        }
      )
    }, 2000);
  }

  imageLoad() {
    console.log(this.sharedService.getLogoUrlCompany())
    this.getBase64ImageFromURL(this.sharedService.getLogoUrlCompany()).subscribe(base64data => {
      // this is the image as dataUrl
      this.logo = 'data:image/jpg;base64,' + base64data;
    });
  }

  getBase64ImageFromURL(url: string) {

    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }


  getBase64Image(img: HTMLImageElement) {
    // We create a HTML canvas object that will create a 2d image

    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    // This will draw image    
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

}
