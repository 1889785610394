import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalInfo } from 'models/entities/modal-info';
import { formatDate } from '@angular/common';
import { ManageClientsComponent } from 'app/activities/manage-clients/manage-clients.component';
import { AdminPrincipalRuPoliciesComponent } from 'app/policies/admin-principal-ru-policies/admin-principal-ru-policies.component';
import { CreateActivityComponent } from 'app/activities/create-activity/create-activity.component';
import { ExportExcelService } from 'app/shared/excel/export-excel.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { PdfService } from 'app/shared/pdf/pdf.service';
import { SharedService } from 'app/shared/shared.service';
import { Observable, Observer} from 'rxjs';
import { CompanyModel } from 'models/entities/company-model';

@Component({
  selector: 'app-vehicle-by-license-plate-report',
  templateUrl: './vehicle-by-license-plate-report.component.html',
  styleUrls: ['./vehicle-by-license-plate-report.component.scss']
})
export class VehicleByLicensePlateReportComponent implements OnInit {

  @ViewChild('tableLicense') contentDiv: ElementRef;

listLicensePlate: any = [];
licensePlate = '';
row = new Array();
columns = [];
logo: any;
dataLoad = false;

modalInforClientModel = new ModalInfo();
companyModel = new CompanyModel();

  constructor(  private crudService: CrudServiceService,
                private messageService: InfoMessagesService,
                private modalService: NgbModal,
                private excelService: ExportExcelService,
                private tokenStorage: TokenStorageService,
                private pdfService: PdfService,
                private sharedService: SharedService) { }

  ngOnInit() {
    setTimeout(() => {
      this.imageLoad();
    }, 2000);
  }

  getVehicleByLicense() {
    this.dataLoad = true;

    const path = 'reportes/reporte-placa?licensePlate=' + this.licensePlate;

    this.crudService.getModel(path).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          if ( genericResponse.answerList.length > 0 ) {
            this.listLicensePlate =  genericResponse.answerList;
          } else {
            this.messageService.getInfoMessagePersonalized(
              'warning',
              '',
              'No hay coincidencias con la búsqueda'
            );
          }
        } else {
          this.messageService.getInfoMessageError();
        }
        this.dataLoad = false;
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  }

  viewPolicy(policy) {
    const modal = this.modalService.open(AdminPrincipalRuPoliciesComponent, {
      windowClass: 'largeModal', size: 'lg', backdrop: false
    });
    modal.componentInstance.policyModel = policy;
    modal.componentInstance.passEntry.subscribe(result => {
      if (result !== null) {
        modal.dismiss();
      } else {
        modal.dismiss();
      }
    });
  }

  viewClient(client) {
    const modal = this.modalService.open(ManageClientsComponent, {
      windowClass: '',
      size: 'lg',
      backdrop: 'static'
    });

    const array = client.birthDate.split('+');
    const date = formatDate(new Date(array[0]), 'yyyy-MM-dd', 'en');
    client.birthDate = date;

    this.modalInforClientModel.isCreate = false;
    this.modalInforClientModel.isUpdate = false;
    this.modalInforClientModel.isView = true;
    modal.componentInstance.modelInfoClient = this.modalInforClientModel;
    modal.componentInstance.clientModel = { ...client };
    modal.componentInstance.passEntry.subscribe(update => {
      if (update === 'ok') {
        modal.dismiss();
      }
    });
  }

  createActivity() {
    const modal = this.modalService.open(CreateActivityComponent, {
      windowClass: 'largeModal', size: 'lg', backdrop: false
    });
    modal.componentInstance.isModal = true;
    modal.componentInstance.passEntry.subscribe(result => {
      if (result === 'close') {
        modal.dismiss();
      }
    });
  }

  exportAsXLSX(): void {
    if ( this.listLicensePlate.length > 0 ) {
      this.dataLoad = true;
      const licensePlateAux: any = [];
      this.listLicensePlate.forEach(license => {
        licensePlateAux.push({
          'Placa' : license.licensePlate,
          'Número de Documento': license.policyId.clientId.documentNumber,
          'Número de Póliza': license.policyId.policy,
          'Ramo': license.policyId.classOfInsuranceId.name,
          'Aseguradora': license.policyId.insurerId.name,
          'Tomador': license.policyId.clientId.nameOrBusinessName
        });
      });
      setTimeout(() => {
        this.excelService.exportAsExcelFile(licensePlateAux, 'Reporte por placa_' + this.tokenStorage.getCompanyName());
        this.dataLoad = false;
      }, 1000);
    } else {
      this.messageService.getInfoMessagePersonalized(
        'warning',
        '',
        'No hay elementos para generar el documento'
      );
      this.dataLoad = false;
    }
  }


  downloadPDF() {
    if ( this.listLicensePlate.length > 0 ) {
      this.dataLoad = true;
      const columns = ['Placa', 'Número Documento', 'Número Póliza', 'Ramo', 'Aseguradora', 'Tomador'];
      const policyAux = [];
      this.listLicensePlate.forEach(policy => {
        policyAux.push([
          policy.licensePlate,
          policy.policyId.clientId.documentNumber,
          policy.policyId.policy,
          policy.policyId.classOfInsuranceId.name,
          policy.policyId.insurerId.name,
          policy.policyId.clientId.nameOrBusinessName
        ]);
      });
      const rows = policyAux;
      this.getCompany();

      setTimeout(() => {
        this.pdfService.generatePdf(columns, rows, this.logo, this.companyModel, 'Reporte por Placa');
        this.dataLoad = false;
      }, 1200);
  } else {
    this.messageService.getInfoMessagePersonalized(
      'warning',
      '',
      'No hay elementos para generar el documento'
    );
    this.dataLoad = false;
  }
}


  getCompany() {
    const pathCompanyUrl = 'empresa/compania/ver-compania?id=' + this.tokenStorage.getCompanyId();
    this.crudService.getModel(pathCompanyUrl).subscribe(
      data => {
        this.companyModel = data.genericObject;
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar la compañia: ' + error)
      }
    );
  }

  imageLoad() {
     this.getBase64ImageFromURL(this.sharedService.getLogoUrlCompany()).subscribe( base64data => {
       // this is the image as dataUrl
      this.logo = 'data:image/jpg;base64,' + base64data;
     });
   }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      // create an image object
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
          // This will call another method that will create image from url
          img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
           observer.error(err);
        };
      } else {
          observer.next(this.getBase64Image(img));
          observer.complete();
      }
    });
 }


   getBase64Image(img: HTMLImageElement) {
    // We create a HTML canvas object that will create a 2d image
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    const dataURL = canvas.toDataURL('image/png');
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }

  cleanFilters() {
    $('#licensePlate').val('');
    this.licensePlate = '';
    this.listLicensePlate = [];
  }
}
