export const environment = {
  production: true,
  // serverUrl: 'http://localhost:8080/',
  //serverUrl: 'https://segurosapp.co/seguros-app-api/'
  serverUrl: 'http://3.224.136.55/seguros-app-api_dev/'
    
};
export const environmentFire = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyD65petaxoJlapIQ5aRFxbErdZVwKZog1s',
    authDomain: 'seguros-app-8add2.firebaseapp.com',
    databaseURL: 'https://seguros-app-8add2.firebaseio.com',
    projectId: 'seguros-app-8add2',
    storageBucket: 'seguros-app-8add2.appspot.com',
    messagingSenderId: '1072046993359',
    appId: '1:1072046993359:web:d92e8e7af74cb7cd'
  }
};
