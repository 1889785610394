import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { ClientModel } from 'models/entities/client-model';
import { ModalInformationModel } from 'models/utilities/modal-information-model';
import { RelationalFileModel } from 'models/utilities/relational-type-model';
import { NgbActiveModal, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-manage-clients',
  templateUrl: './manage-clients.component.html',
  styleUrls: ['./manage-clients.component.scss']
})

export class ManageClientsComponent implements OnInit {
  @Input() public clientModel: ClientModel = new ClientModel();
  @Input() public modelInfoClient: ModalInformationModel = new ModalInformationModel();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('f') createClientForm: NgForm;
  @Input() public actvityClient=false;
  @ViewChild('t') tabset;

  public relationalModel: RelationalFileModel = new RelationalFileModel();

  constructor( private activeModal: NgbActiveModal ) {}

  ngOnInit() {
    this.relationalModel.relationalId = this.clientModel.id;
    this.relationalModel.relationalType = 'DOCUMENTS_CLIENT_FILE';
    this.relationalModel.relationalFileType = 'ADJUNTO';
    this.relationalModel.relationalObjetive = 'adjunto para cliente';
    this.relationalModel.relationalRestrictions = new Array();
  }

  close() {
    this.activeModal.close();
  }

  reportOfSon($event) {
    if ($event.response === 'ok') {      
      this.modelInfoClient.isCreate = false;
      this.passEntry.emit('ok');
      this.relationalModel.relationalId = $event.id;
      setTimeout(() => {
         this.tabset.select('tab-documnets');
      }, 50);
    }
  }
}
