import { DocumentTypeModel } from './document-type-model';
import { UserModel } from './user-model';
import { PolicyModel } from './policy-model';

export class BeneficiaryModel {
    id: string;
    name: string;
    phone: string;
    percent: string;
    documentNumber: string;
    documentTypeId: DocumentTypeModel;
    policyId: any;
    createdDate: string;
    lastUpdateDate: string;
    creatorId: UserModel;
    lastUpdateId: UserModel;

    public constructor() {
        this.id = '';
        this.name = '';
        this.phone = '';
        this.percent = '';
        this.policyId = null;
        this.documentNumber = '';
        this.documentTypeId = new DocumentTypeModel();
        this.createdDate = '';
        this.lastUpdateDate = '';
        this.creatorId = new UserModel();
        this.lastUpdateId = null;
    }
}