export class DepartmentModel {
    id: string;
    name: string;
    countryId: string;
    indicative: string;

    public constructor() {
        this.id = '';
        this.name = '';
        this.countryId = '';
        this.indicative = '';
    }
}
