import { UserModel } from './user-model';
import { CompanyModel } from './company-model';
export class AnsModel{
    id: any;
    name: string;
    hoursWorked: any;
    companyId: any;
    createdDate: string;
    lastUpdateDate: any;
    creatorId: any;
    lastUpdateId: any;


    public constructor(){
        this.id = null;
        this.name = '';
        this.hoursWorked = '';
        this.companyId = new CompanyModel();
        this.createdDate = '';
        this.lastUpdateDate = new Date();
        this.creatorId = new UserModel();
        this.lastUpdateId = new UserModel();;
    }
}