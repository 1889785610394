import { ExportExcelService } from 'app/shared/excel/export-excel.service';
import { CompanyModel } from 'models/entities/company-model';
import { TotalSellersCommissionResponse } from './../../../models/response/TotalSellersComissionResponse';
import { debounceTime, distinctUntilChanged, merge, filter, map } from 'rxjs/operators';
import { SharedService } from 'app/shared/shared.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { UserModel } from 'models/entities/user-model';
import { Subject, Observable, Observer } from 'rxjs';
import { SearchCommissionReportModel } from 'models/utilities/search-commission-report-model';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as jsPDF from 'jspdf';

@Component({
  selector: 'app-commissions-by-seller',
  templateUrl: './commissions-by-seller.component.html',
  styleUrls: ['./commissions-by-seller.component.scss']
})
export class CommissionsBySellerComponent implements OnInit {

  public canShowLoading = false;
  public searchCommissionReportModel: SearchCommissionReportModel = new SearchCommissionReportModel();
  public base64Logo: any = '';
  public reportInformation: Array<TotalSellersCommissionResponse> = new Array();

  @ViewChild('instanceClient') instanceClient: NgbTypeahead;
  public listSellers: Array<UserModel> = new Array();
  focusSeller$ = new Subject<string>();
  clickSeller$ = new Subject<string>();

  public reportInformationsColumns = [
    { prop: 'sellerDocumentNumber', width: 500, name: 'Documento del vendedor' },
    { prop: 'sellerCompleteName', width: 500, name: 'Nombre del vendedor' },
    { prop: 'totalCommission', width: 500, name: 'Monto total de la comisión' }
  ];


  private myCompanyModel = new CompanyModel();

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService,
    private sharedService: SharedService,
    private excelService: ExportExcelService
  ) {
  }

  ngOnInit() {
    this.getSellers();
    this.getCompany();
    setTimeout(() => {
      this.imageLoad();
    }, 2000);
  }

  /***
   * Búsqueda de totales en comisiones por vendedores
   */
  searchCommissionReport() {
    this.reportInformation = new Array();
    let sellerIdToSend = -1;
    if (this.searchCommissionReportModel.sellerId.id) {
      sellerIdToSend = this.searchCommissionReportModel.sellerId.id;
    }

    const path = 'comisiones/totalizar-comisiones-por-vendedor-entre-fechas?sellerId=' +
      sellerIdToSend + '&initialDate=' +
      this.searchCommissionReportModel.initialDate + '&finalDate=' +
      this.searchCommissionReportModel.finalDate;
    this.crudService.getModel(path).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200 && genericResponse.answerList.length > 0) {
          this.reportInformation = genericResponse.answerList;
        } else {
          this.messageService.getInfoMessagePersonalized(
            'warning',
            'No se encontraron comisiones',
            'Aviso'
          );
        }
      },
      error => {
        this.messageService.getInfoMessageBadInternet();
        console.error('El error es ', JSON.stringify(error.message));
      }
    );
  }

  /***
   * Ejecución de la descarga del pdf para la tabla consulta en la vista actual
   */
  async downloadPDF() {
    const rowsToPrint = new Array();
    for (let i = await 0; await i < this.reportInformation.length; await i++) {
      await rowsToPrint.push(
        [
          this.reportInformation[i].sellerDocumentNumber,
          this.reportInformation[i].sellerCompleteName,
          this.reportInformation[i].totalCommission
        ]
      );
    }

    const doc = new jsPDF('l', 'pt');
    const headers = ['Documento del vendedor', 'Nombre del vendedor', 'Monto total de comisión'];
    doc.setFontSize(16)
    doc.setFontType('bold')
    doc.text(35, 30, 'INFORME DE COMISIONES POR VENDEDOR');
    doc.addImage(this.base64Logo, 'JPG', 35, 40, 80, 80);
    doc.autoTable(headers, rowsToPrint, { startY: 200, styles: { fontSize: 8 } });
    doc.save('Reporte de comisiones por vendedor - ' + this.myCompanyModel.name);
  }

  /***
   * Exportación a excel de la a información consultada en el reporte
   */
  exportToExcel() {
    this.reportInformation.forEach(
      (async commissionItem => {
        commissionItem.totalCommission = Math.round(Number(commissionItem.totalCommission));
      })
    )
    this.excelService.exportAsExcelFile(this.reportInformation, 'Reporte de comisiones por vendedor - ' + this.myCompanyModel.name);
  }

  /***
   * Obtiene la empresa del usuario actual para generar la impresión del pdf para el usuario
   */
  getCompany() {
    const pathCompanyUrl = 'empresa/compania/ver-compania?id=' + this.tokenStorage.getCompanyId();
    this.crudService.getModel(pathCompanyUrl).subscribe(
      data => {
        this.myCompanyModel = data.genericObject;
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error(error);
      }
    );
  }

  /***
   * Configuración de listado de vendedores consultando desde el back y cargandolos en variable para el front
   */
  getSellers() {
    this.listSellers = new Array();
    const pathListSelle = 'usuarios/listar-vendedores-por-empresa';
    this.tokenStorage.getCompanyId()
    this.listSellers = new Array();
    this.crudService.getModel(pathListSelle).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listSeller = genericResponse.answerList;
          listSeller.forEach(seller => {
            this.listSellers.push(seller);
          });
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized(
          'error',
          'No se pudo vistar los vendedores del sistema, por favor contácta al administrador',
          'Error'
        );
        console.error(JSON.stringify(error.message));
      }
    );
  }

  /***
   * Configuración de filtro local para vendedores
   */
  searchSeller = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusSeller$),
      merge(this.clickSeller$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
      map(search => (search === '' ? this.listSellers
        : this.listSellers.filter(seller => seller.completeName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
    );

  /***
   * Filtro de visualización de vendedores para el front
   */
  formatterSeller = (x: { completeName: string }) => x.completeName;

  /***
   * Método accionado al presionar borrar en la barra de vendedor, se encarga de reiniciar el campo para evitar escrituras erroneas.
   */
  rebuildSeller() {
    this.searchCommissionReportModel.sellerId = null;
  }

  /***
   * Descarga el logo de firebase para convertirlo a BASE64
   */
  imageLoad() {
    this.getBase64ImageFromURL(this.sharedService.getLogoUrlCompany()).subscribe(base64data => {
      this.base64Logo = 'data:image/jpg;base64,' + base64data;
    });
  }

  /***
   * Observable de la descarga del logo que obtiene y ejecuta la conversión de la imagen a base 64
   */
  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  /***
    * Método que ejecuta la conversión de una imagen a su base 64.
    */
  getBase64Image(img: HTMLImageElement) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png');
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }

}
