import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { Output } from '@angular/core';
import { ClientInfoRegisterModel } from './../../../models/entities/client-info-register.model';
import { NgForm } from '@angular/forms';
import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss']
})
export class ClientInfoComponent implements OnInit {


  @ViewChild('f') loginForm: NgForm;
  clientRegisterModel: ClientInfoRegisterModel = new ClientInfoRegisterModel();
  @Output() public passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    const pathListDOcumentTypesUrl = 'cliente_info_landing/crear';
    this.crudService.createModelWA(pathListDOcumentTypesUrl, this.clientRegisterModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessagePersonalized('success', 'Hemos recibido tu información',
            'Te contáctaremos en un parpadeo');
          this.closeModal();
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo guardar su información',
            'Comuniquese por favor al 300-739-3720');
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized('warning', 'No se pudo guardar su información',
            'Comuniquese por favor al 300-739-3720');
        console.error('Error al guardar la información del cliente' + JSON.stringify(error))
      }
    );
  }

  closeModal() {
    this.passEntry.emit(null);
  }



}
