import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CommissionModelResponse } from 'models/entities/commission-response.model';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchCommissionModel } from 'models/utilities/search-commission-model';
import { SelectionType, ColumnMode } from '@swimlane/ngx-datatable';
import { ResultCommissionsComponent } from '../result-commissions/result-commissions.component';
import { FormControl, Validators, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-list-commissions',
  templateUrl: './list-commissions.component.html',
  styleUrls: ['./list-commissions.component.scss']
})
export class ListCommissionsComponent implements OnInit, OnChanges {
  @Input() searchCommissionModel: any;
  @Input() value: any;

  listCommission: Array<CommissionModelResponse> = new Array<CommissionModelResponse>();
  isSearching = false;
  canShowLoading = false;
  documentType = '';
  selected = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  changeText: boolean;
  loadingIndicator = true;
  checkboxState = true;
  ica = '';
  cree = '';
  iva = '';

  abonoAux = 0;

  columnsCommission = [
    { prop: 'installment', width: 100, name: 'Id Cuota' },
    { prop: 'insurerId.name', width: 200, name: 'Aseguradora' },
    { prop: 'policyId.policy', width: 200, name: 'Poliza' },
    { prop: 'attachedId.type', width: 200, name: 'Anexo' },
    { prop: 'premium', width: 300, name: 'Saldo' },
    { prop: 'insuredId.nameOrBusinessName', width: 300, name: 'Asegurado' },
    { prop: 'classofInsuranceId.name', width: 150, name: 'Aseguradora' },
    { prop: 'sellerId', width: 150, name: 'Vendedor' },
    { prop: 'commissionPercentage', width: 150, name: '% comisión' },
    { prop: 'agencyPercentage', width: 300, name: '% agencia' },
    { prop: 'premiumCalculated', width: 300, name: 'Abono prima' },
    { prop: 'commissionValue', width: 300, name: 'Valor comisión' },
    { prop: 'retefuentePercentage', width: 300, name: 'Retefuente 11%' },
    { prop: 'retefuentePercentageCalculated', width: 300, name: 'Retefuente 11%' },
    { prop: 'iva', width: 300, name: 'IVA' },
    { prop: 'icaPercentage', width: 300, name: 'ICA 0%' },
    { prop: 'icaPercentageCalculated', width: 300, name: 'ICA 0%' },
    { prop: 'creePercentage', width: 300, name: 'CREE 0%' },
    { prop: 'creePercentageCalculated', width: 300, name: 'CREE 0%' },
    { prop: 'commissionCalculated', width: 300, name: 'Neto Comisión' },
    { prop: 'documentTypeId', width: 300, name: 'Tipo de documento' }
  ];

  rateControl = new FormControl('', [Validators.max(100), Validators.min(0)])

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService,
    private modalService: NgbModal
  ) {
    this.changeText = false;
    setTimeout(() => {
      this.loadingIndicator = false;
    }, 1500);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.value.firstChange) {
      this.getInstallments(this.searchCommissionModel);
      this.selected = [];
    }
  }

  ngOnInit() {
    this.listCommission = new Array<CommissionModelResponse>();
    this.selected = [];
  }



  getInstallments(searchCommissionModel: SearchCommissionModel) {
    const path = 'cuotas/listar-comisiones-por-filtro';
    this.listCommission = new Array<CommissionModelResponse>();
    this.crudService.putModel(path, searchCommissionModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          if (genericResponse.answerList.length > 0) {
            this.listCommission = genericResponse.answerList;
            this.ica = genericResponse.answerList[0].icaPercentage;
            this.cree = genericResponse.answerList[0].creePercentage;
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se encontraron comisiones por cancelar',
              '¡Alerta!');
          }
          // this.checkboxState = false;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las comisiones',
            'Problema consultando las comisiones');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar las comisiones: ' + JSON.stringify(error))
      }
    );
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push( ...selected );
  }

  onActivate(event) {
    if (event.type === 'click' && event.column.prop === 'premiumCalculated') {
      const rowAux = event.row;
      if (rowAux.sellerId.documentTypeId.name !== 'NIT') {
        this.calculateNaturalEntity(rowAux);
      } else {
        this.calculateLegalEntity(rowAux);
      }
    }
    if (event.type === 'click' && event.column.prop === 'retefuentePercentageCalculated') {
      const rowAux = event.row;
      if (rowAux.sellerId.documentTypeId.name !== 'NIT') {
        this.recalculateRetefuenteNatural(rowAux);
      } else {
        this.recalculateRetefuenteLegal(rowAux);
      }
    }
    if (event.type === 'click' && event.column.prop === 'icaPercentageCalculated') {
      const rowAux = event.row;
      if (rowAux.sellerId.documentTypeId.name !== 'NIT') {
        this.recalculateICA(rowAux);
      }
    }
    if (event.type === 'click' && event.column.prop === 'creePercentageCalculated') {
      const rowAux = event.row;
      if (rowAux.sellerId.documentTypeId.name !== 'NIT') {
        this.recalculateCREE(rowAux);
      }
    }
  }

  recalculateRetefuenteNatural(rowAux: any): any {
    const agencyCalculated = (rowAux.agencyPercentage / 100) * rowAux.commissionValue;
    rowAux.commissionCalculated = rowAux.commissionValue -
      (rowAux.retefuentePercentageCalculated + rowAux.icaPercentageCalculated + rowAux.creePercentageCalculated) - agencyCalculated;
  }

  recalculateRetefuenteLegal(rowAux: any): any {
    const agencyCalculated = (rowAux.agencyPercentage / 100) * rowAux.commissionValue;
    rowAux.commissionCalculated = rowAux.commissionValue + rowAux.iva - rowAux.retefuentePercentageCalculated - agencyCalculated;
  }

  recalculateICA(rowAux: any): any {
    const agencyCalculated = (rowAux.agencyPercentage / 100) * rowAux.commissionValue;
    rowAux.commissionCalculated = rowAux.commissionValue -
      (rowAux.retefuentePercentageCalculated + rowAux.icaPercentageCalculated + rowAux.creePercentageCalculated) - agencyCalculated;
  }

  recalculateCREE(rowAux: any): any {
    const agencyCalculated = (rowAux.agencyPercentage / 100) * rowAux.commissionValue;
    rowAux.commissionCalculated = rowAux.commissionValue -
      (rowAux.retefuentePercentageCalculated + rowAux.icaPercentageCalculated + rowAux.creePercentageCalculated) - agencyCalculated;
  }

  calculateLegalEntity(rowAux: any): any {
    rowAux.creePercentage = 0;
    rowAux.icaPercentage = 0;
    rowAux.commissionValue = (rowAux.commissionPercentage / 100) * rowAux.premiumCalculated;
    const agencyCalculated = (rowAux.agencyPercentage / 100) * rowAux.commissionValue;
    const retefuenteCalculated = (rowAux.commissionValue -
      (rowAux.commissionValue * (rowAux.agencyPercentage / 100))) * (rowAux.retefuentePercentage / 100)
    rowAux.retefuentePercentageCalculated = retefuenteCalculated;
    const iva = 0.19 * (rowAux.commissionValue - (rowAux.commissionValue * (rowAux.agencyPercentage / 100)))
    rowAux.iva = iva;
    rowAux.commissionCalculated = rowAux.commissionValue + iva - retefuenteCalculated - agencyCalculated;
    return rowAux;
  }

  calculateNaturalEntity(rowAux: any): any {
    rowAux.commissionValue = (rowAux.commissionPercentage / 100) * rowAux.premiumCalculated;
    const agencyCalculated = (rowAux.agencyPercentage / 100) * rowAux.commissionValue;
    const retefuenteCalculated = (rowAux.retefuentePercentage / 100) * rowAux.commissionValue;
    rowAux.retefuentePercentageCalculated = retefuenteCalculated;
    const icaCalculated = (rowAux.icaPercentage / 100) * rowAux.commissionValue;
    rowAux.icaPercentageCalculated = icaCalculated;
    const creeCalculated = (rowAux.creePercentage / 100) * rowAux.commissionValue;
    rowAux.creePercentageCalculated = creeCalculated;

    rowAux.commissionCalculated = rowAux.commissionValue - (retefuenteCalculated + icaCalculated + creeCalculated) - agencyCalculated;
    return rowAux;
  }

  add() {
    this.selected.push(this.listCommission[1], this.listCommission[3]);
  }

  update() {
    this.selected = [this.listCommission[1], this.listCommission[3]];
  }

  remove() {
    this.selected = [];
  }

  displayCheck(row) {
    return row;
  }

  openPaymentsDocument() {
    const modal = this.modalService.open(ResultCommissionsComponent, {
      windowClass: 'largeModal', size: 'lg', backdrop: 'static'
    });

    modal.componentInstance.rows = this.selected;
    modal.componentInstance.passEntry.subscribe(retorno => {
      if (retorno === 'ok') {
        this.ngOnInit();
      }

    });
  }

  checkboxChangeState() {
    this.checkboxState = !this.checkboxState;
  }

  currencyInputChanged(value) {
    const num = value.toString().replace(/[$,]/g, '');
    return Number(num);
  }

  percentInputChanged(value) {
    const num = value.toString().replace(/[$%]/g, '');
    if (num !== '0') {
      return Number(num);
    } else {
      return 0;
    }
  }

  rtfChanged(row: any): void {
    const rowAux = row;
    if (rowAux.sellerId.documentTypeId.name !== 'NIT') {
      this.calculateNaturalEntity(rowAux);
    } else {
      this.calculateLegalEntity(rowAux);
    }
  }

  onKeydownICA(row: any): void {
    const rowAux = row;
    if (rowAux.sellerId.documentTypeId.name !== 'NIT') {
      this.recalculateICA(rowAux);
    }
  }

  onKeydownCREE(row: any): void {
    const rowAux = row;
    if (rowAux.sellerId.documentTypeId.name !== 'NIT') {
      this.recalculateCREE(rowAux);
    }
  }

}
