export class SearchCommissionModel {
    classOfInsuranceId: any;
    insurerId: any;
    sellerId: any;
    policy: string;
    checkbox: string;

    public constructor() {
        this.classOfInsuranceId = {id: null};
        this.insurerId = {id: null};
        this.sellerId = {id: null};
        this.policy = '';
        this.checkbox = null;
    }
}
