import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { SearchCommissionModel } from 'models/utilities/search-commission-model';
import { ListCommissionsComponent } from '../list-commissions/list-commissions.component';
import { CommissionModelResponse } from 'models/entities/commission-response.model';

@Component({
  selector: 'app-manage-commissions',
  templateUrl: './manage-commissions.component.html',
  styleUrls: ['./manage-commissions.component.scss']
})
export class ManageCommissionsComponent implements OnInit {
  searchModel: CommissionModelResponse = new CommissionModelResponse();
  searchCommissionModel: SearchCommissionModel = new SearchCommissionModel();
  value: string;
  @ViewChild(ListCommissionsComponent) listCommissions: ListCommissionsComponent;

  listCommission: any;
  // searchCommissionModel: any;

  constructor() { }

  ngOnInit() {
  }

  newSearchReport(newSearch) {
    this.searchModel = { ...newSearch };
  }

  cleanSearch(value: string) {
    this.listCommissions.ngOnInit();
  }

}
