import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { ChangePasswordByComponent } from './../change-password-by/change-password-by.component';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { DocumentTypeModel } from 'models/entities/document-type-model';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent implements OnInit {

  form: any = {};
  public planInfo: any;
  user: any;
  role = 'ROLE_ADMIN';
  errorMessage = '';
  public selected: any;
  @Input() public userInfoUpdate;
  @Input() public isView = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('f') updateUserForm: NgForm;
  listDocumentTypes: Array<DocumentTypeModel> = new Array();
  roleTitle = this.tokenStorage.getAuthorities();


  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private modalService: NgbModal,
    private tokenStorage: TokenStorageService,
    private userForm: FormBuilder) { }

  ngOnInit() {
    this.role = this.tokenStorage.getAuthorities();
    this.getDocumentsTypes();
    if (this.tokenStorage.getAuthorities() === 'ROLE_SUPER') {
      this.configSelectPlan();
    }
    console.log(this.userInfoUpdate);
    this.user = this.userForm.group({
      id: this.userInfoUpdate.userAux.id,
      completeName: this.userInfoUpdate.userAux.completeName,
      email: this.userInfoUpdate.userAux.email,
      username: this.userInfoUpdate.userAux.username,
      address: this.userInfoUpdate.userAux.address,
      role: this.userInfoUpdate.userAux.role,
      phone: this.userInfoUpdate.userAux.phone,
      documentTypeId: this.userInfoUpdate.userAux.documentTypeId.id,
      documentNumber: this.userInfoUpdate.userAux.documentNumber,
      nameCompany: this.userInfoUpdate.nameCompany,
      activatedPlanId: this.userInfoUpdate.idActivatedPlan
    });
  }

  getDocumentsTypes() {
    const pathListDOcumentTypesUrl = 'tipos-de-documento/ver-por-objetivo?objective=Personal';
    this.listDocumentTypes = new Array();
    this.crudService.getModel(pathListDOcumentTypesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listDocumentTypesAux = genericResponse.answerList;
          listDocumentTypesAux.forEach(documentType => {
            this.listDocumentTypes.push(documentType);
          });
          this.user.documentTypeId = this.listDocumentTypes[0];
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los tipos de documentos del sistema',
            'Problema consultando los tipos de documentos del sistema');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los tipos de documentos del sistema ' + JSON.stringify(error))
      }
    );
  }

  passBack() {    
    let formValue = this.user.value;
     
    this.passEntry.emit(formValue);
  }

  configSelectPlan() {
    this.crudService.getModel('plan/listar-todas').subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.planInfo = genericResponse.answerList;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron planes')
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  changePassword() {
    const userInfo = {
      'idUser': this.userInfoUpdate.userAux.id,
      'name': this.userInfoUpdate.userAux.name
    };
    const modalRef = this.modalService.open(ChangePasswordByComponent, {
      windowClass: 'modal', size: 'lg'
    });
    modalRef.componentInstance.userInfo = userInfo;
  }

  closeWindow() {
    this.modalService.dismissAll();
  }


}
