import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsRoutingModule } from './reports-routing.module';
import { HappyBirthDayReportComponent } from './happy-birth-day-report/happy-birth-day-report.component';
import { ExpirationPolicyReportComponent } from './expiration-policy-report/expiration-policy-report.component';
import { MixClassOfInsuranceReportComponent } from './mix-class-of-insurance-report/mix-class-of-insurance-report.component';
import { ProductivityReportComponent } from './productivity-report/productivity-report.component';
import { VehicleByLicensePlateReportComponent } from './vehicle-by-license-plate-report/vehicle-by-license-plate-report.component';
import { ActivitiesByManagerReportComponent } from './activities-by-manager-report/activities-by-manager-report.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { NgbModalModule, NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxLoadingModule } from 'ngx-loading';
import { PortfolioReportComponent } from './portfolio-report/portfolio-report.component';
import { CommissionReportComponent } from './commission-report/commission-report.component';
import { CommissionsByInsurerComponent } from './commissions-by-insurer/commissions-by-insurer.component';
import { CommissionsBySellerComponent } from './commissions-by-seller/commissions-by-seller.component';

@NgModule({
  declarations: [
    HappyBirthDayReportComponent,
    ExpirationPolicyReportComponent,
    MixClassOfInsuranceReportComponent,
    ProductivityReportComponent,
    VehicleByLicensePlateReportComponent,
    ActivitiesByManagerReportComponent,
    PortfolioReportComponent,
    CommissionReportComponent,
    CommissionsByInsurerComponent,
    CommissionsBySellerComponent
  ],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    NgxDatatableModule,
    FormsModule,
    NgxLoadingModule,
    NgbModalModule,
    NgbModule,
    NgxDatatableModule,
    ArchwizardModule,
  ],
  exports: [
    HappyBirthDayReportComponent,
    ExpirationPolicyReportComponent,
    MixClassOfInsuranceReportComponent,
    ProductivityReportComponent,
    VehicleByLicensePlateReportComponent,
    ActivitiesByManagerReportComponent,
    FormsModule,
    ArchwizardModule,
    NgbModule,
    NgxDatatableModule,
    NgxLoadingModule,
    CommissionReportComponent
  ],
  entryComponents: [
    ActivitiesByManagerReportComponent],
    providers: [
      NgbActiveModal
    ]
})
export class ReportsModule { }
