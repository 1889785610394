import { PlansModel } from './../../../models/entities/plans-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-detail-plan',
  templateUrl: './detail-plan.component.html',
  styleUrls: ['./detail-plan.component.scss']
})
export class DetailPlanComponent implements OnInit {

  @Input() public planInfo = new PlansModel()
  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }
  closeWindow() {
    this.modalService.dismissAll();
  }

}
