import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminPrincipalRuPoliciesComponent } from './../admin-principal-ru-policies/admin-principal-ru-policies.component';
import { SearchPolicyModel } from './../../../models/utilities/search-policy-model';
import { ManageClientsComponent } from 'app/clients/manage-clients/manage-clients.component';
import { ModalInfo } from 'models/entities/modal-info';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { PolicyModel } from 'models/entities/policy-model';
import { NgbTypeahead, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InsurerModel } from 'models/entities/insurer-model';
import { ClassOfInsuranceModel } from 'models/entities/class-of-insurance.model';
import { UserModel } from 'models/entities/user-model';
import { ClientModel } from 'models/entities/client-model';
import { DocumentTypeModel } from 'models/entities/document-type-model';
import { DepartmentModel } from 'models/entities/department-model';
import { BeneficiaryModel } from 'models/entities/beneficiary-model';
import { CreateBeneficiaryComponent } from '../create-beneficiary/create-beneficiary.component';
import { ModalInformationModel } from 'models/utilities/modal-information-model';
import swal from 'sweetalert2';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { DatePipe } from '@angular/common';
import { BudgetModel } from 'models/entities/budget-model';
import { BudgetInPolicyComponent } from '../budget-in-policy/budget-in-policy.component';
import {SharedService} from "../../shared/shared.service";

@Component({
  selector: 'app-cru-personal-info',
  templateUrl: './cru-personal-info.component.html',
  styleUrls: ['./cru-personal-info.component.scss']
})
export class CruPersonalInfoComponent implements OnInit {

  generalInfo = false;
  personalInfoCLient = false;
  personalInfoInsured = false;
  personalInfoBeneficiary = false;
  budgetInfo = false;
  listInsurer: Array<InsurerModel> = new Array();
  listClassOfInsurances: Array<ClassOfInsuranceModel> = new Array();
  listSellers: Array<UserModel> = new Array();
  listClients: Array<ClientModel> = new Array();
  listDocumentTypes: Array<DocumentTypeModel> = new Array();
  listDepartments: Array<DepartmentModel> = new Array();
  listBeneficiaries: Array<BeneficiaryModel> = new Array();
  @ViewChild('formInfoPersonal') createInfoPolizaForm: NgForm;
  @ViewChild('instanceInsurer') instanceInsurer: NgbTypeahead;
  @ViewChild('instanceClassOfInsurance') instanceClassOfInsurance: NgbTypeahead;
  @ViewChild('instanceSeller') instanceSeller: NgbTypeahead;
  @ViewChild('instanceClient') instanceClient: NgbTypeahead;
  @ViewChild(BudgetInPolicyComponent) instanceBudgetInPolicy: BudgetInPolicyComponent;
  @Input() policyModel: PolicyModel = new PolicyModel();
  @Input() budgetModel: BudgetModel = new BudgetModel();
  focusInsurer$ = new Subject<string>();
  clickInsurer$ = new Subject<string>();
  focusClassOfInsurance$ = new Subject<string>();
  clickClassOfInsurance$ = new Subject<string>();
  focusSeller$ = new Subject<string>();
  clickSeller$ = new Subject<string>();
  focusClient$ = new Subject<string>();
  clickClient$ = new Subject<string>();
  focusClientIn$ = new Subject<string>();
  clickClientIn$ = new Subject<string>();
  columnsBeneficiary = [
    { prop: 'name' },
    { prop: 'phone' },
    { prop: 'percent' },
    { prop: 'documentNumber' },
  ]
  @Output() emitter: EventEmitter<any> = new EventEmitter();
  @Output() changesEmmiter: EventEmitter<any> = new EventEmitter();
  today = '';

  beneficiaryChangedList = new Array();

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private modalService: NgbModal,
    private currentModal: NgbActiveModal,
    private datePipe: DatePipe,
    private tokenStorageService: TokenStorageService,
    private sharedService: SharedService
  ) {
    this.today = this.transformDate(new Date());
    this.policyModel.validSince = this.transformDate(new Date());
    this.policyModel.validUntil = this.transformDate(new Date());
    this.policyModel.expeditionDate = this.transformDate(new Date());
  }

  ngOnInit() {
    this.getInsurers();
    this.getClassOfInsurances();
    this.getSellers();
    this.getClients();
    this.getDocumentsTypes();
    console.log('El valor del policyModel.id es: '+JSON.stringify(this.policyModel.id))
    if (this.policyModel.id !== '' && this.policyModel.id !== null) {
      console.log('Entró al IF');
      this.getBeneficiaries();
      this.getBudgetOfCreate();
    }
  }

  getBudgetOfCreate() {
    const pathBudgetModelUrl = 'presupuesto/ver-presupuesto-de-creacion-por-poliza?policyId=' + this.policyModel.id;
    this.budgetModel = new BudgetModel();
    this.crudService.getModel(pathBudgetModelUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          if (genericResponse.genericObject !== null) {
            this.budgetModel = genericResponse.genericObject;
          }
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo obtener el presupuesto',
            'Problema consultando el presupuesto');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar el presupuesto: ' + JSON.stringify(error))
      }
    );
  }


  getBeneficiaries() {
    const pathListBeneficiariesUrl = 'beneficiario/listar-beneficiarios-por-poliza?policyId=' + this.policyModel.id;
    this.listBeneficiaries = new Array();
    this.crudService.getModel(pathListBeneficiariesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          if (genericResponse.answerList !== null) {
            this.listBeneficiaries = genericResponse.answerList;
          }
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los beneficiarios',
            'Problema consultando los beneficiarios');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los beneficiarios: ' + JSON.stringify(error))
      }
    );
  }

  transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  emitModel() {
    this.changesEmmiter.emit(this.beneficiaryChangedList);
    this.emitter.emit(
      {
        'policyModel': this.policyModel,
        'budgetModel': this.budgetModel
      }
    );
  }

  allIsRight() {
    //console.log("vendedor",this.policyModel.sellerUserId)
    return this.containsValidation(this.policyModel.clientId, this.listClients)
      && this.containsValidation(this.policyModel.insuredId, this.listClients)
      && this.containsValidation(this.policyModel.classOfInsuranceId, this.listClassOfInsurances)
      && this.containsValidation(this.policyModel.sellerUserId, this.listSellers) 
      && (this.policyModel.validSince !== '' && this.policyModel.validSince !== null)
      && (this.policyModel.policy !== '' && this.policyModel.policy !== null)
       
  } 

  isCurrent() {
    return this.containsValidation(this.policyModel.insurerId, this.listInsurer)
      && this.containsValidation(this.policyModel.sellerUserId, this.listSellers)
      && this.containsValidation(this.policyModel.classOfInsuranceId, this.listClassOfInsurances)
      && this.budgetModel.totalWithIva !== '0'
      && (this.policyModel.validSince !== '' && this.policyModel.validSince !== null)
      && (this.policyModel.validUntil !== '' && this.policyModel.validUntil !== null)
      && (this.policyModel.policy !== '' && this.policyModel.policy !== null)
      && this.containsValidation(this.policyModel.clientId, this.listClients)
      && this.containsValidation(this.policyModel.insuredId, this.listClients)
      && this.instanceBudgetInPolicy.containsValidation();
  }

  preparePolicy() {
    if (!this.containsValidation(this.policyModel.insurerId, this.listInsurer)) {
      this.policyModel.insurerId = null;
    }
    if (!this.containsValidation(this.policyModel.sellerUserId, this.listSellers)) {
      this.policyModel.sellerUserId = null;
    }
    if (!this.instanceBudgetInPolicy.containsValidation()) {
      this.budgetModel = null;
    }
  }

  getInsurers() {
    const pathListInsurerUrl = 'empresa/aseguradora/listar-aseguradoras';
    this.listInsurer = new Array();
    this.crudService.getModel(pathListInsurerUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listInsurerAux = genericResponse.answerList;
          listInsurerAux.forEach(insurer => {
            this.listInsurer.push(insurer);
          });
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las aseguradoras',
            'Problema consultando aseguradoras');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar las aseguradoras: ' + JSON.stringify(error))
      }
    );
  }

  getClassOfInsurances() {
    const pathListClassOfInsurancesUrl = 'ramos/listar-ramos';
    this.listClassOfInsurances = new Array();
    this.crudService.getModel(pathListClassOfInsurancesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listClassOfInsurancesAux = genericResponse.answerList;
          listClassOfInsurancesAux.forEach(classOfInsurances => {
            this.listClassOfInsurances.push(classOfInsurances);
          });
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los tipos de ramos',
            'Problema consultando los tipos de ramos');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los tipos de ramos: ' + JSON.stringify(error))
      }
    );
  }

  getSellers() {
    const pathListSellersUrl = 'usuarios/listar-vendedores-y-gestores';
    this.listSellers = new Array();
    this.crudService.getModel(pathListSellersUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listSellersAux = genericResponse.answerList;
          listSellersAux.forEach(seller => {
            this.listSellers.push(seller);
          });
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los vendedores o gestores',
            'Problema consultando los vendedores o gestores');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los vendedores o gestores: ' + JSON.stringify(error))
      }
    );
  }

  getClients() {
    const pathListClientsUrl = 'cliente/listar-nombre-por-empresa';
    this.listClients = new Array();
    this.crudService.getModel(pathListClientsUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listClientsAux = genericResponse.answerList;
          listClientsAux.forEach(client => {
            this.listClients.push(client);
          });
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los clientes asociados a su empresa',
            'Problema consultando los clientes asociados a su empresa');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los clientes asociados a su empresa ' + JSON.stringify(error))
      }
    );
  }

  getDocumentsTypes() {
    const pathListDOcumentTypesUrl = 'tipos-de-documento/ver-por-objetivo?objective=Personal';
    this.listDocumentTypes = new Array();
    this.crudService.getModel(pathListDOcumentTypesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listDocumentTypesAux = genericResponse.answerList;
          listDocumentTypesAux.forEach(documentType => {
            this.listDocumentTypes.push(documentType);
          });
          if (!this.policyModel.id) {
            this.policyModel.clientId.documentTypeId = this.listDocumentTypes[0]
            this.policyModel.insuredId.documentTypeId = this.listDocumentTypes[0]
          }
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los tipos de documentos del sistema',
            'Problema consultando los tipos de documentos del sistema');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los tipos de documentos del sistema ' + JSON.stringify(error))
      }
    );
  }

  copyInsured() {
    console.log(this.policyModel.sellerAndInsuredEquals)
    if (this.policyModel.sellerAndInsuredEquals) {
      let insuredCopy: ClientModel = new ClientModel();
      insuredCopy = this.policyModel.clientId;
      this.policyModel.insuredId = insuredCopy;
      console.log('Cambié el dato y se debe cargar el tomador y el asegurado iguales'+JSON.stringify(insuredCopy));
    } else {
      this.policyModel.insuredId = new ClientModel();
      //this.policyModel.insuredId.departmentId = this.listDepartments[0];
      //this.policyModel.insuredId.documentTypeId = this.listDocumentTypes[0];
    }
  }

  getPercentTotal() {
    return this.listBeneficiaries.reduce((sum, beneficiary) => sum + +beneficiary.percent, 0)
  }

  createBeneficiary() {
    const modal = this.modalService.open(CreateBeneficiaryComponent, {
      windowClass: 'modal', size: 'lg', backdrop: false
    });
    const modalInformationToSend: ModalInformationModel = new ModalInformationModel();
    const arrSum = this.listBeneficiaries.reduce((sum, beneficiary) => sum + +beneficiary.percent, 0)
    modalInformationToSend.isCreate = true;
    modal.componentInstance.modalInformation = modalInformationToSend
    modal.componentInstance.maxPercent = 100 - arrSum;
    modal.componentInstance.beneficiaryModel = new BeneficiaryModel();
    modal.componentInstance.passEntry.subscribe(newBeneficiary => {
      if (newBeneficiary !== null) {
        this.listBeneficiaries = this.listBeneficiaries.concat(newBeneficiary);
        this.beneficiaryChangedList.push('Se ha creado un nuevo beneficiario con  nombre ' + newBeneficiary.name);
        modal.dismiss();
      } else {
        modal.dismiss();
      }
    });
  }

  updateBeneficiary(beneficiaryToUpdate: BeneficiaryModel) {
    const modal = this.modalService.open(CreateBeneficiaryComponent, {
      windowClass: 'modal', size: 'lg', backdrop: false
    });
    const modalInformationToSend: ModalInformationModel = new ModalInformationModel();
    const arrSum = this.listBeneficiaries.reduce((sum, beneficiary) => +sum + +beneficiary.percent, 0)
    modalInformationToSend.isUpdate = true;
    modal.componentInstance.modalInformation = modalInformationToSend
    modal.componentInstance.maxPercent = Number(100) - Number(arrSum) + Number(beneficiaryToUpdate.percent);
    const beneficiaryToUpdateToSend: BeneficiaryModel = new BeneficiaryModel();
    beneficiaryToUpdateToSend.id = beneficiaryToUpdate.id;
    beneficiaryToUpdateToSend.name = beneficiaryToUpdate.name;
    beneficiaryToUpdateToSend.phone = beneficiaryToUpdate.phone;
    beneficiaryToUpdateToSend.percent = beneficiaryToUpdate.percent;
    beneficiaryToUpdateToSend.createdDate = beneficiaryToUpdate.createdDate;
    beneficiaryToUpdateToSend.documentTypeId = beneficiaryToUpdate.documentTypeId;
    beneficiaryToUpdateToSend.documentNumber = beneficiaryToUpdate.documentNumber;
    beneficiaryToUpdateToSend.lastUpdateDate = beneficiaryToUpdate.lastUpdateDate;
    beneficiaryToUpdateToSend.creatorId = beneficiaryToUpdate.creatorId;
    beneficiaryToUpdateToSend.lastUpdateId = beneficiaryToUpdate.lastUpdateId;
    modal.componentInstance.beneficiaryModel = beneficiaryToUpdateToSend;
    modal.componentInstance.passEntry.subscribe(updatedBeneficiary => {
      if (updatedBeneficiary !== null) {
        this.listBeneficiaries.splice(
          this.listBeneficiaries.indexOf(beneficiaryToUpdate),
          this.listBeneficiaries.indexOf(beneficiaryToUpdate) + 1
        );
        this.listBeneficiaries = this.listBeneficiaries.concat(updatedBeneficiary);
        this.beneficiaryChangedList.push('Se ha actualizado la información del beneficiario ' + beneficiaryToUpdate.name);
        modal.dismiss();
      } else {
        modal.dismiss();
      }
    });
  }

  viewBeneficiary(beneficiaryToUpdate: BeneficiaryModel) {
    const modal = this.modalService.open(CreateBeneficiaryComponent, {
      windowClass: 'modal', size: 'lg'
    });
    const modalInformationToSend: ModalInformationModel = new ModalInformationModel();
    const arrSum = this.listBeneficiaries.reduce((sum, beneficiary) => sum + +beneficiary.percent, 0)
    modalInformationToSend.isView = true;
    modal.componentInstance.modalInformation = modalInformationToSend
    modal.componentInstance.beneficiaryModel = beneficiaryToUpdate;
    modal.componentInstance.passEntry.subscribe(() => {
      modal.dismiss();
    });
  }

  deleteBeneficiary(beneficiaryToUpdate: BeneficiaryModel) {
    swal({
      title: '¿Está seguro que desea eliminar el beneficiario?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.listBeneficiaries.splice(
          this.listBeneficiaries.indexOf(beneficiaryToUpdate),
          this.listBeneficiaries.indexOf(beneficiaryToUpdate) + 1
        );
        this.beneficiaryChangedList.push('Se ha eliminado el beneficiario ' + beneficiaryToUpdate.name);
      }
    })
  }

  onSubmit() {

    const searchModel = new SearchPolicyModel();
    const pathListPoliciesUrl = 'poliza/consultar-existencia-poliza';
    searchModel.classOfInsuranceId = this.policyModel.classOfInsuranceId;
    searchModel.insurerId = this.policyModel.insurerId;
    searchModel.policyNumber = this.policyModel.policy;
    this.crudService.putModelParams(pathListPoliciesUrl, searchModel, {
      policyId: this.policyModel.id
    }).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          if (genericResponse.answerList.length > 0) {
            swal({
              title: 'No podemos crear la póliza, se ha encontrado una existente con lo datos ingresados, ¿Desea visualizarla?',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: '¡Sí, ver!',
              cancelButtonText: 'Volver'
            }).then((result) => {
              if (result.value) {
                this.currentModal.close();
                const modal = this.modalService.open(AdminPrincipalRuPoliciesComponent, {
                  windowClass: 'largeModal', size: 'lg', backdrop: false
                });
                modal.componentInstance.policyModel = genericResponse.answerList[0].policy;
              }
            });
          } else {
            if (this.isCurrent()) {
              this.policyModel.state = 'CURRENT' // Póliza en estado vigente
            } else {
              this.policyModel.state = 'DRAFT' // Póliza en estado borrador
              this.preparePolicy();
            }
            if (this.policyModel.createdDate) {
              this.policyModel.createdDate = new Date(new Date(this.policyModel.createdDate).getTime() + 86400000).toUTCString();
            }
            this.policyModel.creatorId.id = this.tokenStorageService.getId();
            this.policyModel.companyId.id = this.tokenStorageService.getCompanyId();

            const policyToSend = { ...this.policyModel };

            const expeditionArray = policyToSend.expeditionDate.split('-');
            const validSinceArray = policyToSend.validSince.split('-');
            const validUntilAray = policyToSend.validUntil.split('-');
            policyToSend.expeditionDate = new Date(expeditionArray[0], Number(expeditionArray[1]) - 1,
              Number(expeditionArray[2])).toUTCString();

            policyToSend.validSince = new Date(validSinceArray[0], Number(validSinceArray[1]) - 1,
              Number(validSinceArray[2])).toUTCString();

            policyToSend.validUntil = new Date(validUntilAray[0], Number(validUntilAray[1]) - 1,
              Number(validUntilAray[2])).toUTCString();

            this.crudService.createModel('poliza/crear-poliza', policyToSend).subscribe(
              (genericResponse: GenericResponseModel) => {
                if (genericResponse.code === 200) {
                  this.policyModel = genericResponse.genericObject;
                  this.sharedService.setRiskInfo({
                    client: this.policyModel.clientId,
                    validSince: this.policyModel.validSince,
                    validUntil: this.policyModel.validUntil
                  });
                  this.createBeneficiaries();
                } else {
                  this.messageService.getInfoMessagePersonalized('warning', 'No se pudo guardar la poliza con exito',
                    'Problema creando la poliza');
                }
              }, (error) => {
                this.messageService.getInfoMessageError();
                console.error('Error, no se pudo guardar la póliza con exito: ' + JSON.stringify(error))
              }
            )
          }
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo consultar la existencia de su póliza',
            'Problema consultando en el servidor');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al consultar polizar en el sistema ' + JSON.stringify(error))
      }
    );
  }

  async createBeneficiaries() {
    await this.listBeneficiaries.forEach(async beneficiary => {
      beneficiary.policyId = await { id: this.policyModel.id };
      beneficiary.creatorId.id = this.tokenStorageService.getId();
      beneficiary.createdDate = new Date().toUTCString();
    });
    this.crudService.createModel('beneficiario/crear-beneficiarios', this.listBeneficiaries).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.emitModel();
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo guardar los beneficiarios de la poliza',
            'Problema creando los beneficiarios');
        }
      }, (error) => {
        this.messageService.getInfoMessageError();
        console.error('Error, no se pudieron guardar los beneficiarios de la poliza: ' + JSON.stringify(error))
      }
    )
  }

  searchByNameInsurer = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusInsurer$),
      merge(this.clickInsurer$.pipe(filter(() => !this.instanceInsurer.isPopupOpen()))),
      map(search => (search === '' ? this.listInsurer
        : this.listInsurer.filter(insurer => insurer.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
    );

  searchByNameClassOfInsurance = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusClassOfInsurance$),
      merge(this.clickClassOfInsurance$.pipe(filter(() => !this.instanceClassOfInsurance.isPopupOpen()))),
      map(search => (search === '' ? this.listClassOfInsurances
        : this.listClassOfInsurances.filter(classOfInsurance =>
          classOfInsurance.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
    );

  searchByNameSeller = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusSeller$),
      merge(this.clickSeller$.pipe(filter(() => !this.instanceSeller.isPopupOpen()))),
      map(search => (search === '' ? this.listSellers
        : this.listSellers.filter(seller => seller.completeName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
    );

  searchByNameClient = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusClient$),
      merge(this.clickClient$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
      map(search => (search === '' ? this.listClients
        : this.searchClientServer(search)).slice(0, 10))
    );

  searchByNameClientIn = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusClientIn$),
      merge(this.clickClientIn$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
      map(search => (search === '' ? this.listClients
        : this.searchClientServer(search)).slice(0, 10))
    );

  formatter = (object: { name: string }) => object.name;
  formatterSeller = (object: { completeName: string }) => object.completeName;
  formatterClient = (object: { nameOrBusinessName: string }) => object.nameOrBusinessName;

  containsValidation(objectToValidate, listToSearch: Array<any>) {
    for (const object of listToSearch) {
      if (objectToValidate !== null) {
        if (object.id === objectToValidate.id) {
          return true;
        }
      }
    }
  }

  rebuildClient() {
    this.policyModel.sellerAndInsuredEquals = false;
    this.policyModel.clientId = new ClientModel();
    this.policyModel.clientId.departmentId = this.listDepartments[0];
    this.policyModel.clientId.documentTypeId = this.listDocumentTypes[0]
    this.policyModel.insuredId = new ClientModel();
    this.policyModel.insuredId.departmentId = this.listDepartments[0];
    this.policyModel.insuredId.documentTypeId = this.listDocumentTypes[0];
  }

  rebuildInsured() {
    this.policyModel.sellerAndInsuredEquals = false;
    this.policyModel.insuredId = new ClientModel();
    this.policyModel.insuredId.departmentId = this.listDepartments[0];
    this.policyModel.insuredId.documentTypeId = this.listDocumentTypes[0];
  }

  createClient() {
    const modal = this.modalService.open(ManageClientsComponent, {
      windowClass: '', size: 'lg', backdrop: 'static'
    });
    const modalInformationToSend: ModalInfo = new ModalInfo();
    modalInformationToSend.isView = false;
    modalInformationToSend.isUpdate = false;
    modalInformationToSend.isCreate = true;
    modal.componentInstance.modelInfoClient = modalInformationToSend;
    modal.componentInstance.passEntry.subscribe(retorno => {
      if (retorno === 'ok') {
        this.getClients();
      } else if (retorno === 'close') {
        modal.dismiss();
      }
    });
  }

  existPolicy() {
    const searchModel = new SearchPolicyModel();
    const pathListPoliciesUrl = 'poliza/consultar-existencia-poliza';
    searchModel.classOfInsuranceId = this.policyModel.classOfInsuranceId;
    searchModel.insurerId = this.policyModel.insurerId;
    searchModel.policyNumber = this.policyModel.policy;
    this.crudService.putModelParams(pathListPoliciesUrl, searchModel, {
      policyId: this.policyModel.id
    }).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          if (genericResponse.answerList.length > 0) {
            swal({
              title: 'Se ha encontrado una poliza, ¿Desea visualizarla?',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: '¡Sí, ver!',
              cancelButtonText: 'Volver'
            }).then((result) => {
              if (result.value) {
                this.currentModal.close();
                const modal = this.modalService.open(AdminPrincipalRuPoliciesComponent, {
                  windowClass: 'largeModal', size: 'lg', backdrop: false
                });
                modal.componentInstance.policyModel = genericResponse.answerList[0].policy;
              }
            });
          } else {
            swal({
              title: 'No se ha encontrado una poliza con los datos proporcionados',
              type: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: '¡Entiendo, continuar!'
            })
          }
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo consultar la existencia de su póliza',
            'Problema consultando en el servidor');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al consultar polizar en el sistema ' + JSON.stringify(error))
      }
    );
  }

  changeDate(newDate) {
    const selectedExpeditionDate = newDate.split('-');
    this.policyModel.validUntil = (Number(selectedExpeditionDate[0]) + 1) + '-'
      + this.validateCero(Number(selectedExpeditionDate[1])) + '-'
      + this.validateCero(Number(selectedExpeditionDate[2]));
    this.policyModel.validSince = newDate
    this.policyModel.expeditionDate = this.policyModel.validSince
  }



  validateCero(toValid) {
    if (toValid <= 9) {
      if (toValid === 0) {
        return '0' + 1
      } else {
        return '0' + toValid
      }

    } else {
      return toValid
    }
  }

  searchClientServer(search) {
    return this.listClients.filter(client => client.nameOrBusinessName.toLowerCase().indexOf(search.toLowerCase()) > -1)
  }

  changeIvaByClassOfInsurer() {
    if (this.containsValidation(this.policyModel.classOfInsuranceId, this.listClassOfInsurances)) {
      if (this.policyModel.classOfInsuranceId.id == 11 || this.policyModel.classOfInsuranceId.id == 6) {
        this.instanceBudgetInPolicy.budgetModel.iva = '0';
        this.instanceBudgetInPolicy.calculateTotals();
      } else {
        this.instanceBudgetInPolicy.budgetModel.iva = '19';
        this.instanceBudgetInPolicy.calculateTotals();
      }
      this.instanceBudgetInPolicy.classOfInsuranceSelected = this.policyModel.classOfInsuranceId;
      this.instanceBudgetInPolicy.updatePorcents();
    }
  }

  selectedClient(item){
    const pathFindClient = 'cliente/ver-por-nombre-o-documento?valueToSearch='+item.item.nameOrBusinessName;
    this.crudService.getModel(pathFindClient).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const clientAux = genericResponse.answerList[0];
          this.policyModel.clientId = clientAux;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo traer los datos del cliente',
            'No se pudo traer los datos del cliente');
        }
      }, error => {
        this.messageService.getInfoMessageError();
        console.error('No se pudo traer los datos del cliente ' + JSON.stringify(error))
      }
    );
    console.log(item.item.nameOrBusinessName);
  }

  selectedClientIn(item){
    const pathFindClient = 'cliente/ver-por-nombre-o-documento?valueToSearch='+item.item.nameOrBusinessName;
    this.crudService.getModel(pathFindClient).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const clientAux = genericResponse.answerList[0];
          this.policyModel.insuredId = clientAux;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo traer los datos del asegurado',
            'No se pudo traer los datos del asegurado');
        }
      }, error => {
        this.messageService.getInfoMessageError();
        console.error('No se pudo traer los datos del asegurado ' + JSON.stringify(error))
      }
    );
    console.log(item.item.nameOrBusinessName);
  }

}
