import { DocumentTypeModel } from './document-type-model';
export class CompanyModel {
    id: any;
    logoUrl: any;
    address: string;
    cellphone: string;
    email: string;
    phone: string;
    documentNumber: string;
    showPendingActivities: boolean;
    showHappybirthdayAlert: boolean;
    showDailyWallet: boolean;
    documentTypeId: DocumentTypeModel;
    createdDate: string;
    lastUpdateDate: string;
    creatorId: any;
    lastUpdateId: any;
    name: string;
    status: string;
    webPage: string;
    maxDocumentSize: any;
    ica: number;
    cree: number;
    sourceRetention: number;


    public constructor() {
        this.id = '';
        this.logoUrl = '';
        this.address = '';
        this.cellphone = '';
        this.email = '';
        this.phone = '';
        this.documentNumber = '';
        this.showPendingActivities = false;
        this.showHappybirthdayAlert = false;
        this.showDailyWallet = false;
        this.documentTypeId = new DocumentTypeModel();
        this.createdDate = '';
        this.lastUpdateDate = '';
        this.creatorId = null;
        this.lastUpdateId = null;
        this.name = '';
        this.status = '';
        this.webPage = '';
        this.maxDocumentSize = '';
        this.ica = 0;
        this.cree = 0;
        this.sourceRetention = 0;
    }
}