import { Component, OnInit, Output, EventEmitter, ViewChild} from '@angular/core';
import { SearchPolicyModel } from 'models/utilities/search-policy-model';
import { Subject, Observable } from 'rxjs';
import { ClassOfInsuranceModel } from 'models/entities/class-of-insurance.model';
import { ClientModel } from 'models/entities/client-model';
import { InsurerModel } from 'models/entities/insurer-model';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { UserModel } from 'models/entities/user-model';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { CommissionModelResponse } from 'models/entities/commission-response.model';
import { SearchCommissionModel } from 'models/utilities/search-commission-model';

@Component({
  selector: 'app-search-commissions',
  templateUrl: './search-commissions.component.html',
  styleUrls: ['./search-commissions.component.scss']
})
export class SearchCommissionsComponent implements OnInit {

  @Output() redirect: EventEmitter<any> = new EventEmitter();
  @Output() flag: EventEmitter<string> = new EventEmitter<string>();

  searchCommissionModel: SearchCommissionModel = new SearchCommissionModel();
  focusClassOfInsurance$ = new Subject<string>();
  clickClassOfInsurance$ = new Subject<string>();
  focusInsurer$ = new Subject<string>();
  clickInsurer$ = new Subject<string>();
  focusSeller$ = new Subject<string>();
  clickSeller$ = new Subject<string>();
  listClassOfInsurances: Array<ClassOfInsuranceModel> = new Array();
  listInsurer: Array<InsurerModel> = new Array();
  listSellers: Array<UserModel> = new Array();
  listCommissions: Array<CommissionModelResponse> = new Array();

  @Output() searchEmitter: EventEmitter<any> = new EventEmitter();
  @Output() cleanEmitter: EventEmitter<any> = new EventEmitter();

  @ViewChild('instanceClassOfInsurance') instanceClassOfInsurance: NgbTypeahead;
  @ViewChild('instanceInsurer') instanceInsurer: NgbTypeahead;
  @ViewChild('instanceClient') instanceClient: NgbTypeahead;
  @ViewChild('formSearch') searchingForm: NgForm;

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService
  ) { }

  ngOnInit() {
    this.getInsurers();
    this.getClassOfInsurances();
    this.getSellers();
  }

  getClassOfInsurances() {
    const pathListClassOfInsurancesUrl = 'ramos/listar-ramos';
    this.listClassOfInsurances = new Array();
    this.crudService.getModel(pathListClassOfInsurancesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listClassOfInsurancesAux = genericResponse.answerList;
          listClassOfInsurancesAux.forEach(classOfInsurances => {
            this.listClassOfInsurances.push(classOfInsurances);
          });
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los tipos de ramos',
            'Problema consultando los tipos de ramos');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los tipos de ramos: ' + JSON.stringify(error))
      }
    );
  }

  getInsurers() {
    const pathListInsurerUrl = 'empresa/aseguradora/listar-aseguradoras';
    this.listInsurer = new Array();
    this.crudService.getModel(pathListInsurerUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listInsurer = genericResponse.answerList;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las aseguradoras',
            'Problema consultando aseguradoras');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar las aseguradoras: ' + JSON.stringify(error))
      }
    );
  }

  getSellers() {
    const pathListSelle = 'usuarios/listar-vendedores-y-gestores';
    this.tokenStorage.getCompanyId()
    this.listSellers = new Array();
    this.crudService.getModel(pathListSelle).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listSeller = genericResponse['answerList'];
          listSeller.forEach(seller => {
            this.listSellers.push(seller);
          });
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized(
          'error',
          'No se pudo Listar Vendedores',
          'Error'
        );
        console.error('El error es ', JSON.stringify(error.message));
      }
    );
  }


  searchByNameClassOfInsurance = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusClassOfInsurance$),
      merge(this.clickClassOfInsurance$.pipe(filter(() => !this.instanceClassOfInsurance.isPopupOpen()))),
      map(search => (search === '' ? this.listClassOfInsurances
        : this.listClassOfInsurances.filter(classOfInsurance =>
          classOfInsurance.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
  );

  searchByNameInsurer = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusInsurer$),
      merge(this.clickInsurer$.pipe(filter(() => !this.instanceInsurer.isPopupOpen()))),
      map(search => (search === '' ? this.listInsurer
        : this.listInsurer.filter(insurer => insurer.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
  );


  searchSeller = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    merge(this.focusSeller$),
    merge(this.clickSeller$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
    map(search => (search === '' ? this.listSellers
    : this.listSellers.filter(seller => seller.completeName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
  );

  formatterSeller = (x: { completeName: string }) => x.completeName;
  formatter = (object: { name: string }) => object.name;

  rebuildClassOfInsurance() {
    this.searchCommissionModel.classOfInsuranceId = {id: null};
  }

  rebuildInsurer() {
    this.searchCommissionModel.insurerId = {id: null};
  }

  rebuildSeller() {
    this.searchCommissionModel.sellerId = {id: null};
  }

  onSearch(obj) {
    // First validate at least one element on searchModel
    const hasAnything = this.containsValidation(obj);
    if (hasAnything) {
      this.redirect.emit(this.searchCommissionModel); // emits the data to the parent
      const value = Math.random().toString();
      this.flag.emit(value); // emits the data to the parent
    } else {
      this.messageService.getInfoMessagePersonalized(
        'warning',
        'Debe seleccionar al menos un criterio de búsqueda',
        '¡Alerta!'
      );
    }
  }

  cleanSearch() {
    this.cleanEmitter.emit('clean');
    this.searchCommissionModel.checkbox = null;
    this.searchCommissionModel.classOfInsuranceId = {id: null};
    this.searchCommissionModel.insurerId = {id: null};
    this.searchCommissionModel.sellerId = {id: null};
    this.searchCommissionModel.policy = '';
  }

  containsValidation(objectToValidate: SearchCommissionModel ) {
    if (objectToValidate.classOfInsuranceId.id !== null ) {
      return true;
    } else if (objectToValidate.insurerId.id !== null) {
      return true;
    } else if ( objectToValidate.sellerId.id !== null) {
      return true;
    } else if (objectToValidate.policy !== '') {
      return true;
    } else if ( objectToValidate.classOfInsuranceId.id === null ||
                objectToValidate.insurerId.id === null ||
               objectToValidate.sellerId.id == null ||
               objectToValidate.policy === '') {
      console.warn('Ningun elemento de busqueda seleccionado');
      return false;
    }
  }

}
