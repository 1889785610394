import { CompanyModel } from './company-model';

export class ActivityStateModel{
    id: any;
    name: string
    finalizer: any;
    initiator: any;
    createdDate: string;
    lastUpdateDate: string;
    creatorId: any;
    lastUpdateId: any;
    companyId: CompanyModel;


    public constructor() {
        this.id = null;
        this.name = '';
        this.companyId = new CompanyModel();
        this.finalizer = false;
        this.initiator = false;
        this.createdDate = '';
        this.lastUpdateDate = '';
        this.creatorId = null;
        this.lastUpdateId = null;
    }
}