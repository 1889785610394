import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TokenStorageService } from '../../shared/storage-services/token-storage.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DetailmoduleComponent } from '../detailmodule/detailmodule.component';
import swal from 'sweetalert2';
import { UpdatemoduleComponent } from '../updatemodule/updatemodule.component';
import { CreateonlyModuleComponent } from '../createonly-module/createonly-module.component';
import { ToPairMenuComponent } from '../to-pair-menu/to-pair-menu.component';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { ModuleModel } from 'models/entities/module-model';

@Component({
  selector: 'app-listmodule',
  templateUrl: './listmodule.component.html',
  styleUrls: ['./listmodule.component.scss']
})
export class ListmoduleComponent implements OnInit {

  rows = [];
  temp: string[];
  board: string;
  dato: any;
  info = new ModuleModel();
  datosDetalle: any;
  button = [];
  columns = [
    { prop: 'name' },
    { name: 'createdDate' }
  ];

  constructor(
    private modalService: NgbModal,
    private tokenStorage: TokenStorageService,
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService
  ) { }

  ngOnInit() {
    this.configPage();
  }

  openCreateModule() {
    const modalRef = this.modalService.open(CreateonlyModuleComponent, {
      windowClass: '', size: 'lg',
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if (receivedEntry === 'Módulo Creado') {
        this.configPage();
      } else {
        return false;
      }
    })
  }

  configPage() {
    if (this.tokenStorage.getAuthorities() === 'ROLE_SUPER') {
      this.crudServices.getModel('modulos/super/ver-todos').subscribe(
        genericResponse => {
          if (genericResponse.code === 200) {
            this.dato = genericResponse.answerList;
            this.temp = [...this.dato];
            this.rows = this.dato;
          } else if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin modulos')
          }
        },
        error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    } else {
      this.crudServices.getModel('modulos/admin/listar-mis-modulos').subscribe(
        genericResponse => {
          if (genericResponse.code === 200) {
            this.dato = genericResponse.answerList;
            this.temp = [...this.dato.data];
            this.rows = this.dato.data;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin modulos')
          }
        },
        error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    }
  }

  listModuleDetail(id) {
    this.crudServices.getModel('modulos/detalle-modulo?moduleId=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.info = genericResponse.genericObject
          const modalRef = this.modalService.open(
            DetailmoduleComponent, {
              windowClass: '', size: 'lg',
            }
          );
          modalRef.componentInstance.moduleInfo = this.info;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando el modulo')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  onUpdateModules(id) {
    this.crudServices.getModel('modulos/detalle-modulo?moduleId=' + id).subscribe(
      geenricResponse => {
        if (geenricResponse.code === 200) {
          this.info = geenricResponse.genericObject;
          const modalRef = this.modalService.open(UpdatemoduleComponent, {
            windowClass: '', size: 'lg',
          });
          modalRef.componentInstance.moduleInfoUpdate = this.info;
          modalRef.componentInstance.passEntry.subscribe((moduleToUpdate) => {
            console.log(moduleToUpdate);
            this.crudServices.createModel('modulos/actualizar', moduleToUpdate).subscribe(
              geenricResponseUpdate => {
                if (geenricResponseUpdate.code === 200) {
                  swal({
                    title: 'Actualización Completa',
                    text: moduleToUpdate.name + ' se ha actualizado correctamente',
                    type: 'success'
                  }).then((result) => {
                    this.configPage();
                    this.modalService.dismissAll();
                  })
                }
                if (geenricResponseUpdate.code === 400) {
                  this.messageService.getInfoMessagePersonalized('warning', geenricResponseUpdate.answer, 'Error actualizando el modulo')
                }
              },
              error => {
                console.error(error);
                this.messageService.getInfoMessageBadInternet();
              }
            );
          })
        }
        if (geenricResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', geenricResponse.answer, 'Error consultanto el modulo')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  toPairMenu(id) {
    this.crudServices.getModel('modulos/detalle-modulo?moduleId=' + id).subscribe(
      data => {
        if (data.code === 200) {
          this.info = data.genericObject;
          const modalRef = this.modalService.open(ToPairMenuComponent, {
            windowClass: '', size: 'lg',
          });
          modalRef.componentInstance.moduleInfoUpdate = this.info;
        }
        if (data.code === 400) {
          swal({ type: 'error', text: data.answer })
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  onDelete(id) {
    this.crudServices.deleteModel('modulos/eliminar?moduleId=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.configPage();
          this.messageService.getInfoMessageDelete();
        } else if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error eliminando el modulo')
        }
      },
      error => {
        console.error(error);
        if (error.error.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', error.error.answer, 'Error eliminando el modulo')
        } else {
          this.messageService.getInfoMessageBadInternet();
        }
      }
    );
  }

  deleteWarning(info) {
    event.preventDefault();
    swal({
      title: '¿Está seguro que desea eliminar el módulo?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.onDelete(info.id);
      }
    })
  }
}

