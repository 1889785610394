export class CiiuModel{
    id: any;
    name: string
    code: string

    public constructor() {
        this.id = null;
        this.name = '';
        this.code = '';
    }
}