import { FormBuilder } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileModel } from 'models/entities/profile-model';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent implements OnInit {

  form: any = {};
  profile: FormGroup;
  @Input() public profileInfoUpdate =  new ProfileModel();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('f') updateProfileForm: NgForm;

  constructor(private modalService: NgbModal, private profileForm: FormBuilder) { }

  ngOnInit() {
    this.profile = this.profileForm.group({
      id: this.profileInfoUpdate.id,
      name: this.profileInfoUpdate.name,
      description: this.profileInfoUpdate.description,
    });
  }
  
  passBack() {
    const formValue = this.profile.value;
    this.passEntry.emit(formValue);
  }

  closeWindow() {
    this.modalService.dismissAll();
  }


}
