import {  Component,  OnInit,  ViewChild,  Output,  EventEmitter,  Input} from '@angular/core';
import { NgForm } from '@angular/forms';
import {  NgbActiveModal,  NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { ClientModel } from 'models/entities/client-model';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { Observable, Subject } from 'rxjs';
import {  debounceTime,  distinctUntilChanged,  map,  filter, merge} from 'rxjs/operators';
import { UserModel } from 'models/entities/user-model';
import { CityModel } from 'models/entities/city-model';
import { DepartmentModel } from 'models/entities/department-model';
import { DocumentTypeModel } from 'models/entities/document-type-model';
import { CiiuModel } from 'models/entities/ciiu.model';
import swal from 'sweetalert2';
import { RelationalFileModel } from 'models/utilities/relational-type-model';
import { ModalInformationModel } from 'models/utilities/modal-information-model';

@Component({
  selector: 'app-cru-client',
  templateUrl: './cru-client.component.html',
  styleUrls: ['./cru-client.component.scss']
})
export class CruClientComponent implements OnInit {
  @Input() public sellerModel = new UserModel();
  @Input() public clientModel = new ClientModel();
  @Input() public modelInfoClient = new ModalInformationModel();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('f') createClientForm: NgForm;
  @ViewChild('updateClient') updateClient: NgForm;
  @ViewChild('instanceClient') instanceClient: NgbTypeahead;

  public relationalModel: RelationalFileModel = new RelationalFileModel();

  focusDeparment$ = new Subject<string>();
  clickDeparment$ = new Subject<string>();
  focusCity$ = new Subject<string>();
  clickCity$ = new Subject<string>();
  focusSeller$ = new Subject<string>();
  clickSeller$ = new Subject<string>();
  focusCiiu$ = new Subject<string>();
  clickCiiu$ = new Subject<string>();

  listSellers: Array<UserModel> = new Array();
  listDepartaments: Array<DepartmentModel> = new Array();
  listCities: Array<CityModel> = new Array();
  listClients: Array<ClientModel> = new Array();
  listCiiu: Array<CiiuModel> = new Array();

  listDocumentTypes: Array<DocumentTypeModel> = new Array();

  modelCity = new CityModel();

  sellerIdAux = '';
  documentExists = false;

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.getSellers();
    this.getDepartamnet(1);
    this.getClients();
    this.getCiiu();
    this.getDocumentsTypes();

    if (this.modelInfoClient.isCreate) {
      this.clientModel.documentTypeId.id = '';
      this.clientModel.fatherOrMother = null;
      this.clientModel.gender = '';
      this.clientModel.cityId = null;
      this.clientModel.creatorId = this.tokenStorage.getId();
    } else if (this.modelInfoClient.isView) {

    } else if (this.modelInfoClient.isUpdate) {

    }

  }


  /**
   * Deshabilita los input y select cuanto
   * se esta en modo visualización
   */
  disableForm() {
    const inputs = document.getElementsByTagName('input');
    const select = document.getElementsByTagName('select');

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].disabled = true;
    }

    for (let i = 0; i < select.length; i++) {
      select[i].disabled = true;
    }
  }

  submitForm() {
    const pathCreateClient = 'cliente/crear';
    const date = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    

    this.clientModel.lastUpdateDate = date;
    this.clientModel.companyId = { id: this.tokenStorage.getCompanyId() };
    this.clientModel.lastUpdateId = { id: this.tokenStorage.getId() };

    /**
     * Crear cliente
   
      */
    
    this.getClientsRepeated();

    if (this.documentExists === false) {
      if (!this.modelInfoClient.isUpdate) {
        this.clientModel.createdDate = date;
        this.clientModel.creatorId = { id: this.tokenStorage.getId() };
        const documentAux = new DocumentTypeModel();
        documentAux.id = this.clientModel.documentTypeId.id;
        this.clientModel.documentTypeId = documentAux;

        if ( $("#seller").val() === '' ) {
          this.clientModel.sellerUserId = new UserModel();
          this.clientModel.sellerUserId.id = this.tokenStorage.getId() ;
        }

        if ( $("#ciiu").val() === '' ) {
          this.clientModel.ciiuId = null;
        }
        this.crudService.createModel(pathCreateClient, this.clientModel).subscribe(
            (genericResponse: GenericResponseModel) => {
              if (genericResponse.code === 200) {
                const idClienteAux = genericResponse['genericObject'];
                this.messageService.getInfoMessageCreate();
                this.passEntry.emit({response: 'ok', id : idClienteAux});
              } else {
                this.messageService.getInfoMessageError();
              }
            },
            error => {
              this.messageService.getInfoMessagePersonalized(
                'error',
                'No se pudo crear Cliente',
                'Error'
              );
              console.error('El error es ', JSON.stringify(error.message));
            }
          );
      } else {
        /**
         * Actualizar cliente
         */
        swal({
          title: '¿Está seguro que desea actualizar el Cliente?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí, Actualizar',
          cancelButtonText: 'Cancelar'
        }).then(result => {
          if (result.value) {
            this.clientModel.lastUpdateDate = date;
            this.crudService.createModel(pathCreateClient, this.clientModel).subscribe(
                (genericResponse: GenericResponseModel) => {
                  if (genericResponse.code === 200) {
                    this.messageService.getInfoMessageCreate();
                    this.passEntry.emit({response: 'ok'});
                    this.close();
                  } else {
                    this.messageService.getInfoMessageError();
                  }
                },
                error => {
                  this.messageService.getInfoMessagePersonalized(
                    'error',
                    'No se pudo actualizar el cliente',
                    'Error'
                  );
                  console.error('El error es ', JSON.stringify(error.message));
                }
              );
          }
        });
      }
  }
  }

  getClientsRepeated() {
    this.listClients.forEach(client => {
      if (client.documentNumber === this.clientModel.documentNumber) {
        this.messageService.getInfoMessagePersonalized('warning', 'El usuario ya se encuentre registrado', '');
        this.clientModel.documentNumber = '';
        this.documentExists= true;
      }
      else{
        this.documentExists= false;
      }

    });
  }

  getClients() {
    const pathListCLients = 'cliente/ver-por-empresa?page=' + 0;
    this.listClients = [];
    this.crudService.getModel(pathListCLients).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listClients = genericResponse['answerList'];
          listClients.forEach(client => {
            this.listClients.push(client);
          });
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized('error', 'No se pudo Listar Clientes', 'Error');
        console.error('El error es ', JSON.stringify(error.message));
      }
    );
  }

  getDocumentsTypes() {
    const pathListDocumentTypesUrl = 'tipos-de-documento/ver-por-objetivo?objective=Personal';
    this.listDocumentTypes = new Array();
    this.crudService.getModel(pathListDocumentTypesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listDocumentTypesAux = genericResponse.answerList;
          listDocumentTypesAux.forEach(documentType => {
            this.listDocumentTypes.push(documentType);
          });
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los tipos de documentos del sistema',
            'Problema consultando los tipos de documentos del sistema');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los tipos de documentos del sistema ' + JSON.stringify(error))
      }
    );
  }


  getSellers() {
    const pathListSelle = 'usuarios/listar-vendedores-y-gestores';
    this.tokenStorage.getCompanyId()
    this.listSellers = new Array();
    this.crudService.getModel(pathListSelle).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listSeller = genericResponse['answerList'];
          listSeller.forEach(seller => {
            this.listSellers.push(seller);
          });
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized(
          'error',
          'No se pudo Listar Vendedores',
          'Error'
        );
        console.error('El error es ', JSON.stringify(error.message));
      }
    );
  }

  getDepartamnet(countriId) {
    const pathListDepartament =
      'departamento/departamentos-por-pais?countryId=' + countriId;
    this.listDepartaments = new Array();
    this.crudService.getModel(pathListDepartament).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listDeparta = genericResponse['answerList'];
          listDeparta.forEach(departamnet => {
            this.listDepartaments.push(departamnet);
          });
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized(
          'error',
          'No se pudo Listar Departamento',
          'Error'
        );
        console.error('El error es ', JSON.stringify(error.message));
      }
    );
  }

  getCity() {
    let departmentId: any;
    this.listDepartaments.forEach(departamnet => {
      if (this.clientModel.departmentId.name === departamnet.name) {
        departmentId = departamnet.id;
      }
    });
    if (departmentId) {
      const pathListCities =
        'ciudad/ciudades-por-departamento?departmentId=' + departmentId;
      this.listCities = new Array();
      this.crudService.getModel(pathListCities).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.listCities = genericResponse.answerList;
            if(!this.modelInfoClient.isView){
              this.clientModel.cityId = genericResponse.answerList[0];
            }
          } else {
            this.messageService.getInfoMessageError();
          }
        },
        error => {
          this.messageService.getInfoMessagePersonalized(
            'error',
            'No se pudo Listar Departamento',
            'Error'
          );
          console.error('El error es ', JSON.stringify(error.message));
        }
      );
    }
  }

  getCiiu() {
    const pathListCiiu = 'cliente/ciiu/ver-todos';
    this.listCiiu = new Array();
    this.crudService.getModel(pathListCiiu).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listCiiuAux = genericResponse['answerList'];
          listCiiuAux.forEach(ciiu => {
            this.listCiiu.push(ciiu);
          });
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized(
          'error',
          'No se pudo Listar los CIIU',
          'Error'
        );
        console.error('El error es ', JSON.stringify(error.message));
      }
    );
  }

  close() {
    this.activeModal.close();
  }

  containsValidation(objectToValidate, listToSearch: Array<any>) {
    for (const object of listToSearch) {
      if (objectToValidate !== null) {
        if (object.id === objectToValidate.id) {
          return true;
        }
      }
    }
  }

  allIsRight() {    
    return this.containsValidation(this.clientModel.departmentId, this.listDepartaments) && 
          this.containsValidation(this.clientModel.cityId, this.listCities)
  }


  validSeller() {
    //console.log('vendedor elegido ', this.clientModel.sellerUserId.id);
    
    var flag = false
    if ( this.clientModel.sellerUserId !== null ) {   
      for (const object of this.listSellers) {
        if ( (object.id === this.clientModel.sellerUserId.id) || this.clientModel.sellerUserId.id === null ) {
          flag = true;
        }
      }
    }
    if ( $("#seller").val() === '' ) {
      flag = true;
    }
    return flag;
  }

  validCiiu() {
    var flag = false
    if ( this.clientModel.ciiuId !== null ) {   
      for (const object of this.listCiiu) {
        if ( (object.id === this.clientModel.ciiuId.id) || this.clientModel.ciiuId.id === null ) {
          flag = true;
        }
      }
    }
    if ( $("#ciiu").val() === '' ) {
      flag = true;
    }
    return flag;
  }


  formatter = (x: { name: string }) => x.name;
  formatterSeller = (x: { completeName: string }) => x.completeName;

  searchSeller = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    merge(this.focusSeller$),
    merge(this.clickSeller$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
    map(search => (search === '' ? this.listSellers
      : this.listSellers.filter(seller => seller.completeName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
  );

  searchDepartamnet = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusDeparment$),
      merge(this.clickDeparment$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
      map(search => (search === '' ? this.listDepartaments
        : this.listDepartaments.filter(department => department.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
    );

    searchCities = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusCity$),
      merge(this.clickCity$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
      map(search => (search === '' ? this.listCities
        : this.listCities.filter(city => city.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
    );

    searchSellersearchSeller = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusSeller$),
      merge(this.clickSeller$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
      map(search => (search === '' ? this.listSellers
        : this.listSellers.filter(seller => seller.completeName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
    );


    searchCiiu = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusCiiu$),
      merge(this.clickCiiu$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
      map(search => (search === '' ? this.listCiiu
        : this.listCiiu.filter(seller => (seller.name.toLowerCase().indexOf(search.toLowerCase()) > -1) ||
        (seller.code.toLowerCase().indexOf(search.toLowerCase()) > -1))).slice(0, 10))
    );

}
