import { PdfService } from './../../shared/pdf/pdf.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { CollectionModel } from './../../../models/entities/collection-model';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { InstallmentModel } from 'models/entities/installment-model';
import { PolicyModel } from 'models/entities/policy-model';
import { CompanyModel } from 'models/entities/company-model';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { RelationalFileModel } from 'models/utilities/relational-type-model';
import { Observable, Observer } from 'rxjs';
import { SharedService } from 'app/shared/shared.service';

@Component({
  selector: 'app-pay-report',
  templateUrl: './pay-report.component.html',
  styleUrls: ['./pay-report.component.scss']
})
export class PayReportComponent implements OnInit {

  public relationalFileModel: RelationalFileModel = new RelationalFileModel();
  @Input() installmentModel: InstallmentModel = new InstallmentModel();
  @Input() policyModel: PolicyModel = new PolicyModel();
  justPrint = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() collectionModel: CollectionModel = new CollectionModel();
  companyModel: CompanyModel = new CompanyModel();
  @ViewChild('contentToConvert') contentDiv: ElementRef;
  @Input() isPrinting = false;
  logo: any = '';

  constructor(
    private crudService: CrudServiceService,
    public tokenStorage: TokenStorageService,
    private messageService: InfoMessagesService,
    private tokenStorageService: TokenStorageService,
    private pdfService: PdfService,
    private sharedService: SharedService
  ) {
    this.relationalFileModel.showTitle = false;
    this.relationalFileModel.maxNumberOfFiles = 1
    this.relationalFileModel.relationalId = this.installmentModel.id;
    this.relationalFileModel.relationalType = 'DOCUMENTS_BY_PAYMENT'
    this.relationalFileModel.relationalObjetive = 'Anexo'
    this.relationalFileModel.relationalFileType = 'Archivo';
    this.relationalFileModel.relationalRestrictions = new Array();

    setTimeout(() => {
      this.imageLoad();
    }, 3000);
  }

  ngOnInit() {
    this.collectionModel.installmentId = this.installmentModel;
    this.getCompany();
    if (this.isPrinting) {
      this.getCollection();
    }
  }

  closeModal() {
    this.passEntry.emit(0);
  }

  getCollection() {
    this.crudService.getModel('collection/obtener-por-pago?installmentId=' + this.installmentModel.id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.collectionModel = genericResponse.genericObject;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo obtener el recaudo con exito',
            'Problema obteniendo el recaudo');
        }
      }, (error) => {
        this.messageService.getInfoMessageError();
        console.error('Error, no se pudo obtener el recaudo con exito: ' + JSON.stringify(error))
      }
    )
  }

  onSubmit() {
    this.collectionModel.installmentId = this.installmentModel;
    this.collectionModel.creatorId.id = this.tokenStorageService.getId();
    this.collectionModel.createdDate = new Date();
    this.crudService.createModel('collection/create-or-update', this.collectionModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.updateInstallment();
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo guardar el recaudo con exito',
            'Problema creando el recaudo');
        }
      }, (error) => {
        this.messageService.getInfoMessageError();
        console.error('Error, no se pudo guardar el recaudo con exito: ' + JSON.stringify(error))
      }
    )
  }

  updateInstallment() {
    this.crudService.putModelParams('cuotas/actualizar-cuota', {}, {
      paymentValue: this.collectionModel.paymentValue,
      installmentId: this.installmentModel.id
    }).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.isPrinting = true;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo actualizar la cuota',
            'Problema creando la cuota');
        }
      }, (error) => {
        this.messageService.getInfoMessageError();
        console.error('Error, no se pudo actualizar la cuota con exito: ' + JSON.stringify(error))
      }
    )
  }

  getCompany() {
    const pathCompanyUrl = 'empresa/compania/ver-compania?id=' + this.tokenStorage.getCompanyId();
    this.crudService.getModel(pathCompanyUrl).subscribe(
      data => {
        this.companyModel = data.genericObject;
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar la compañia: ' + error)
      }
    );
  }

  public captureScreen() {
    this.justPrint = true;
    setTimeout(() => {
      const dataToConvert = this.contentDiv.nativeElement;
      this.pdfService.captureScreen(dataToConvert, 295, 'landscape').then(
        () => {
          this.passEntry.emit(1);
        }
      )
    }, 2000);

  }

  imageLoad() {
    console.log(this.sharedService.getLogoUrlCompany())
    this.getBase64ImageFromURL(this.sharedService.getLogoUrlCompany()).subscribe(base64data => {
      // this is the image as dataUrl
      this.logo = 'data:image/jpg;base64,' + base64data;
    });
  }

  getBase64ImageFromURL(url: string) {

    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }


  getBase64Image(img: HTMLImageElement) {
    // We create a HTML canvas object that will create a 2d image

    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    // This will draw image    
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

}
