import { ActivityModel } from './../../../models/entities/activity';
import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { RelationalFileModel } from 'models/utilities/relational-type-model';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { SearchClientModel } from 'models/types/search-client-model';
import { NgbTypeahead, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ManageClientsComponent } from 'app/clients/manage-clients/manage-clients.component';
import { formatDate } from '@angular/common';
import { ModalInfo } from 'models/entities/modal-info';
import { ModalInformationModel } from 'models/utilities/modal-information-model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-activity',
  templateUrl: './create-activity.component.html',
  styleUrls: ['./create-activity.component.scss']
})
export class CreateActivityComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Output() @Input() public activityModel=new ActivityModel();
  @ViewChild('f') activityForm: NgForm;
  @Output() public modelInfoClient: ModalInformationModel = new ModalInformationModel();
  public listTypes: any [];
  public listStates: any [];
  public listUsers: any [];
  public isCreateCLient = false;
  public save = false; clientAux
  public relationalModel: RelationalFileModel = new RelationalFileModel();
  listSeacrhClient: any;
  listClients: any;
  temp: any;
  @ViewChild('instanceClient') instanceClient: NgbTypeahead;
  modelSearchClient: SearchClientModel = new SearchClientModel();
  focusClient$ = new Subject<string>();
  clickClient$ = new Subject<string>();
  focusClientIn$ = new Subject<string>();
  modalInforClientModel = new ModalInfo();
 flagClient=false;
 isModal=false;
 isEdit=false;
 focusUser$ = new Subject<string>();
 clickUser$ = new Subject<string>();
 focusUserIn$ = new Subject<string>();
 @ViewChild('instanceUser') instanceUser: NgbTypeahead;


  constructor(
    private messageCtrl:InfoMessagesService,
    private serviceCtrl:CrudServiceService,
    private tokenServiceCtrl:TokenStorageService,
    private modalService:NgbModal,
    private router: Router,
    
  ) { }

  ngOnInit() {
    this.getTypeActivity();
    this.getStatusActivity();
    this.getUsers();
    this.getClients();
    this.modelInfoClient.isUpdate=true;
  }



  end(){
    if(this.isModal)
    {
     this.passEntry.emit('close');
    }
    else{
      this.router.navigate(['actividades/gestion-actividades']);

    }
  }

  changeClient(e) {
    if (this.isCreateCLient) {
      this.isCreateCLient = false;
    } else {
      this.isCreateCLient = true;
    }
  }

  getUsers() {
    const path = 'usuarios/listar-vendedores-y-gestores';
    this.serviceCtrl.getModel(path).subscribe(
      result=>{
        if(result.code===200)
        {
         this.listUsers=result.answerList;
        }
      },
      error=>{
       this.messageCtrl.getInfoMessageError();
      }
    )
  }

  onSubmit()
  {
  if(this.activityModel.responsibleUserId.id===''){
      this.activityModel.responsibleUserId.id=this.tokenServiceCtrl.getId();
  }
  else{
    if(this.activityModel.relationalEntityId===null )
    {
      this.activityModel.relationalEntityId=this.activityModel.responsibleUserId.id;
      this.activityModel.relationalEntityType='SIN ASIGNAR';
    }
    else{
       if(this.activityModel.relationalEntityType==='')
       {
       
       this.activityModel.relationalEntityId=this.activityModel.relationalEntityId.id;
       this.activityModel.relationalEntityType='CLIENTE';
       }
    }
  }
  if(this.activityModel.expireDate===null){
    this.activityModel.expireDate=new Date();
  }
   this.activityModel.creatorId.id = this.tokenServiceCtrl.getId();
   this.activityModel.lastUpdateId.id = this.tokenServiceCtrl.getId();
   this.activityModel.companyId.id = this.tokenServiceCtrl.getCompanyId();

   const path = 'actividad/crear';
  this.serviceCtrl.createModel(path, this.activityModel).subscribe(
    result => {
      if (result.code === 200) {
        this.relationalModel.relationalId = result.genericObject.id;
        this.relationalModel.relationalType = 'ACTIVITY'
        this.relationalModel.relationalFileType = 'ARCHIVO';
        this.relationalModel.relationalObjetive="adjunto actividad"
        this.relationalModel.relationalRestrictions = new Array();
        this.save = true;
        this.isCreateCLient = false;
        this.messageCtrl.getInfoMessageCreate();
        this.passEntry.emit('ok');
      } else {
        this.messageCtrl.getInfoMessageError();
      }
    },
    error => {
      this.messageCtrl.getInfoMessageError();
    }
  );
  }

  close(){
    this.passEntry.emit('close');
  }

  getTypeActivity() {
  const path = 'empresa/tipo-actividad/listar-tipo-actividad';
    this.serviceCtrl.getModel(path).subscribe(
      result => {
        if(result.code===200)
        {
         this.listTypes=result.answerList        
        }
      },
      error => {
       this.messageCtrl.getInfoMessageDelete();
      }
    )
  }


  getStatusActivity(){
    let path='empresa/estado-actividad/listar-estado-actividad-no-finalizadora';
      this.serviceCtrl.getModel(path).subscribe(
        result=>{
          if(result.code===200)
          {
          
           this.listStates=result.answerList        
          }
        },
        error => {
         this.messageCtrl.getInfoMessageDelete();
        }
      )
    }

  validateSelectType(type) {
    if (type !== 'none') {
      return true;
    } else {
      return false;
    }
  }

  getClients() {
    const pathListClientsUrl = 'cliente/ver-por-empresa';
    this.listClients = new Array();
    this.serviceCtrl.getModel(pathListClientsUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listClientsAux = genericResponse.answerList;
          listClientsAux.forEach(client => {
            this.listClients.push(client);
          });
        } else {
          this.messageCtrl.getInfoMessagePersonalized('warning', 'No se pudieron listar los clientes asociados a su empresa',
            'Problema consultando los clientes asociados a su empresa');
        }
      },
      error => {
        this.messageCtrl.getInfoMessageError();
        console.error('Error al cargar los clientes asociados a su empresa ' + JSON.stringify(error))
      }
    );
  }

  formatterClient = (object: { nameOrBusinessName: string }) => object.nameOrBusinessName;



  searchByNameClient = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    merge(this.focusClientIn$),
    merge(this.clickClient$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
    map(search => (search === '' ? this.listClients
      : this.listClients.filter(client => client.nameOrBusinessName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
  );

  formatterUser = (object: { completeName: string }) => object.completeName



  searchByNameUser = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    merge(this.focusUserIn$),
    merge(this.clickUser$.pipe(filter(() => !this.instanceUser.isPopupOpen()))),
    map(search => (search === '' ? this.listUsers
      : this.listUsers.filter(user => user.completeName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
  );


  lookClient() {
    const modal = this.modalService.open(ManageClientsComponent, {
      windowClass: '',
      size: 'lg',
      backdrop: 'static'
    });

    const array = this.activityModel.relationalEntityId.birthDate.split('+');
    const date = formatDate(new Date(array[0]), 'yyyy-MM-dd', 'en');
    this.activityModel.relationalEntityId.birthDate = date;

    this.modalInforClientModel.isCreate = false;
    this.modalInforClientModel.isUpdate = false;
    this.modalInforClientModel.isView = true;
    modal.componentInstance.modelInfoClient = this.modalInforClientModel;
    modal.componentInstance.clientModel = { ...this.activityModel.relationalEntityId };
    /*modal.componentInstance.passEntry.subscribe(update => {
      if (update === 'ok') {
      }
    });*/
  }

  clientSelect() {
    if (!this.flagClient) {
    this.flagClient = true;
    } else {
      this.flagClient = false;
    }
  }

  createClient() {
    this.flagClient = false;
    const modal = this.modalService.open(ManageClientsComponent, {
      windowClass: '',
      size: 'lg',
      backdrop: 'static'
    });
    this.modalInforClientModel.isCreate = true;
    this.modalInforClientModel.isUpdate = false;
    this.modalInforClientModel.isView = false;
    modal.componentInstance.modelInfoClient = this.modalInforClientModel;
    modal.componentInstance.passEntry.subscribe(retorno => {
      if (retorno === 'ok') {
        this.getClients();
      } else if (retorno === 'close') {
        modal.dismiss();
      
      }
    });
  }

}