import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { CompanyModel } from 'models/entities/company-model';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { FirebaseStorageService } from 'app/shared/firebase/firebase-storage.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ComposeModel } from 'models/utilities/compose-model';
import swal from 'sweetalert2';
import { FileModel } from 'models/entities/file-model';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';

@Component({
  selector: 'app-new-file',
  templateUrl: './new-file.component.html',
  styleUrls: ['./new-file.component.scss']
})
export class NewFileComponent implements OnInit {

  @Input() composeModel: ComposeModel = new ComposeModel();
  @Output() emit: EventEmitter<any> = new EventEmitter();
  @Input() fileModel: FileModel = new FileModel();
  companyModel: CompanyModel = new CompanyModel();
  @Input() restrictions = Array();
  selectedExtension = '';
  selectedFiles: any = undefined;
  stringAccept = '';
  canShowLoading = false;

  constructor(
    private firebaseStorageService: FirebaseStorageService,
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private storageInfoCtrl: TokenStorageService
  ) {
  }

  ngOnInit() {
    this.buildStringExtensions()
    this.getMyCompany();
    this.fileModel.idCreator = this.storageInfoCtrl.getId();
    this.fileModel.createdDate = new Date().toUTCString();
  }

  getMyCompany() {
    const pathCompanyUrl = 'empresa/compania/ver-compania?id=' + this.storageInfoCtrl.getCompanyId();
    this.crudService.getModel(pathCompanyUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.companyModel = genericResponse.genericObject;
          console.log(this.companyModel);
        } else if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se pudo cargar la empresa')
          this.canShowLoading = false;
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar la compañia: ' + error)
      }
    );
  }

  buildStringExtensions() {
    for (const ext of this.restrictions) {
      this.stringAccept = this.stringAccept + '.' + ext + ','
    }
    this.stringAccept = this.stringAccept.substring(0, this.stringAccept.length - 1)
  }

  uploadFile(): Promise<any> {
    return new Promise((resolve, reject) => {
      const realTimeUpload = new Date().getTime()
      if (this.fileModel.relationalType === '') {
        this.fileModel.relationalType = 'FILE'
      }
      if (this.fileModel.fileType === '') {
        this.fileModel.fileType = 'FILE'
      }
      const folderPath = this.storageInfoCtrl.getCompanyId() + '/' + this.fileModel.idCreator + '/'
        + this.fileModel.relationalType + '/' + this.fileModel.relationalId + '/';
      const fileName = this.fileModel.fileName.trim() + '-' + realTimeUpload + '.' + this.selectedExtension;
      this.fileModel.path = folderPath + fileName;
      this.fileModel.fileName = fileName
      this.firebaseStorageService.uploadFile(folderPath, fileName, this.selectedFiles).then(
        (responseFirebase) => {
          this.firebaseStorageService.loadFile(folderPath, fileName).then(
            (dowloadURL) => {
              resolve(dowloadURL)
            }
          ).catch(error => {
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
            reject(error)
          })
        }, (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
          reject(error)
        })
    })
  }

  onSubmit() {
    this.canShowLoading = true;
    this.uploadFile().then((urlResult) => {
      this.fileModel.downloadUrl = urlResult
      this.crudService.createModelWA('archivo-firebase/crear', this.fileModel).subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.messageService.getInfoMessageCreate().then(
              (response) => {
                this.selectFile = undefined;
                this.emit.emit(genericResponse.genericObject);
                this.canShowLoading = false;
              }
            )
          } else if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se pudo crear el archivo')
            this.canShowLoading = false;
          }
        }
      )
    }).catch((error) => {
      console.error(error);
      this.canShowLoading = false;
      this.messageService.getInfoMessageBadInternet();
      this.emit.emit(0);
    })
  }

  closeModal() {
    this.emit.emit(0);
  }

  selectFile(event) {
    console.log(event.target.files.item(0));
    console.log((Number(event.target.files.item(0).size) / 1000 / 1000));
    if ((Number(event.target.files.item(0).size) / 1000 / 1000) <= this.companyModel.maxDocumentSize) {
      if (this.restrictions.length === 0) {
        this.selectedFiles = event.target.files.item(0);
        this.selectedExtension = this.selectedFiles.name.split('.').pop()
      } else {
        this.selectedFiles = event.target.files.item(0);
        if (this.restrictions.includes(this.selectedFiles.name.split('.').pop())) {
          this.selectedExtension = this.selectedFiles.name.split('.').pop()
        } else {
          this.selectedFiles = undefined;
          swal({
            title: 'El tipo de archivo seleccionado no es correcto', type: 'error',
            text: 'El archivo debe tener formato ' + this.restrictions.toString()
          })
        }
      }
    } else {
      this.selectedFiles = undefined;
      swal({
        title: 'El archivo es demasiado pesado', type: 'error',
        text: 'El archivo no puede pesar mas de ' + this.companyModel.maxDocumentSize + 'mb'
      })
    }
  }

}
