import { Component,  OnInit,  ViewChild,  Output,  EventEmitter,  Input } from '@angular/core';
import { PlantillaModel } from './../../../models/utilities/plantilla-models';
import { NgForm } from '@angular/forms';
import { RelationalFileModel } from 'models/utilities/relational-type-model';
import { ModalInfo } from 'models/entities/modal-info';



@Component({
  selector: 'app-crear-plantillas',
  templateUrl: './crear-plantillas.component.html',
  styleUrls: ['./crear-plantillas.component.scss']
})
export class CrearPlantillasComponent implements OnInit {


  @Input() public plantillaModel = new PlantillaModel();
  @ViewChild('formPlantilla') createClientForm: NgForm;
  @Input() public modalInfo: ModalInfo = new ModalInfo();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  relationalModel: RelationalFileModel = new RelationalFileModel();

  constructor() { }

  ngOnInit() {
    this.relationalModel.relationalType = 'DOCUMENTOS_PLANTILLAS_FILE';
    this.relationalModel.relationalFileType = 'ARCHIVO';
    this.relationalModel.relationalObjetive = 'Plantillas';
    this.relationalModel.relationalRestrictions = new Array();
  }

  submitForm() {
  
  }
  

}
