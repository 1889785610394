import { JoinModel } from 'models/types/join-model';
import { DocumentTypeModel } from './document-type-model';
import { PlansModel } from './plans-model';
import { CompanyModel } from './company-model';
export class UserModel {
    id: string;
    completeName: string;
    username: string;
    email: string;
    password: string;
    documentNumber: string;
    birthday: Date;
    address: string;
    phone: string;
    cellphone: string;
    role: string;
    gender: string;
    documentTypeId: DocumentTypeModel;
    longitude: string;
    latitude: string;
    activatedPlanId: PlansModel;
    companyId: CompanyModel;
    creatorId: UserModel;
    createdDate: string;
    lastUpdateDate: string;
    token: string;
    isLegalRepresent: boolean;
    reminderBirthday: string;


    constructor() {
        this.id = null;
        this.completeName = '';
        this.username = '';
        this.email = '';
        this.password = '';
        this.documentNumber = '';
        this.birthday = new Date();
        this.address = '';
        this.phone = '';
        this.cellphone = '';
        this.role = '';
        this.gender = '';
        this.documentTypeId = new DocumentTypeModel();
        this.longitude = '';
        this.latitude = '';
        this.activatedPlanId = null;
        this.companyId = null;
        this.creatorId = null;
        this.createdDate = new Date().toUTCString();
        this.lastUpdateDate = new Date().toUTCString();
        this.token = '';
        this.isLegalRepresent = true;
        this.companyId=new CompanyModel();
        this.reminderBirthday = 'true';
    }

}
