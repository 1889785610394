import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListarPlantillasComponent } from './listar-plantillas/listar-plantillas.component';
import { PlantillasRoutingModule } from './plantillas-routing.module';
import { CrearPlantillasComponent } from './crear-plantillas/crear-plantillas.component';
import { RelationalFileModel } from 'models/utilities/relational-type-model';

@NgModule({
  declarations: [ListarPlantillasComponent, CrearPlantillasComponent],
  imports: [
    CommonModule,PlantillasRoutingModule, FormsModule,ReactiveFormsModule
  ],exports: [ListarPlantillasComponent,CrearPlantillasComponent]
})
export class PlantillasModule { }
