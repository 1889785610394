export class SearchCommissionReportModel {
    initialDate: any;
    finalDate: any;
    classOfInsuranceId: any;
    insurerId: any;
    sellerId: any;
    policy: string;

    public constructor() {
        this.initialDate = '';
        this.finalDate = '';
        this.classOfInsuranceId = {id: null};
        this.insurerId = {id: null}; 
        this.sellerId = {id: null};
        this.policy = '';
    }
}
