export class FileModel {
    id: string;
    fileName: string;
    downloadUrl: string;
    path: string;
    relationalId: string;
    relationalType: string;
    fileType: string;
    idCreator: string;
    createdDate: string;

    public constructor() {
        this.id = '';
        this.fileName = '';
        this.downloadUrl = '';
        this.path = '';
        this.relationalId = '';
        this.relationalType = '';
        this.fileType = '';
        this.idCreator = '';
        this.createdDate = '';
    }
}
