export class ContractualCivilLiabilityShipmentRiskModel {
    id: string;
    policyId: any;
    clientId: any;
    state: string;
    boatName: string;
    patentNumber: string;
    passengerCapacity: string;
    type: string;
    service: string;
    owner: string;
    di: string;
    operatingZone: string;
    validSince: string;
    validUntil: string;
    classOfInsuranceId: any;
    creatorId: any;
    lastUpdateId: any;
    createdDate: string;
    lastUpdateDate: string;

    public constructor() {
        this.id = null;
        this.policyId = '';
        this.clientId = '';
        this.state = '';
        this.boatName = '',
        this.patentNumber = '';
        this.passengerCapacity = '';
        this.type = '';
        this.service = '';
        this.owner = '';
        this.di = '';
        this.operatingZone = '';
        this.validSince = '';
        this.validUntil = '';
        this.classOfInsuranceId = '';
        this.creatorId = '';
        this.lastUpdateId = '';
        this.createdDate = '';
        this.lastUpdateDate = '';
    }
}
