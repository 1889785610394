import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { PolicyModel } from 'models/entities/policy-model';
import { CrudServiceService } from '../../shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from '../../shared/messages/info-messages.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { HealthRiskModel } from 'models/entities/health-risk.model';
import { VehicleRiskModel } from 'models/entities/vehicle-risk.model';
import { CoownershipRiskModel } from 'models/entities/coownership-risk.model';
import { RcProfessionalRiskModel } from 'models/entities/rcprofessional-risk.model';
import { PymeRiskModel } from 'models/entities/pyme-risk.model';
import { LifeRiskModel } from 'models/entities/life-risk.model';
import { PersonalAccidentRiskModel } from 'models/entities/personal-accident-risk.model';
import { FullfillmentRiskModel } from 'models/entities/fullfillment-risk.model';
import { HomeRiskModel } from 'models/entities/home-risk.model';
import { CapitalizationRiskModel } from 'models/entities/capitalization-risk.model';
import { SOATRiskModel } from 'models/entities/soat-risk.model';
import { RCEVehicleRiskModel } from 'models/entities/rce-vehicle-risk.model';
import { RCCVehicleRiskModel } from 'models/entities/rcc-vehicle-risk.model';
import { MachineryBreakdownRiskModel } from 'models/entities/machinery-breakdown-risk.model';
import { MultiRiskModel } from 'models/entities/multi-risk.model';
import { RcCyberRiskModel } from 'models/entities/rc-cyber-risk.model';
import { MaritimeTransportRiskModel } from 'models/entities/maritime-transport-risk.model';
import { HealthRiskListComponent } from '../health-risk-list/health-risk-list.component';
import { VehicleRiskListComponent } from '../vehicle-risk-list/vehicle-risk-list.component';
import { AllRiskConstructionRiskModel } from 'models/entities/all-risk-construction-risk-model';
import { CivilLiabilityHydrocarbonsShipmentRiskModel } from 'models/entities/civil-liability-hydrocarbons-shipment-risk.model';
import { NonContractualCivilLiabilityShipmentRiskModel } from 'models/entities/non-contractual-liability-shipment-risk.model';
import { ContractualCivilLiabilityShipmentRiskModel } from 'models/entities/contractual-civil-liability-shipment-risk.model';
import { NonContractualCivilLiabilityPassengerRiskModel } from 'models/entities/non-contractual-liability-passenger-risk.model';
import { ContractualCivilLiabilityPassengerRiskModel } from 'models/entities/contractual-civil-liability-passenger-risk.model';
import { LifeDebtorsRiskModel } from 'models/entities/life-debtors-risk.model';
import { ComplementaryPlanRiskModel } from 'models/entities/complementary-plan-risk.model';
import { RCFullfillmentRiskModel } from 'models/entities/rc-fullfillment-risk.model';
import { AviationRiskModel } from 'models/entities/aviation-risk.model';
import { FuneralRiskModel } from 'models/entities/funeral-risk.model';
import { AgriculturalCropsRiskModel } from 'models/entities/agricultural-crops-risk-model';
import { ARLRiskModel } from 'models/entities/arl-risk-model';
import { LeaseRiskModel } from 'models/entities/lease-risk-model';
import { HullBoatRiskModel } from 'models/entities/hull-boat-risk-model';
import { DirectorsAdministratorsRiskModel } from 'models/entities/directors-administrators-risk-model';
import { InfidelityFinancialRiskModel } from 'models/entities/infidelity-financial-risk-model';
import { MachineryEquipmentRiskModel } from 'models/entities/machinery-equipment-risk-model';
import { RCClinicHospitalsRiskModel } from 'models/entities/rc-clinic-hospitals-risk-model';
import { RCParkingWorkshopsRiskModel } from 'models/entities/rc-parking-workshops-risk-model';
import { NonContractualCivilLiabilityRiskModel } from 'models/entities/non-civil-liability-risk-model';
import { TransportValuablesRiskModel } from 'models/entities/transport-valuables-risk-model';
import { TransportMerchandiseRiskModel } from 'models/entities/transport-merchandise-risk-model';
import { GlobalCommercialManagementRiskModel } from 'models/entities/global-commercial-management-risk-model';

@Component({
  selector: 'app-admin-cru-list-risks',
  templateUrl: './admin-cru-list-risks.component.html',
  styleUrls: ['./admin-cru-list-risks.component.scss']
})
export class AdminCruListRisksComponent implements OnInit {
  activityRecord = new Array();

  @Input() policyModel = new PolicyModel();

  @Input() healthRiskModel = new HealthRiskModel();
  @Input() vehicleRiskModel = new VehicleRiskModel();
  @Input() coownershipRiskModel = new CoownershipRiskModel();
  @Input() rcprofessionalRiskModel = new RcProfessionalRiskModel();
  @Input() pymeRiskModel = new PymeRiskModel();
  @Input() lifeRiskModel = new LifeRiskModel();
  @Input() personalAccidentRiskModel = new PersonalAccidentRiskModel();
  @Input() fulfillmentRiskModel = new FullfillmentRiskModel();
  @Input() homeRiskModel = new HomeRiskModel();
  @Input() capitalizationRiskModel = new CapitalizationRiskModel();
  @Input() soatRiskModel = new SOATRiskModel();
  @Input() rceVehicleRiskModel = new RCEVehicleRiskModel();
  @Input() rccVehicleRiskModel = new RCCVehicleRiskModel();
  @Input() machineryBreakdownRiskModel = new MachineryBreakdownRiskModel();
  @Input() multiRiskModel = new MultiRiskModel();
  @Input() rcCyberRiskModel = new RcCyberRiskModel();
  @Input() maritimeTransportRiskModel = new MaritimeTransportRiskModel();
  @Input() allRiskConstructionRiskModel = new AllRiskConstructionRiskModel();
  @Input() civilLiabilityHydrocarbonsShipmentRiskModel = new CivilLiabilityHydrocarbonsShipmentRiskModel();
  @Input() nonContractualCivilLiabilityShipmentRiskModel = new NonContractualCivilLiabilityShipmentRiskModel();
  @Input() contractualCivilLiabilityShipmentRiskModel = new ContractualCivilLiabilityShipmentRiskModel();
  @Input() nonContractualCivilLiabilityPassengerRiskModel = new NonContractualCivilLiabilityPassengerRiskModel();
  @Input() contractualCivilLiabilityPassengerRiskModel = new ContractualCivilLiabilityPassengerRiskModel();
  @Input() lifeDebtorsRiskModel = new LifeDebtorsRiskModel();
  @Input() complementaryPlanRiskModel = new HealthRiskModel();
  @Input() rcfulfillmentRiskModel = new RCFullfillmentRiskModel();
  @Input() aviationRiskModel = new AviationRiskModel();
  @Input() funeralRiskModel = new FuneralRiskModel();
  @Input() agriculturalCropsRiskModel = new AgriculturalCropsRiskModel();
  @Input() arlRiskModel = new ARLRiskModel();
  @Input() leaseRiskModel = new LeaseRiskModel();
  @Input() hullBoatRiskModel = new HullBoatRiskModel();
  @Input() directorsAdministratorsRiskModel = new DirectorsAdministratorsRiskModel();
  @Input() infidelityFinancialRiskModel = new InfidelityFinancialRiskModel();
  @Input() machineryEquipmentRiskModel = new MachineryEquipmentRiskModel();
  @Input() rcClinicHospitalsRiskModel = new RCClinicHospitalsRiskModel();
  @Input() rcParkingWorkshopsRiskModel = new RCParkingWorkshopsRiskModel();
  @Input() nonContractualCivilLiabilityRiskModel = new NonContractualCivilLiabilityRiskModel();
  @Input() transportValuablesRiskModel = new TransportValuablesRiskModel();
  @Input() transportMerchandiseRiskModel = new TransportMerchandiseRiskModel();
  @Input() globalCommercialManagementRiskModel = new GlobalCommercialManagementRiskModel();

  @ViewChild(HealthRiskListComponent) componentHealthRiskist;


  @Output() emitterHealth: EventEmitter<any> = new EventEmitter();
  @Output() emitterVehicle: EventEmitter<any> = new EventEmitter();
  @Output() emitterCoownership: EventEmitter<any> = new EventEmitter();
  @Output() emitterRcProfessional: EventEmitter<any> = new EventEmitter();
  @Output() emitterPyme: EventEmitter<any> = new EventEmitter();
  @Output() emitterLife: EventEmitter<any> = new EventEmitter();
  @Output() emitterPersonalAccident: EventEmitter<any> = new EventEmitter();
  @Output() emitterFulfillment: EventEmitter<any> = new EventEmitter();
  @Output() emitterHome: EventEmitter<any> = new EventEmitter();
  @Output() emitterCapitalization: EventEmitter<any> = new EventEmitter();
  @Output() emitterSOAT: EventEmitter<any> = new EventEmitter();
  @Output() emitterRCEVehicle: EventEmitter<any> = new EventEmitter();
  @Output() emitterRCCVehicle: EventEmitter<any> = new EventEmitter();
  @Output() emitterMachineryBreakdown: EventEmitter<any> = new EventEmitter();
  @Output() emitterMulti: EventEmitter<any> = new EventEmitter();
  @Output() emitterRcCyber: EventEmitter<any> = new EventEmitter();
  @Output() emitterMaritimeTransport: EventEmitter<any> = new EventEmitter();
  @Output() emitterAllRiskConstruction: EventEmitter<any> = new EventEmitter();
  @Output() emitterCivilLiabilityHydrocarbonsShipment: EventEmitter<any> = new EventEmitter();
  @Output() emitterNonContractualCivilLiabilityShipment: EventEmitter<any> = new EventEmitter();
  @Output() emitterNonContractualCivilLiabilityPassenger: EventEmitter<any> = new EventEmitter();
  @Output() emitterContractualCivilLiabilityPassenger: EventEmitter<any> = new EventEmitter();
  @Output() emitterLifeDebtors: EventEmitter<any> = new EventEmitter();
  @Output() emitterComplementaryPlan: EventEmitter<any> = new EventEmitter();
  @Output() emitterRCFulfillment: EventEmitter<any> = new EventEmitter();
  @Output() emitterAviation: EventEmitter<any> = new EventEmitter();
  @Output() emitterFuneral: EventEmitter<any> = new EventEmitter();
  @Output() emitterAgriculturalCrops: EventEmitter<any> = new EventEmitter();
  @Output() emitterARL: EventEmitter<any> = new EventEmitter();
  @Output() emitterLease: EventEmitter<any> = new EventEmitter();
  @Output() emitterHullBoat: EventEmitter<any> = new EventEmitter();
  @Output() emitterDirectorsAdministrators: EventEmitter<any> = new EventEmitter();
  @Output() emitterInfidelityFinancial: EventEmitter<any> = new EventEmitter();
  @Output() emitterMachineryEquipment: EventEmitter<any> = new EventEmitter();
  @Output() emitterRCClinicHospitals: EventEmitter<any> = new EventEmitter();
  @Output() emitterRCParkingWorkshops: EventEmitter<any> = new EventEmitter();
  @Output() emitterNonContractualCivilLiability: EventEmitter<any> = new EventEmitter();
  @Output() emitterTransportValuables: EventEmitter<any> = new EventEmitter();
  @Output() emitterTransportMerchandise: EventEmitter<any> = new EventEmitter();
  @Output() emitterGlobalCommercialManagement: EventEmitter<any> = new EventEmitter();

  @Output() changesEmmiter: EventEmitter<any> = new EventEmitter();

  @Input() flagSuccessfulHealthUpdate: any;
  @Input() flagSuccessfulVehicleUpdate: any;
  @Input() flagSuccessfulCoownershipUpdate: any;
  @Input() flagSuccessfulRcProfessionalUpdate: any;
  @Input() flagSuccessfulPymeUpdate: any;
  @Input() flagSuccessfulLifeUpdate: any;
  @Input() flagSuccessfulPersonalAccidentUpdate: any;
  @Input() flagSuccessfulFulfillmentUpdate: any;
  @Input() flagSuccessfulHomeUpdate: any;
  @Input() flagSuccessfulCapitalizationUpdate: any;
  @Input() flagSuccessfulSOATUpdate: any;
  @Input() flagSuccessfulRCEVehicleUpdate: any;
  @Input() flagSuccessfulRCCVehicleUpdate: any;
  @Input() flagSuccessfulMachineryBreakdownUpdate: any;
  @Input() flagSuccessfulMultiUpdate: any;
  @Input() flagSuccessfulRcCyberUpdate: any;
  @Input() flagSuccessfulMaritimeTransportUpdate: any;
  @Input() flagSuccessfulAllRiskConstructionUpdate: any;
  @Input() flagSuccessfulCivilLiabilityHydrocarbonShipmentUpdate: any;
  @Input() flagSuccessfulNonContractualCivilLiabilityShipmentUpdate: any;
  @Input() flagSuccessfulContractualCivilLiabilityShipmentUpdate: any;
  @Input() flagSuccessfulNonContractualCivilLiabilityPassengerUpdate: any;
  @Input() flagSuccessfulContractualCivilLiabilityPassengerUpdate: any;
  @Input() flagSuccessfulLifeDebtorsUpdate: any;
  @Input() flagSuccessfulComplementaryPlanUpdate: any;
  @Input() flagSuccessfulRCFulfillmentUpdate: any;
  @Input() flagSuccessfulAviationUpdate: any;
  @Input() flagSuccessfulFuneralUpdate: any;
  @Input() flagSuccessfulAgriculturalCropsUpdate: any;
  @Input() flagSuccessfulARLUpdate: any;
  @Input() flagSuccessfulLeaseUpdate: any;
  @Input() flagSuccessfulHullBoatUpdate: any;
  @Input() flagSuccessfulDirectorsAdministratorsUpdate: any;
  @Input() flagSuccessfulInfidelityFinancialUpdate: any;
  @Input() flagSuccessfulMachineryEquipmentUpdate: any;
  @Input() flagSuccessfulRCClinicHospitalsUpdate: any;
  @Input() flagSuccessfulRCParkingWorkshopsUpdate: any;
  @Input() flagSuccessfulNonContractualCivilLiabilityUpdate: any;
  @Input() flagSuccessfulTransportValuablesUpdate: any;
  @Input() flagSuccessfulTransportMerchandiseUpdate: any;
  @Input() flagSuccessfulGlobalCommercialManagementUpdate: any;

  @ViewChild(HealthRiskListComponent) healthRiskLisComponent: HealthRiskListComponent;
  @ViewChild(VehicleRiskListComponent) vehicleRiskLisComponent: VehicleRiskListComponent;

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService
  ) { }

  ngOnInit() {
    // this.activityRecord.push('No hubo cambios');
  }

  checkActivityRecord() {
    this.changesEmmiter.emit(this.activityRecord);
  }

  configRiskWithPolicy() {
    switch (this.policyModel.classOfInsuranceId.id) {
      case 1: {
        this.healthRiskLisComponent.policyId = this.policyModel.classOfInsuranceId.id;
        this.healthRiskLisComponent.getHealthRisks();
        break;
      }
      case 2: {
        this.vehicleRiskLisComponent.policyId = this.policyModel.classOfInsuranceId.id;
        this.vehicleRiskLisComponent.getVehicleRisks();
        break;
      }
    }
  }

  configActivityRecord(activity: any) {
    this.activityRecord.push(activity);
    this.checkActivityRecord();
  }

  configHealth(health: HealthRiskModel) {
    this.healthRiskModel = health;
  }
  successfulHealthUpdate(flag: any) {
    this.flagSuccessfulHealthUpdate = flag;
  }

  configVehicle(vehicle: VehicleRiskModel) {
    this.vehicleRiskModel = vehicle;
  }
  successfulVehicleUpdate(flag: any) {
    this.flagSuccessfulVehicleUpdate = flag;
  }

  configCoownership(coownership: CoownershipRiskModel) {
    this.coownershipRiskModel = coownership;
  }
  successfulCoownershipUpdate(flag: any) {
    this.flagSuccessfulCoownershipUpdate = flag;
  }

  configRcProfessional(rcprofessional: RcProfessionalRiskModel) {
    this.rcprofessionalRiskModel = rcprofessional;
  }
  successfulRcProfessionalUpdate(flag: any) {
    this.flagSuccessfulRcProfessionalUpdate = flag;
  }

  configPyme(pyme: PymeRiskModel) {
    this.pymeRiskModel = pyme;
  }
  successfulPymeUpdate(flag: any) {
    this.flagSuccessfulPymeUpdate = flag;
  }

  configLife(life: LifeRiskModel) {
    this.lifeRiskModel = life;
  }
  successfulLifeUpdate(flag: any) {
    this.flagSuccessfulLifeUpdate = flag;
  }

  configPersonalAccident(personalAccident: PersonalAccidentRiskModel) {
    this.personalAccidentRiskModel = personalAccident;
  }
  successfulPersonalAccidentUpdate(flag: any) {
    this.flagSuccessfulPersonalAccidentUpdate = flag;
  }

  configFulfillment(fullfillment: FullfillmentRiskModel) {
    this.fulfillmentRiskModel = fullfillment;
  }
  successfulFulfillmentUpdate(flag: any) {
    this.flagSuccessfulFulfillmentUpdate = flag;
  }

  configHome(home: HomeRiskModel) {
    this.homeRiskModel = home;
  }
  successfulHomeUpdate(flag: any) {
    this.flagSuccessfulHomeUpdate = flag;
  }

  configCapitalization(capitalization: CapitalizationRiskModel) {
    this.capitalizationRiskModel = capitalization;
  }
  successfulCapitalizationUpdate(flag: any) {
    this.flagSuccessfulCapitalizationUpdate = flag;
  }

  configSOAT(soat: SOATRiskModel) {
    this.soatRiskModel = soat;
  }
  successfulSOATUpdate(flag: any) {
    this.flagSuccessfulSOATUpdate = flag;
  }

  configRCEVehicle(rceVehicle: RCEVehicleRiskModel) {
    this.rceVehicleRiskModel = rceVehicle
  }
  successfulRCEVehicleUpdate(flag: any) {
    this.flagSuccessfulRCEVehicleUpdate = flag;
  }

  configRCCVehicle(rccVehicle: RCCVehicleRiskModel) {
    this.rccVehicleRiskModel = rccVehicle
  }
  successfulRCCVehicleUpdate(flag: any) {
    this.flagSuccessfulRCCVehicleUpdate = flag;
  }

  configMachineryBreakdown(machineryBreakdown: MachineryBreakdownRiskModel) {
    this.machineryBreakdownRiskModel = machineryBreakdown
  }
  successfulMachineryBreakdownUpdate(flag: any) {
    this.flagSuccessfulMachineryBreakdownUpdate = flag;
  }

  configMulti(multi: MultiRiskModel) {
    this.multiRiskModel = multi
  }
  successfulMultiUpdate(flag: any) {
    this.flagSuccessfulMultiUpdate = flag;
  }

  configRcCyber(rcCyber: RcCyberRiskModel) {
    this.rcCyberRiskModel = rcCyber
  }
  successfulRcCyberUpdate(flag: any) {
    this.flagSuccessfulRcCyberUpdate = flag;
  }

  configMaritimeTransport(maritimeTransport: MaritimeTransportRiskModel) {
    this.maritimeTransportRiskModel = maritimeTransport
  }
  successfulMaritimeTransportUpdate(flag: any) {
    this.flagSuccessfulMaritimeTransportUpdate = flag;
  }

  configAllRiskConstruction(allRiskConstruction: AllRiskConstructionRiskModel) {
    this.allRiskConstructionRiskModel = allRiskConstruction
  }
  successfulAllRiskConstructionUpdate(flag: any) {
    this.flagSuccessfulAllRiskConstructionUpdate = flag;
  }
  configCivilLiabilityHydrocarbonShipment(civilLiabilityHydrocarbonsShipment: CivilLiabilityHydrocarbonsShipmentRiskModel) {
    this.civilLiabilityHydrocarbonsShipmentRiskModel = civilLiabilityHydrocarbonsShipment
  }
  successfulCivilLiabilityHydrocarbonShipmentUpdate(flag: any) {
    this.flagSuccessfulCivilLiabilityHydrocarbonShipmentUpdate = flag;
  }

  configNonContractualCivilLiabilityShipment(conContractualCivilLiabilityShipment: NonContractualCivilLiabilityShipmentRiskModel) {
    this.nonContractualCivilLiabilityShipmentRiskModel = conContractualCivilLiabilityShipment
  }
  successfulNonContractualCivilLiabilityShipmentUpdate(flag: any) {
    this.flagSuccessfulNonContractualCivilLiabilityShipmentUpdate = flag;
  }

  configContractualCivilLiabilityShipment(contractualCivilLiabilityShipment: ContractualCivilLiabilityShipmentRiskModel) {
    this.contractualCivilLiabilityShipmentRiskModel = contractualCivilLiabilityShipment
  }
  successfulContractualCivilLiabilityShipmentUpdate(flag: any) {
    this.flagSuccessfulContractualCivilLiabilityShipmentUpdate = flag;
  }

  configContractualCivilLiabilityPassenger(ContractualCivilLiabilityPassenger: ContractualCivilLiabilityPassengerRiskModel) {
    this.contractualCivilLiabilityPassengerRiskModel = ContractualCivilLiabilityPassenger
  }
  successfulContractualCivilLiabilityPassengerUpdate(flag: any) {
    this.flagSuccessfulContractualCivilLiabilityPassengerUpdate = flag;
  }

  configNonContractualCivilLiabilityPassenger(nonContractualCivilLiabilityPassenger: NonContractualCivilLiabilityPassengerRiskModel) {
    this.nonContractualCivilLiabilityPassengerRiskModel = nonContractualCivilLiabilityPassenger
  }
  successfulNonContractualCivilLiabilityPassengerUpdate(flag: any) {
    this.flagSuccessfulNonContractualCivilLiabilityPassengerUpdate = flag;
  }

  configLifeDebtors(lifeDebtors: LifeDebtorsRiskModel) {
    this.lifeDebtorsRiskModel = lifeDebtors;
  }
  successfulLifeDebtorsUpdate(flag: any) {
    this.flagSuccessfulLifeDebtorsUpdate = flag;
  }

  configComplementaryPlan(complementaryPlan: ComplementaryPlanRiskModel) {
    this.complementaryPlanRiskModel = complementaryPlan;
  }
  successfulComplementaryPlanUpdate(flag: any) {
    this.flagSuccessfulComplementaryPlanUpdate = flag;
  }

  configRCFulfillment(rcfullfillment: RCFullfillmentRiskModel) {
    this.rcfulfillmentRiskModel = rcfullfillment;
  }
  successfulRCFulfillmentUpdate(flag: any) {
    this.flagSuccessfulRCFulfillmentUpdate = flag;
  }

  configAviation(aviation: AviationRiskModel) {
    this.aviationRiskModel = aviation;
  }
  successfulAviationUpdate(flag: any) {
    this.flagSuccessfulAviationUpdate = flag;
  }

  configFuneral(funeral: FuneralRiskModel) {
    this.funeralRiskModel = funeral;
  }
  successfulFuneralUpdate(flag: any) {
    this.flagSuccessfulFuneralUpdate = flag;
  }

  configAgriculturalCrops(agriculturalCrops: AgriculturalCropsRiskModel) {
    this.agriculturalCropsRiskModel = agriculturalCrops;
  }
  successfulAgriculturalCropsUpdate(flag: any) {
    this.flagSuccessfulAgriculturalCropsUpdate = flag;
  }

  configARL(arl: ARLRiskModel) {
    this.arlRiskModel = arl;
  }
  successfulARLUpdate(flag: any) {
    this.flagSuccessfulARLUpdate = flag;
  }

  configLease(lease: LeaseRiskModel) {
    this.leaseRiskModel = lease;
  }
  successfulLeaseUpdate(flag: any) {
    this.flagSuccessfulLeaseUpdate = flag;
  }

  configHullBoat(hullBoat: HullBoatRiskModel) {
    this.hullBoatRiskModel = hullBoat;
  }
  successfulHullBoatUpdate(flag: any) {
    this.flagSuccessfulHullBoatUpdate = flag;
  }

  configDirectorsAdministrators(directorsAdministrators: DirectorsAdministratorsRiskModel) {
    this.directorsAdministratorsRiskModel = directorsAdministrators;
  }
  successfulDirectorsAdministratorsUpdate(flag: any) {
    this.flagSuccessfulDirectorsAdministratorsUpdate = flag;
  }

  configInfidelityFinancial(infidelityFinancial: InfidelityFinancialRiskModel) {
    this.infidelityFinancialRiskModel = infidelityFinancial;
  }
  successfulInfidelityFinancialUpdate(flag: any) {
    this.flagSuccessfulInfidelityFinancialUpdate = flag;
  }

  configMachineryEquipment(machineryEquipment: MachineryEquipmentRiskModel) {
    this.machineryEquipmentRiskModel = machineryEquipment;
  }
  successfulMachineryEquipmentUpdate(flag: any) {
    this.flagSuccessfulMachineryEquipmentUpdate = flag;
  }

  configRCClinicHospitals(rcClinicHospitals: RCClinicHospitalsRiskModel) {
    this.rcClinicHospitalsRiskModel = rcClinicHospitals;
  }
  successfulRCClinicHospitalsUpdate(flag: any) {
    this.flagSuccessfulRCClinicHospitalsUpdate = flag;
  }

  configRCParkingWorkshops(rcParkingWorkshops: RCParkingWorkshopsRiskModel) {
    this.rcParkingWorkshopsRiskModel = rcParkingWorkshops;
  }
  successfulRCParkingWorkshopsUpdate(flag: any) {
    this.flagSuccessfulRCParkingWorkshopsUpdate = flag;
  }

  configNonContractualCivilLiability(nonContractualCivilLiability: NonContractualCivilLiabilityRiskModel) {
    this.nonContractualCivilLiabilityRiskModel = nonContractualCivilLiability;
  }
  successfulNonContractualCivilLiabilityUpdate(flag: any) {
    this.flagSuccessfulNonContractualCivilLiabilityUpdate = flag;
  }

  configTransportValuables(transportValuables: TransportValuablesRiskModel) {
    this.transportValuablesRiskModel = transportValuables;
  }
  successfulTransportValuablesUpdate(flag: any) {
    this.flagSuccessfulTransportValuablesUpdate = flag;
  }

  configTransportMerchandise(transportMerchandise: TransportMerchandiseRiskModel) {
    this.transportMerchandiseRiskModel = transportMerchandise;
  }
  successfulTransportMerchandiseUpdate(flag: any) {
    this.flagSuccessfulTransportMerchandiseUpdate = flag;
  }

  configGlobalCommercialManagement(globalCommercialManagement: GlobalCommercialManagementRiskModel) {
    this.globalCommercialManagementRiskModel = globalCommercialManagement;
  }
  successfulGlobalCommercialManagementUpdate(flag: any) {
    this.flagSuccessfulGlobalCommercialManagementUpdate = flag;
  }
}
