import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';
import { PayReportComponent } from './pay-report/pay-report.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RemissionComponent } from './remission/remission.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'reporte-pago',
        component: PayReportComponent,
        data: {
          title: '',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_MANAGER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'remision',
        component: RemissionComponent,
        data: {
          title: '',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_MANAGER']
        },
        canActivate: [RoleAuthGuard]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrintingRoutingModule { }
