export class RCFullfillmentRiskModel {
    id: string;
    policyId: any;
    clientId: any;
    state: string;
    contractValue: string;
    contractObject: string;
    contractPeriod: string;
    contractor: string;
    occupation: string;
    insuredValue: string;
    validSince: string;
    validUntil: string;
    classOfInsuranceId: any;
    creatorId: any;
    lastUpdateId: any;
    createdDate: string;
    lastUpdateDate: string;

    public constructor() {
        this.id = null;
        this.policyId = '';
        this.clientId = '';
        this.state = '';
        this.contractValue = '';
        this.contractObject = '';
        this.contractPeriod = '';
        this.contractor = '';
        this.occupation = '';
        this.insuredValue = '';
        this.validSince = '';
        this.validUntil = '';
        this.classOfInsuranceId = '';
        this.creatorId = '';
        this.lastUpdateId = '';
        this.createdDate = '';
        this.lastUpdateDate = '';
    }
}
