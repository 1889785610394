import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { BeneficiaryModel } from 'models/entities/beneficiary-model';
import { DocumentTypeModel } from 'models/entities/document-type-model';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { ModalInformationModel } from 'models/utilities/modal-information-model';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { NgForm } from '@angular/forms';
import { UserModel } from 'models/entities/user-model';

@Component({
  selector: 'app-create-beneficiary',
  templateUrl: './create-beneficiary.component.html',
  styleUrls: ['./create-beneficiary.component.scss']
})
export class CreateBeneficiaryComponent implements OnInit {

  listDocumentTypes: Array<DocumentTypeModel> = new Array();
  @ViewChild('formBeneficiary') beneficiaryForm: NgForm;
  @Input() public beneficiaryModel: BeneficiaryModel = new BeneficiaryModel();
  @Input() public modalInformation: ModalInformationModel = new ModalInformationModel();
  @Input() public maxPercent: any = 100;
  @Output() public passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorageService: TokenStorageService
  ) { }

  ngOnInit() {
    this.getDocumentsTypes();
  }

  onSubmit() {
    if (this.modalInformation.isCreate) {
      this.beneficiaryModel.createdDate = new Date().toUTCString();
      this.beneficiaryModel.creatorId.id = this.tokenStorageService.getId();
    }
    if (this.modalInformation.isUpdate) {
      this.beneficiaryModel.lastUpdateDate = new Date().toUTCString();
      this.beneficiaryModel.lastUpdateId = new UserModel();
      this.beneficiaryModel.lastUpdateId.id = this.tokenStorageService.getId();
    }
    this.passEntry.emit(this.beneficiaryModel);
  }

  closeModal() {
    this.passEntry.emit(null);
  }

  getDocumentsTypes() {
    const pathListDOcumentTypesUrl = 'tipos-de-documento/ver-por-objetivo?objective=Personal';
    this.listDocumentTypes = new Array();
    this.crudService.getModel(pathListDOcumentTypesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listDocumentTypesAux = genericResponse.answerList;
          listDocumentTypesAux.forEach(documentType => {
            this.listDocumentTypes.push(documentType);
          });

          if (this.modalInformation.isCreate) {
            this.beneficiaryModel.documentTypeId.id = this.listDocumentTypes[0].id
          }
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los tipos de documentos del sistema',
            'Problema consultando los tipos de documentos del sistema');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los tipos de documentos del sistema ' + JSON.stringify(error))
      }
    );
  }

}
