import { ProfileModel } from 'models/entities/profile-model';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from 'app/shared/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import swal from 'sweetalert2';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { PairModuleProfileModel } from 'models/utilities/pair-module-profile-model';

@Component({
  selector: 'app-to-pair-user',
  templateUrl: './to-pair-user.component.html',
  styleUrls: ['./to-pair-user.component.scss']
})
export class ToPairUserComponent implements OnInit {

  rows = [];
  temp: string[];
  public moduleInfo: any;
  public profileUsers = new PairModuleProfileModel();
  errorMessage = '';
  isPairedUserFailed = false;
  dato: any;
  form: any = {};
  columns = [
    { prop: 'name' },
    { name: 'creatingDate' }
  ];
  @Input() public profileInfoUpdate = new ProfileModel();
  @ViewChild('f') planForm: NgForm;

  constructor(
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.form.moduleMenu = new PairModuleProfileModel()
    this.listModuleProfile(this.profileInfoUpdate.id);
  }

  configSelectModule() {
    let urlToConsult = '';
    if (this.tokenStorage.getAuthorities() === 'ROLE_SUPER') {
      urlToConsult = 'modulos/super/ver-todos';
    } else {
      urlToConsult = 'modulos/admin/listar-mis-modulos';
    }
    this.crudServices.getModel(urlToConsult).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.moduleInfo = genericResponse.answerList;
          const modulesToSelect = Array();
          for (const module of this.moduleInfo) {
            let itsIn = false;
            for (const moduloPorPlan of this.dato) {
              if (module.id === moduloPorPlan.id) {
                itsIn = true;
              }
            }
            if (!itsIn) {
              modulesToSelect.push(module);
            }
          }
          this.moduleInfo = modulesToSelect;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron menús')
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  listModuleProfile(id) {
    this.crudServices.getModel('perfil/listar-modules-perfil?profileId=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.answerList;
          this.temp = [...this.dato];
          this.rows = this.dato;
          this.configSelectModule();
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron modulos')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = error.error.message;
      }
    );
  }

  toPairUser(idModule, idProfile) {
    this.profileUsers.profileId = {
      id: idProfile
    }
    this.profileUsers.moduleId = {
      id: idModule
    }
    this.crudServices.createModel('perfil/enlazar-perfil-modulo', this.profileUsers).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate().then(
            (response) => {
              this.isPairedUserFailed = false;
              this.listModuleProfile(idProfile);
            }
          )
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creo el enlace')
          this.isPairedUserFailed = true;
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = error.error.info;
      }
    );
  }

  unPair(idModule, idProfile) {
    this.profileUsers.profileId = {
      id: idProfile
    }
    this.profileUsers.moduleId = {
      id: idModule
    }
    this.crudServices.createModel('perfil/desenlazar-perfil-modulo', this.profileUsers).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageDelete().then(
            (response) => {
              this.listModuleProfile(idProfile);
            }
          )
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se eliminó el enlace')
        }
      },
      error => {
        this.errorMessage = error.error.info;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  closeWindow() {
    this.modalService.dismissAll();
  }

}
