import { ActivityManagmentModel } from './../../../models/entities/activity-managment';
import { Component, OnInit, Input } from '@angular/core';
import { ActivityModel } from 'models/entities/activity';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';

@Component({
  selector: 'app-list-activity-managment',
  templateUrl: './list-activity-managment.component.html',
  styleUrls: ['./list-activity-managment.component.scss']
})
export class ListActivityManagmentComponent implements OnInit {
  @Input() listActivitys: Array<ActivityManagmentModel> = new Array();
  @Input() activity=new ActivityModel();
  constructor(private messageCtrl:InfoMessagesService,
    private serviceCtrl:CrudServiceService,
    private tokenServiceCtrl:TokenStorageService) { }

  ngOnInit() {
    this.getActivitysComents();
  }
  getActivitysComents(){
    let path='gestion-actividad?activityId='+this.activity.id;
    this.serviceCtrl.getModel(path).subscribe(
      result=>{
       
        if(result.code===200)
        {
           this.listActivitys=result.answerList;
        }
      }
      ,error=>{
        this.messageCtrl.getInfoMessageError();
      }
    )
  }

}
