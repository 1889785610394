export class SearchClientModel {
    clientName: string;
    documentNumberClient:string;
    sellerName: string;

    constructor() {
        this .clientName = '';
        this .documentNumberClient='';
        this .sellerName = '';
    }
}
