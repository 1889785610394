import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { DetailmoduleComponent } from './../../modules/detailmodule/detailmodule.component';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { CreatemenuComponent } from '../createmenu/createmenu.component';
import swal from 'sweetalert2';
import { PairModulePlanModel } from 'models/utilities/pair-module-plan-model';

@Component({
  selector: 'app-listmenu',
  templateUrl: './listmenu.component.html',
  styleUrls: ['./listmenu.component.scss']
})
export class ListmenuComponent implements OnInit {

  rows = [];
  temp: string[];
  board: string;
  errorMessage: string;
  dato: any;
  info: any = new PairModulePlanModel();
  datosDetalle: any;
  button = [];


  // Table Column Titles
  columns = [
    { prop: 'name' }
  ];

  constructor(
    private modalService: NgbModal,
    private tokenStorage: TokenStorageService,
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService
  ) { }

  ngOnInit() {
    this.configPage();
  }

  openCreateMenu() {
    const modalRef = this.modalService.open(CreatemenuComponent, {
      windowClass: '', size: 'lg',
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if (receivedEntry === 'Menú Creado') {
        this.configPage();
      } else {
        return false;
      }
    })
  }

  configPage() {
    // Obtendremos los usuarios a partir del servicio SUPER o ADMIN
    this.crudServices.getModel('menu/ver-todos').subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.answerList;
          this.temp = [...this.dato];
          this.rows = this.dato;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin menús')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  onDelete(id) {
    this.crudServices.deleteModel('menu/eliminar?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageDelete().then(
            (response) => {
              this.configPage();
            }
          )
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se eliminó el menú')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = `${error.status}: ${error.error.message}`;
      }
    );
  }

  deleteWarning(id) {
    event.preventDefault(); // prevent form submit
    swal({
      title: '¿Está seguro que desea eliminar el menu?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.onDelete(id);
      }
    })
  }

}
