import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from "@angular/core";
import { ClientModel } from "models/entities/client-model";
import { ModalInformationModel } from "models/utilities/modal-information-model";
import { RelationalFileModel } from "models/utilities/relational-type-model";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgForm } from '@angular/forms';

@Component({
  selector: "app-manage-clients",
  templateUrl: "./manage-clients.component.html",
  styleUrls: ["./manage-clients.component.scss"]
})
export class ManageClientsComponent implements OnInit {
  @Input() public clientModel: ClientModel = new ClientModel();
  @Input() public modelInfoClient: ModalInformationModel = new ModalInformationModel();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild("f") createClientForm: NgForm;
  @Input() public actvityClient=false; 

  public relationalModel: RelationalFileModel = new RelationalFileModel();

  constructor() {}

  ngOnInit() {

    this.relationalModel.relationalId = this.clientModel.id;
    this.relationalModel.relationalType = "DOCUMENTS_CLIENT_FILE";
    this.relationalModel.relationalFileType = "ARCHIVO";
    this.relationalModel.relationalRestrictions = new Array();
  }

  close() {
    this.passEntry.emit("ok");    
  }

  reportOfSon($event) {
    this.passEntry.emit("ok");
  }
}
