export class PairModuleProfileModel {
    id: string;
    profileId: any;
    moduleId: any;
    createdDate: string;

    public constructor() {
        this.id = '';
        this.profileId = '';
        this.moduleId = '-1';
        this.createdDate = '';
    }
}
