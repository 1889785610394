import { BudgetModel } from './budget-model';
import { PolicyModel } from './policy-model';
import { UserModel } from './user-model';

export class AttachedInfoModel {

    id: string;
    descriptionOfChanges: string;
    comments: string;
    type: string;
    consecutive: any;
    valuable: boolean;
    budgetId: BudgetModel;
    policyId: PolicyModel;
    createdDate: string;
    lastUpdateDate: string;
    creatorId: UserModel;

    public constructor() {
        this.id = '';
        this.descriptionOfChanges = '';
        this.comments = '';
        this.consecutive = '';
        this.valuable = false;
        this.type = '';
        this.budgetId = new BudgetModel();
        this.policyId = null;
        this.createdDate = '';
        this.lastUpdateDate = '';
        this.creatorId = new UserModel();
    }

}