import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchProductivityReportModel } from 'models/entities/search-productivity-report-model';
import { PageDataModel } from 'models/utilities/page-data-model';
import { Observable, Subject } from 'rxjs';
import { ClientModel } from 'models/entities/client-model';
import { ClassOfInsuranceModel } from 'models/entities/class-of-insurance.model';
import { InsurerModel } from 'models/entities/insurer-model';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { ExportExcelService } from 'app/shared/excel/export-excel.service';
import {  debounceTime,  distinctUntilChanged,  merge,  map,  filter} from 'rxjs/operators';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminPrincipalRuPoliciesComponent } from 'app/policies/admin-principal-ru-policies/admin-principal-ru-policies.component';
import { UserModel } from 'models/entities/user-model';

@Component({
  selector: 'app-productivity-report',
  templateUrl: './productivity-report.component.html',
  styleUrls: ['./productivity-report.component.scss']
})
export class ProductivityReportComponent implements OnInit {

  @ViewChild('instanceSinister') instanceSinister: NgbTypeahead;
  @ViewChild('instanceClassOfInsurance') instanceClassOfInsurance: NgbTypeahead;
  @ViewChild('instanceInsurance') instanceInsurance: NgbTypeahead;

  public listPolicies: Array<any> = new Array();
  columns = [];
  page: PageDataModel = new PageDataModel();
  row = new Array();
  public modelSearchAttached = new SearchProductivityReportModel();

  focusInsure$ = new Subject<string>();
  clickInsure$ = new Subject<string>();

  focusSeller$ = new Subject<string>();
  clickSeller$ = new Subject<string>();


  listClients: Array<ClientModel> = new Array();
  listClassOfInsurance: Array<ClassOfInsuranceModel> = new Array();
  listInsurer: Array<InsurerModel> = new Array();
  listSeller: Array<UserModel> = new Array();
  listAttached: any = [];

  focusClassOfInsurance$ = new Subject<string>();
  clickClassOfInsurance$ = new Subject<string>();

  isSearching = false;

 

  public columnsPolicy = [
    { prop: 'policy', width: 100 },
    { prop: 'policyId.insurerId.name', width: 200, name: 'Aseguradora' },
    { prop: 'policyId.classOfInsuranceId.name', width: 150, name: 'Ramo' },
    { prop: 'policyId.clientId.nameOrBusinessName', width: 180, name: 'Tomador' },
    { prop: 'policyId.clientId.phone', width: 180, name: 'Teléfono tomador' },
    { prop: 'policyId.policy', width: 150, name: 'Número Póliza' },
    { prop: 'policyId.sellerUserId.completeName', width: 200, name: 'Vendedor' },
    { prop: 'policyId.expeditionDate', width: 200, name: 'Fecha Expedición' }
  ];

  constructor(  private crudService: CrudServiceService,
                private messageService: InfoMessagesService,
                private tokenStorage: TokenStorageService,
                private modalService: NgbModal,
                private excelService: ExportExcelService ) 
                {
                  this.page.pageNumber = 0;
                  this.page.size = 10;
                }

  ngOnInit() {
    this.getClassOfInsurance();
    this.getInsurer();
    this.getSeller();
    
    
  }

  getAllReport(){
    const path = 'poliza/ver-poliza-por-produccion?initialDate=' + this.modelSearchAttached.initialDate +
  '&finalDate=' + this.modelSearchAttached.finalDate + 
  '&classOfInsuranceId=' + this.modelSearchAttached.classOfInsuranceId.id +
  '&insurerId= ' + this.modelSearchAttached.insurerId.id + 
  '&sellerId= ' + this.modelSearchAttached.sellerId.id;

  console.log(this.modelSearchAttached.classOfInsuranceId.id, this.modelSearchAttached.insurerId.id, this.modelSearchAttached.sellerId.id);
  this.listPolicies = new Array();
  this.crudService.getModel(path).subscribe(  
    (genericResponse: GenericResponseModel) => {
      if (genericResponse.code === 200 && genericResponse.answerList.length > 0) {
          
          this.listPolicies= genericResponse.answerList;
          
          console.log(this.listPolicies);
          
        }  else {
          this.messageService.getInfoMessagePersonalized(
            'warning',
            'No se encontraron coincidencias',
            'Aviso'
          );
      }
    },
    error => {
      this.messageService.getInfoMessageError();
      console.error('EL ERROR ES: ' + JSON.stringify(error));
    }
  );
  }

 

  getReportByClassOfinsuredAndSeller(){
    const path = 'poliza/ver-poliza-por-produccion-ClassInsuranceSeller?initialDate=' + this.modelSearchAttached.initialDate +
  '&finalDate=' + this.modelSearchAttached.finalDate + 
  '&classOfInsuranceId=' + this.modelSearchAttached.classOfInsuranceId.id +
  '&sellerId= ' + this.modelSearchAttached.sellerId.id;

  console.log(this.modelSearchAttached.classOfInsuranceId.id, this.modelSearchAttached.insurerId.id, this.modelSearchAttached.sellerId.id);
  this.listPolicies = new Array();
  this.crudService.getModel(path).subscribe(  
    (genericResponse: GenericResponseModel) => {
      if (genericResponse.code === 200 && genericResponse.answerList.length > 0) {
          
          this.listPolicies= genericResponse.answerList
          
        }  else {
          this.messageService.getInfoMessagePersonalized(
            'warning',
            'No se encontraron coincidencias',
            'Aviso'
          );
      }
    },
    error => {
      this.messageService.getInfoMessageError();
      console.error('EL ERROR ES: ' + JSON.stringify(error));
    }
  );
  }

  getReportByInsurerAndSeller(){
    const path = 'poliza/ver-poliza-por-produccion-insurerSeller?initialDate=' + this.modelSearchAttached.initialDate +
    '&finalDate=' + this.modelSearchAttached.finalDate + 
    '&sellerId= ' + this.modelSearchAttached.sellerId.id +
    '&insurerId= ' + this.modelSearchAttached.insurerId.id;
  
    console.log(this.modelSearchAttached.classOfInsuranceId.id, this.modelSearchAttached.insurerId.id, this.modelSearchAttached.sellerId.id);
    this.listPolicies = new Array();
    this.crudService.getModel(path).subscribe(  
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200 && genericResponse.answerList.length > 0) {
            
            this.listPolicies= genericResponse.answerList;
            
            console.log(this.listPolicies);
            
          }  else {
            this.messageService.getInfoMessagePersonalized(
              'warning',
              'No se encontraron coincidencias',
              'Aviso'
            );
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  }

  gerReportSeller(){
    const path = 'poliza/ver-poliza-por-produccion-seller?initialDate=' + this.modelSearchAttached.initialDate +
    '&finalDate=' + this.modelSearchAttached.finalDate + 
    '&sellerId= ' + this.modelSearchAttached.sellerId.id;
  
    console.log(this.modelSearchAttached.classOfInsuranceId.id, this.modelSearchAttached.insurerId.id, this.modelSearchAttached.sellerId.id);
    this.listPolicies = new Array();
    this.crudService.getModel(path).subscribe(  
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200 && genericResponse.answerList.length > 0) {
            
            this.listPolicies= genericResponse.answerList;
            
            console.log(this.listPolicies);
             
          }  else {
            this.messageService.getInfoMessagePersonalized(
              'warning',
              'No se encontraron coincidencias',
              'Aviso'
            );
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  }

  getReportByClassOfinsurer(){
    const path = 'poliza/ver-poliza-por-produccion-ramo?initialDate=' + this.modelSearchAttached.initialDate +
    '&finalDate=' + this.modelSearchAttached.finalDate + 
    '&classOfInsuranceId=' + this.modelSearchAttached.classOfInsuranceId.id;
  
    console.log(this.modelSearchAttached.classOfInsuranceId.id, this.modelSearchAttached.insurerId.id, this.modelSearchAttached.sellerId.id);
    this.listPolicies = new Array();
    this.crudService.getModel(path).subscribe(  
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200 && genericResponse.answerList.length > 0) {
            
            this.listPolicies= genericResponse.answerList;
            
            console.log(this.listPolicies);
             
          }  else {
            this.messageService.getInfoMessagePersonalized(
              'warning',
              'No se encontraron coincidencias',
              'Aviso'
            );
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  }

  getReportByInsurer(){
    const path = 'poliza/ver-poliza-por-produccion-insurer?initialDate=' + this.modelSearchAttached.initialDate +
    '&finalDate=' + this.modelSearchAttached.finalDate + 
    '&insurerId= ' + this.modelSearchAttached.insurerId.id;
  
    console.log(this.modelSearchAttached.classOfInsuranceId.id, this.modelSearchAttached.insurerId.id, this.modelSearchAttached.sellerId.id);
    this.listPolicies = new Array();
    this.crudService.getModel(path).subscribe(  
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200 && genericResponse.answerList.length > 0) {
            
            this.listPolicies= genericResponse.answerList;
            
            console.log(this.listPolicies);
             
          }  else {
            this.messageService.getInfoMessagePersonalized(
              'warning',
              'No se encontraron coincidencias',
              'Aviso'
            );
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  }
//Obtener las polizas que pertenecen a un vendedor segun filtros
getReport(){
  if(this.modelSearchAttached.classOfInsuranceId .id != null && this.modelSearchAttached.insurerId.id != null && this.modelSearchAttached.sellerId.id != null) {
    this.getAllReport();
  }
 
  else{
    if(this.modelSearchAttached.classOfInsuranceId.id != null && this.modelSearchAttached.insurerId.id === null && this.modelSearchAttached.sellerId.id != null){
      this.getReportByClassOfinsuredAndSeller();
    }
    else{
      if(this.modelSearchAttached.classOfInsuranceId.id === null && this.modelSearchAttached.insurerId.id != null && this.modelSearchAttached.sellerId.id != null){
        this.getReportByInsurerAndSeller();
      }
      else{
        if(this.modelSearchAttached.classOfInsuranceId.id === null && this.modelSearchAttached.insurerId.id === null && this.modelSearchAttached.sellerId.id != null)
        { this.gerReportSeller();}

        else{
          if(this.modelSearchAttached.classOfInsuranceId.id != null && this.modelSearchAttached.insurerId.id === null && this.modelSearchAttached.sellerId.id === null){
            this.getReportByClassOfinsurer();
          }
          else{
            if(this.modelSearchAttached.classOfInsuranceId.id === null && this.modelSearchAttached.insurerId.id != null && this.modelSearchAttached.sellerId.id === null){
              this.getReportByInsurer();
            }
          }
        }
      }
    }
  
}
}

  // obtiene los ramos del usuario con sesión iniciada
  getClassOfInsurance() {
    const pathClassOfInsurance = 'ramos/listar-ramos';
    this.listClassOfInsurance = new Array();
    this.crudService.getModel(pathClassOfInsurance).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listClassOfInsurance= genericResponse.answerList;
          
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  }


  // obtiene las aseguradoras del usuario con sesión iniciada
  getInsurer() {
    const pathInsurer = 'empresa/aseguradora/listar-aseguradoras';
    this.listInsurer = new Array();
    this.crudService.getModel(pathInsurer).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listInsurer = genericResponse.answerList;
          
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  }


  // obtiene los usuarios con rol vendedor del usuario con sesión iniciada
  getSeller() {
    const path = 'usuarios/listar-vendedores-y-gestores';
    this.listSeller = new Array();
    this.crudService.getModel(path).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listSeller = genericResponse.answerList;
          
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  }


  // generar documento en formato XLSX ("Excel") Hoja de cálculo
  exportAsXLSX(): void {
    const attachedAux: any = [];
    if ( this.listPolicies.length > 0 ) {
      this.listPolicies.forEach(attached => {
        attachedAux.push({
          'Aseguradora' : attached.policyId.insurerId.name,
          'Ramo' : attached.policyId.classOfInsuranceId.name,
          'Tomador' : attached.policyId.clientId.nameOrBusinessName,
          'Número Póliza' : attached.policyId.policy,
          'Vendedor' : attached.policyId.sellerUserId.completeName,
          'Valor Prima' : attached.budgetId.premium,
          'Fecha de Expedición' : attached.policyId.expeditionDate
        });
      });
      this.excelService.exportAsExcelFile(attachedAux, 'Reporte Productividad_' + this.tokenStorage.getCompanyName());
    } else {
      this.messageService.getInfoMessagePersonalized(
        'warning',
        '',
        'No hay elementos para generar el documento'
      );
    }
  }


  // llama al componente encargado de mostrar las pólizas, con la poóliza que recibe por parametro
  viewPolicy(policy) {
    const modal = this.modalService.open(AdminPrincipalRuPoliciesComponent, {
      windowClass: 'largeModal', size: 'lg', backdrop: false
    });
    modal.componentInstance.policyModel = policy.policyId;
    modal.componentInstance.passEntry.subscribe(result => {
      if (result !== null) {
        modal.dismiss();
      } else {
        modal.dismiss();
      }
    });
  }


  // Borra el contenido de los input del formulario por id
  cleanFilters() {
    $('#initialDate').val('');
    $('#finalDate').val('');
    $('#seller').val('');
    $('#sinister').val('');
    $('#insurer').val('');

    this.listAttached = [];
    this.listPolicies = [];
    this.modelSearchAttached = new SearchProductivityReportModel();
    this.ngOnInit();
  }

  searchByNameClassOfInsurance = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusClassOfInsurance$),
      merge(this.clickClassOfInsurance$.pipe(filter(() => !this.instanceClassOfInsurance.isPopupOpen()))),
      map(search => (search === '' ? this.listClassOfInsurance
        : this.listClassOfInsurance.filter(classOfInsurance =>
          classOfInsurance.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
    );

  searchSeller = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusSeller$),
      merge(this.clickSeller$.pipe(filter(() => !this.instanceSinister.isPopupOpen()))),
      map(search => (search === '' ? this.listSeller
          : this.listSeller.filter(name => name.completeName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10)
      )
    );

    searchByNameInsurance = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusInsure$),
      merge(this.clickInsure$.pipe(filter(() => !this.instanceInsurance.isPopupOpen()))),
      map(search => (search === '' ? this.listInsurer
        : this.listInsurer.filter(insurance =>
          insurance.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
    );

  formatter2 = (x: { completeName: string }) => x.completeName;
  formatter = (object: { name: string }) => object.name;
  formatter1 = (x: {name : string}) => x.name;
}
