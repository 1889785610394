import { AttachedInfoModel } from './attached-info-model';

export class InstallmentModel {
    id: string;
    state: string;
    number: string;
    expirationDate: string;
    premium: string;
    iva: string;
    spendings: string;
    interest: string;
    total: string;
    balance: string;
    createdDate: any;
    lastUpdateDate: string;
    creatorId: string;
    lastUpdateId: string;
    attachedId: AttachedInfoModel;

    public constructor() {
        this.id = '';
        this.state = '';
        this.number = '';
        this.expirationDate = '';
        this.premium = '';
        this.iva = '';
        this.spendings = '';
        this.interest = '';
        this.total = '';
        this.balance = '';
        this.createdDate = new Date();
        this.lastUpdateDate = '';
        this.creatorId = '';
        this.lastUpdateId = '';
        this.attachedId = new AttachedInfoModel();
    }
}
