import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WelcomeRoutingModule } from './welcome-routing.module';
import { WelcomeTitleComponent } from './welcome-title/welcome-title.component';
import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';
import { PricingComponent } from './pricing-container/pricing/pricing.component';
import { BannerComponent } from './banner/banner.component';
import { LandingComponent } from './landing/landing.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PricingContainerComponent } from './pricing-container/pricing-container.component';
import { SevicesContainerComponent } from './sevices-container/sevices-container.component';
import { ServiceComponent } from './sevices-container/service/service.component';
import { ClientInfoComponent } from './client-info/client-info.component';

@NgModule({
  imports: [
    CommonModule,
    WelcomeRoutingModule,
    FormsModule,
    NgbModule
  ],
  declarations: [
    WelcomeTitleComponent,
    PricingComponent,
    BannerComponent,
    LandingComponent,
    PricingContainerComponent,
    SevicesContainerComponent,
    ServiceComponent,
    ClientInfoComponent],
  providers: [RoleAuthGuard],
  exports: [BannerComponent, ClientInfoComponent],
})
export class WelcomeModule { }
