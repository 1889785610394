import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { SearchClientModel } from 'models/types/search-client-model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';

@Component({
  selector: 'app-searching-clients',
  templateUrl: './searching-clients.component.html',
  styleUrls: ['./searching-clients.component.scss']
})
export class SearchingClientsComponent implements OnInit {
  @Output() passEntry = new EventEmitter<any>();

  modelSeacrhClient = new SearchClientModel();

  constructor(private messageService: InfoMessagesService) {}

  ngOnInit() {}

  submitForm() {
    if (  this.modelSeacrhClient.clientName === '' &&
          this.modelSeacrhClient.documentNumberClient === '' &&
          this.modelSeacrhClient.sellerName === '' ) {
          this.messageService.getInfoMessagePersonalized(
          'warning',
          '',
          'Los Filtros Están Vacíos '
      );
    } else {
      this.reportOfSon();
    }
  }

  // borra el contenido de los inputi por id
  cleanFilters() {
    $('#clientName').val('');
    $('#sellerName').val('');
    $('#documnetNumberClient').val('');

    this.modelSeacrhClient = new SearchClientModel();
    this.passEntry.emit(1);
  }

  reportOfSon() {
    this.passEntry.emit(this.modelSeacrhClient);
  }
}
