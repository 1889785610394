import { Component, OnInit } from '@angular/core';
import { PricingModel } from './model/pricing.model';

@Component({
  selector: 'app-pricing-container',
  templateUrl: './pricing-container.component.html',
  styleUrls: ['./pricing-container.component.scss']
})
export class PricingContainerComponent implements OnInit {
  pricings: Array<PricingModel>;
  constructor() { }

  ngOnInit() {
    this.pricings = [
      {
        fare: 'Gratis',
        price: '',
        period: '',
        actionText: 'Obten Información',
        items: [
          {
            icon: 'fa fa-check',
            title: '1 Usuario'
          },
          {
            icon: 'fa fa-check',
            title: '200MB'
          },
          {
            icon: 'fa fa-check',
            title: '20 Polizas'
          },
          {
            icon: 'fa fa-check',
            title: 'App Movil'
          },
          {
            icon: 'fa fa-check',
            title: 'Comisiones',
            hasMeted: false
          },
          {
            icon: 'fa fa-check',
            title: 'Reportes',
            hasMeted: false
          },
          {
            icon: 'fa fa-check',
            title: '$0/mes',
            hasMeted: false
          },
          {
            icon: 'fa fa-check',
            title: '$0/año',
            hasMeted: false
          }
        ]
      },
      {
        fare: 'Básico',
        price: '',
        period: '',
        actionText: 'Obten Información',
        items: [
          {
            icon: 'fa fa-check',
            title: '2 Usuarios'
          },
          {
            icon: 'fa fa-check',
            title: '3GB'
          },
          {
            icon: 'fa fa-check',
            title: '1000 Polizas'
          },
          {
            icon: 'fa fa-check',
            title: 'App Movil'
          },
          {
            icon: 'fa fa-check',
            title: 'Comisiones',
            hasMeted: false
          },
          {
            icon: 'fa fa-check',
            title: 'Reportes',
            hasMeted: false
          },
          {
            icon: 'fa fa-check',
            title: '50.000/mes',
            hasMeted: false
          },
          {
            icon: 'fa fa-check',
            title: '500.000/año',
            hasMeted: false
          }
        ]
      },
      {
        fare: 'Pro',
        price: '',
        period: '',
        actionText: 'Obten Información',
        items: [
          {
            icon: 'fa fa-check',
            title: '5 usuarios'
          },
          {
            icon: 'fa fa-check',
            title: '10GB'
          },
          {
            icon: 'fa fa-check',
            title: '4.000 Polizas'
          },
          {
            icon: 'fa fa-check',
            title: 'App Movil'
          },
          {
            icon: 'fa fa-check',
            title: 'Comisiones',
            hasMeted: false
          },
          {
            icon: 'fa fa-check',
            title: 'Reportes',
            hasMeted: false
          },
          {
            icon: 'fa fa-check',
            title: '120.000/mes',
            hasMeted: false
          },
          {
            icon: 'fa fa-check',
            title: '1.200.000/año',
            hasMeted: false
          }
        ]
      },
      {
        fare: 'Personalizado',
        price: '',
        period: '',
        actionText: 'Obten Información',
        items: [
          {
            icon: 'fa fa-check',
            title: '¿Preguntame Cómo?'
          }
        ]
      }
    ]
  }
}
