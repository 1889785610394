import { InsurerModel } from 'models/entities/insurer-model';
import { ClientModel } from 'models/entities/client-model';
import { ClassOfInsuranceModel } from 'models/entities/class-of-insurance.model';
import { UserModel } from 'models/entities/user-model';

export class SearchSinisterModel {
    initialDate: string;
    finalDate: string;
    client: any;
    sinister: any;
    insurer: any;
    seller: any;

    constructor() {
        this.initialDate = '';
        this.finalDate = '';
        this.client = new ClientModel();
        this.sinister = new ClassOfInsuranceModel();
        this.insurer = new InsurerModel();
        this.seller = new UserModel();
    }
}
