import { Component, OnInit, Input, ViewChild, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PolicyModel } from 'models/entities/policy-model';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { formatDate } from '@angular/common';
import {Observable, Subject, Subscription} from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { ClientModel } from 'models/entities/client-model';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'ts-xlsx';
import { LeaseRiskModel } from 'models/entities/lease-risk-model';
import {SharedService} from "../../shared/shared.service";

@Component({
  selector: 'app-lease-risk-cru',
  templateUrl: './lease-risk-cru.component.html',
  styleUrls: ['./lease-risk-cru.component.scss']
})
export class LeaseRiskCruComponent implements OnInit, OnChanges {
  @Input() policyId = 1;
  @ViewChild('f') registerForm: NgForm;

  @Input() leaseRiskModel = new LeaseRiskModel();
  @Input() policyModel = new PolicyModel();

  flagSuccessfulUpdate: any;

  @Output() emitterSuccessfulUpdate: EventEmitter<boolean>;
  @Output() emitterActivityRecord: EventEmitter<any>;

  today = '';
  listClients: Array<ClientModel> = new Array();
  @ViewChild('instanceClient') instanceClient: NgbTypeahead;
  clickClient$ = new Subject<string>();
  focusClient$ = new Subject<string>();

  arrayBuffer: any;
  file: File;
  excelFile = new Array();
  fileSelected = false;
  fileName = 'Elegir archivo';
  idClient: any;

  riskInfoSubscription: Subscription;

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService,
    private sharedService: SharedService
    ) {
      this.emitterSuccessfulUpdate = new EventEmitter();
    this.emitterActivityRecord = new EventEmitter();
    this.today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    }

  ngOnInit() {
    this.leaseRiskModel.policyId = { id: this.policyModel.id };
    this.getClients();
    this.riskInfoSubscription = this.sharedService.getRiskInfo().subscribe(riskInfo => {
      const { client, validUntil, validSince } = riskInfo;
      this.leaseRiskModel.clientId = client;
      this.leaseRiskModel.validSince = validSince;
      this.leaseRiskModel.validUntil = validUntil;
    });
  }

  ngOnDestroy() {
    if (this.riskInfoSubscription) {
      this.riskInfoSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getClients();
  }

  onSubmit(form: NgForm)  {
    const path = 'riesgo-arrendamiento/crear-riesgo-arrendamiento';

    const date = formatDate(new Date(), 'yyyy-MM-dd', 'en');

    this.leaseRiskModel.policyId = { id: this.policyModel.id };
    this.leaseRiskModel.classOfInsuranceId = {id: this.policyModel.classOfInsuranceId.id};
    this.leaseRiskModel.creatorId = { id: this.tokenStorage.getId()};
    this.leaseRiskModel.lastUpdateId = { id: this.tokenStorage.getId()};
    this.leaseRiskModel.lastUpdateDate = date;
    this.leaseRiskModel.createdDate = date;

    this.leaseRiskModel.state = 'INCLUIDO';

    this.crudService.createModel(path, this.leaseRiskModel).subscribe(
      (data: GenericResponseModel) => {
        if (data.code === 200) {
          this.messageService.getInfoMessageCreate();
          if (this.leaseRiskModel.id === null) {
            this.sendActivityRecord('Se creó un riesgo con No. Registro ' + data.genericObject.id);
          } else {
            this.sendActivityRecord('Se modificó un riesgo con No. Registro ' + this.leaseRiskModel.id);
          }
          this.leaseRiskModel = new LeaseRiskModel();
          this.rebuildClient();
          form.form.markAsPristine();
          form.form.markAsUntouched();
          form.form.updateValueAndValidity();
          this.successfulUpdate(new Date().getSeconds());
        } else if (data.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', data.answer, 'No se creó el riesgo')
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized('error', error.error.answer + '', 'Error');
        console.error('El error es ', JSON.stringify(error));
      }
    );
    this.successfulUpdate(new Date().getSeconds());
  }

  sendActivityRecord(activity: any) {
    this.emitterActivityRecord.emit(activity);
  }

  getClients() {
    const pathListClientsUrl = 'cliente/listar-nombre-por-empresa';
    this.listClients = new Array();
    this.crudService.getModel(pathListClientsUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listClientsAux = genericResponse.answerList;
          listClientsAux.forEach(client => {
            this.listClients.push(client);
          });
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los clientes asociados a su empresa',
            'Problema consultando los clientes asociados a su empresa');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los clientes asociados a su empresa ' + JSON.stringify(error))
      }
    );
  }

  allIsRight() {
    return this.containsValidation(this.leaseRiskModel.clientId, this.listClients)
  }

  searchByNameClient = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    merge(this.focusClient$),
    merge(this.clickClient$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
    map(search => (search === '' ? this.listClients
      : this.listClients.filter(client => client.nameOrBusinessName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
  );

  formatterClient = (object: { nameOrBusinessName: string }) => object.nameOrBusinessName;

  rebuildClient() {
    this.policyModel.sellerAndInsuredEquals = false;
    this.policyModel.clientId = new ClientModel();
    this.policyModel.insuredId = new ClientModel();
  }

  containsValidation(objectToValidate, listToSearch: Array<any>) {
    for (const object of listToSearch) {
      if (object.id === objectToValidate.id) {
        return true;
      }
    }
  }
  successfulUpdate(value: any) {
    this.flagSuccessfulUpdate = value;
    this.emitterSuccessfulUpdate.emit(this.flagSuccessfulUpdate);
  }
  incomingfile(event) {
    this.file = event.target.files[0];
    this.fileSelected = true;
    this.fileName = this.file.name;
  }

  Upload() {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      // Lectura de archivo de excel
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) { arr[i] = String.fromCharCode(data[i]); }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];
      this.excelFile.push(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      let index = 0;
      const errors = [];

      if (this.excelFile[0].length > 0) {
        // Se revisa cada fila de la hoja de datos
        this.excelFile[0].forEach(element => {
          // Se revisa cada celda de la fila y se validan que tengan valores
          let idClient = '-1';

          // Se buscan el id del tipo documento y el numero de documento
          this.listClients.forEach(element => {
            if (element.documentTypeId.id.toString() === this.excelFile[0][index].idTipoDocumento.toString()
              && element.documentNumber === this.excelFile[0][index].numeroDocumento.toString()) {
              idClient = element.id;
            }
          });

          // Si el cliente existe en la lista de clientes cargada
          if (idClient !== '-1') {
            if (this.excelFile[0][index].valorCanon !== undefined) {
              if (this.excelFile[0][index].ubicacion !== undefined) {
                if (this.excelFile[0][index].validoDesde !== undefined) {
                  if (this.excelFile[0][index].validoHasta !== undefined) {
                    if (this.excelFile[0][index].vrAsegurado !== undefined) {
                      if (this.excelFile[0][index].estrato !== undefined) {
                          //  console.log(this.excelFile[0][index]);
                          const date = formatDate(new Date(), 'yyyy-MM-dd', 'en');
                          this.leaseRiskModel.createdDate = date;
                          this.leaseRiskModel.valueCanyon = this.excelFile[0][index].valorCanon;
                          this.leaseRiskModel.location = this.excelFile[0][index].ubicacion;
                          this.leaseRiskModel.lastUpdateDate = date;
                          this.leaseRiskModel.state = 'INCLUIDO';
                          this.leaseRiskModel.validSince = formatDate(new Date((this.excelFile[0][index].validoDesde -
                            (25567 + 1)) * 86400 * 1000), 'yyyy-MM-dd', 'en');
                          this.leaseRiskModel.validUntil = formatDate(new Date((this.excelFile[0][index].validoHasta -
                            (25567 + 1)) * 86400 * 1000), 'yyyy-MM-dd', 'en');
                          this.leaseRiskModel.classOfInsuranceId = { id: this.policyModel.classOfInsuranceId.id }
                          this.leaseRiskModel.clientId = { id: idClient };
                          this.leaseRiskModel.creatorId = { id: this.tokenStorage.getId() };
                          this.leaseRiskModel.lastUpdateId = { id: this.tokenStorage.getId() };
                          this.leaseRiskModel.policyId = { id: this.policyModel.id };
                          this.leaseRiskModel.vrInsured = this.excelFile[0][index].vrAsegurado;
                          this.leaseRiskModel.stratum = this.excelFile[0][index].estrato;
                          //  console.log(JSON.stringify(this.leaseRiskModel));
                          this.uploadRiskMassively(this.leaseRiskModel);
                          this.leaseRiskModel = new LeaseRiskModel();
                          this.fileSelected = false;
                      } else {
                        console.error(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                        errors.push(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                        this.fileSelected = false;
                      }
                    } else {
                      console.error(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                      errors.push(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                      this.fileSelected = false;
                    }
                  } else {
                    console.error(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                    errors.push(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                    this.fileSelected = false;
                  }
                } else {
                  console.error(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                  errors.push(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                  this.fileSelected = false;
                }
              } else {
                console.error(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                errors.push(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                this.fileSelected = false;
              }
            } else {
              console.error(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
              errors.push(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
              this.fileSelected = false;
            }
          } else {
            errors.push(' El cliente no existe en la fila ' + (index + 2));
            this.fileSelected = false;
          }
          if (errors.length > 0) {
            this.messageService.getInfoMessagePersonalized('error',
              errors, 'Se encontraron los siguientes errores');
          }
          index++
        }
        );
      } else {
        console.error('El archivo no tiene registros');
        this.messageService.getInfoMessagePersonalized('error',
          'El archivo no tiene registros', 'Error');
        this.fileSelected = false;
        this.file = null;
      }
    }
    fileReader.readAsArrayBuffer(this.file);
  }

  uploadRiskMassively(leaseRiskModel: LeaseRiskModel) {
    const path = 'riesgo-arrendamiento/crear-riesgo-arrendamiento';
    this.crudService.createModel(path, leaseRiskModel).subscribe(
      (data: GenericResponseModel) => {
        if (data.code === 200) {
          if (leaseRiskModel.id === null) {
            this.sendActivityRecord('Se creó un riesgo con No. Registro ' + data.genericObject.id);
          } else {
            this.sendActivityRecord('Se modificó un riesgo con No. Registro ' + leaseRiskModel.id);
          }
          leaseRiskModel = new LeaseRiskModel();
          this.rebuildClient();
          this.successfulUpdate(new Date().getSeconds());
        } else if (data.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', data.answer, 'No se creó el riesgo')
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized('error', error.error.answer + '', 'Error');
        console.error('El error es ', JSON.stringify(error));
      });
  }

  downloadFile() {
    const link = document.createElement('a');
    link.download = 'lease-risk-template';
    link.href = 'assets/risks-templates/lease-risk-template.xlsx';
    link.click();
  }
}

