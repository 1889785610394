import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PageDataModel } from 'models/utilities/page-data-model';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { ClientModel } from 'models/entities/client-model';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { PolicyModel } from 'models/entities/policy-model';
import { AdminPrincipalRuPoliciesComponent } from 'app/policies/admin-principal-ru-policies/admin-principal-ru-policies.component';
import { ManageClientsComponent } from 'app/clients/manage-clients/manage-clients.component';
import { formatDate } from '@angular/common';
import { ModalInfo } from 'models/entities/modal-info';
import { CruClientComponent } from 'app/clients/cru-client/cru-client.component';

@Component({
  selector: 'app-initial-client-searcher',
  templateUrl: './initial-client-searcher.component.html',
  styleUrls: ['./initial-client-searcher.component.scss']
})
export class InitialClientSearcherComponent implements OnInit {
  @Input() listClients: Array<ClientModel> = new Array();
  @Input() canShowLoading = false;

  clientIdToSearch: any;
  valueToSearch: any;

  row = [];
  rows: Array<any> = new Array();
  temp: string[];
  errorMessage: string;
  dato: any;
  modalInforClientModel = new ModalInfo();

  flagClien

  public listPolicies: Array<any> = new Array();
  public columns = [
    { prop: 'policy.clientId.nameOrBusinessName', width: 200, name: 'Nombre Razon Social' },
    { prop: 'policy.clientId.documentNumber', width: 200, name: 'Número Documento' },
    { prop: 'policy.policy', width: 300, name: 'Número Póliza' },
    { prop: 'policy.classOfInsuranceId.name', width: 300, name: 'Ramo' },
    { prop: 'policy.insurerId.name', width: 150, name: 'Aseguradora' },
    { prop: 'policy.clientId.nameOrBusinessName', width: 150, name: 'Tomador' },
    { prop: 'relatioship', width: 150, name: 'Tipo Relación' },
  ];

  numberOfPages: any = 0;
  page = new PageDataModel();

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private routerService: Router,
    private token: TokenStorageService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit() {
      this.setPage({ offset: 0 });
      // Se recibe una lista con los clientes encontrados
      // Si no se encontraron clientes con el nombre/documento, se busca
      // directamente en los beneficiarios
      if (this.listClients.length === 0) {
        this.getPoliciesByBeneficiary();
      }
      // Si hay al menos un cliente, se busca primero en las polizas
      // Despues se busca si también es un beneficiario
      // Si no se encontro en polizas ni en beneficiarios, se busca en todos los riesgos
      if (this.listClients.length > 0) {
        setTimeout( () => {
          this.getListPoliciesByClient(0);
        }, 1000);
        setTimeout( () => {
           this.getPoliciesByBeneficiary();
        }, 1000);
        if (this.rows.length === 0) {
          setTimeout( () => {
            this.getPoliciesByRisks();
          }, 1000);
        }
      }
      setTimeout(() => {
        this.canShowLoading = false;
        if (this.rows.length === 0) {
        this.messageService.getInfoMessagePersonalized(
          'warning',
          'No se encontraron resultados.',
          'Sin resultados'
          );
        }
      }, 3700);
  }

   getPoliciesByRisks() {
    this.getPoliciesByAllRiskConstruction();
    this.getPoliciesByCapitalization();
    this.getPoliciesByCoownerShipRisk();
    this.getPoliciesByFulfillmentRisk();
    this.getPoliciesByHealthRisk();
    this.getPoliciesByHomeRisk();
    this.getPoliciesByLifeRisk();
    this.getPoliciesByMachineryBreakdownRisk();
    this.getPoliciesByMaritimeTransportRisk();
    this.getPoliciesByMultiRisk();
    this.getPoliciesByPersonalAccidentRisk();
    this.getPoliciesByPymeRisk();
    this.getPoliciesByRCCVehicleRisk();
    this.getPoliciesByRCCCyberRisk();
    this.getPoliciesByRCEVehicleRisk();
    this.getPoliciesByRCProfesionalRisk();
    this.getPoliciesBySOATRisk();
    this.getPoliciesByVehicleRisk();
  }

   getPoliciesByBeneficiary() {
    // console.log('listClients: ' + JSON.stringify(this.listClients));
      const pathListBeneficiaryUrl = 'beneficiario/ver-beneficiario-por-nombre-o-documento?valueToSearch=' + this.valueToSearch;
      this.crudService.getModel(pathListBeneficiaryUrl).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            // console.log('beneficiarios');
            // console.log(JSON.stringify(JSON.stringify(genericResponse.answerList)));
            if (genericResponse.answerList.length > 0) {
              genericResponse.answerList.forEach(answer => {
                    this.rows.push({policy: answer.policyId, relationship: 'Beneficiario'})
              });
            }
            this.rows = [...this.rows]
            // console.log(this.rows);
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas asociadas a los clientes',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
  }

   getListPoliciesByClient(page: string | number) {
    this.rows = new Array();
    this.listClients.forEach(client => {
      const pathListPoliciesUrl = 'poliza/listar-polizas-por-cliente?clientId=' + client.id + '&page=' + page;
      this.crudService.getModel(pathListPoliciesUrl).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            // console.log('answerListByClient');
            // console.log(JSON.stringify(genericResponse.answerList));
            genericResponse.answerList.forEach(answer => {
              if (!this.rows.find(x => x.policy.id === answer.policy.id)) {
                // console.log('client.id: ' + client.id);
                // console.log('answer.policy.clientId: ' + answer.policy.clientId.id);
                if (answer.policy.sellerAndInsuredEquals === true) {
                  this.rows.push({policy: answer.policy, relationship: 'Tomador/Asegurado'})
                } else if (answer.policy.clientId.id === client.id
                           && answer.policy.insurerId.id !== client.id) {
                  this.rows.push({policy: answer.policy, relationship: 'Tomador'})
                }  else if (answer.policy.insurerId.id === client.id) {
                  this.rows.push({policy: answer.policy, relationship: 'Asegurado'})
                }
              }
            });
            this.rows = [...this.rows]
            // console.log('logByClient');
            // console.log(JSON.stringify(this.rows));
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas asociadas a los clientes',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
    });
  }

  configListPolicies(page: string | number) {
    const pathListPoliciesUrl = 'poliza/listar-polizas-por-mi-empresa?page=' + page;
    this.listPolicies = new Array();
    this.crudService.getModel(pathListPoliciesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        console.log(genericResponse.answerList);
        if (genericResponse.code === 200) {
          this.listPolicies = genericResponse.answerList;
          this.page.totalElements = genericResponse.variable;
          this.page.pageNumber = page;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas de su empresa',
            'Problema consultando las polizas');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
      }
    );
  }

  getClients(page) {
    const pathlistActivityTypeUrl = 'cliente/ver-por-empresa-paginado?page=' + page;
    this.row = [];
    this.crudService.getModel(pathlistActivityTypeUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.temp = [...genericResponse.answerList];
          this.row = genericResponse.answerList;
          console.log('tamaño lista', this.row.length );
          this.page.totalElements = genericResponse.variable;
          this.page.pageNumber = page;
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  }

  setPage(pageInfo: { offset: any; }) {
    this.getListPoliciesByClient(pageInfo.offset);
  }

  viewPolicy(policyTo: PolicyModel) {
    const modal = this.modalService.open(AdminPrincipalRuPoliciesComponent, {
      windowClass: 'largeModal', size: 'lg', backdrop: false
    });
    modal.componentInstance.policyModel = policyTo;
    modal.componentInstance.passEntry.subscribe(result => {
      if (result !== null) {
        modal.dismiss();
        this.configListPolicies(0);
      } else {
        modal.dismiss();
      }
    });
  }

  lookClient(clientAux) {
    console.log('Dato a enviar: ' + JSON.stringify(clientAux.policy.clientId));
    const modal = this.modalService.open(ManageClientsComponent, {
      windowClass: '',
      size: 'lg',
      backdrop: 'static'
    });

    this.modalInforClientModel.isCreate = false;
    this.modalInforClientModel.isUpdate = false;
    this.modalInforClientModel.isView = true;
    modal.componentInstance.modelInfoClient = this.modalInforClientModel;
    modal.componentInstance.clientModel = clientAux.policy.clientId;
    modal.componentInstance.passEntry.subscribe(update => {
      if (update === 'ok') {
        this.getClients(0);
      }
    });
  }

  createActivity(id: any) {
  }

  close() {
    this.activeModal.close();
  }

  getPoliciesByAllRiskConstruction() {
    this.listClients.forEach(client => {
      const path = 'riesgo-todo-riesgo-construccion/listar-riesgos-todo-riesgo-construccion-por-cliente?clientId=' + client.id;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            // console.log('answerListConstruction');
            // console.log(JSON.stringify(genericResponse.answerList));
            genericResponse.answerList.forEach(answer => {
              this.rows.push({policy: answer.policyId, relationship: 'Vinculado'})
            });
            this.rows = [...this.rows]
            // console.log('rowsConstruction');
            // console.log(JSON.stringify(this.rows));
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas asociadas a los clientes',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
    });
  }

  getPoliciesByCapitalization () {
    this.listClients.forEach(client => {
      const path = 'riesgo-capitalizacion/listar-riesgos-capitalizacion-por-cliente?clientId=' + client.id;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            // console.log(JSON.stringify(genericResponse.answerList));
            genericResponse.answerList.forEach(answer => {
              this.rows.push({policy: answer.policyId, relationship: 'Vinculado'})
            });
            this.rows = [...this.rows]
            // console.log(this.rows);
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas asociadas a los clientes',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
    });
  }

  getPoliciesByCoownerShipRisk () {
    this.listClients.forEach(client => {
      const path = 'riesgo-copropiedad/listar-riesgos-copropiedad-por-cliente?clientId=' + client.id;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            // console.log(JSON.stringify(genericResponse.answerList));
            genericResponse.answerList.forEach(answer => {
              this.rows.push({policy: answer.policyId, relationship: 'Vinculado'})
            });
            this.rows = [...this.rows]
            // console.log(this.rows);
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas asociadas a los clientes',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
    });
  }

  getPoliciesByFulfillmentRisk () {
    this.listClients.forEach(client => {
      const path = 'riesgo-cumplimiento/listar-riesgos-cumplimiento-por-cliente?clientId=' + client.id;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            // console.log(JSON.stringify(genericResponse.answerList));
            genericResponse.answerList.forEach(answer => {
              this.rows.push({policy: answer.policyId, relationship: 'Vinculado'})
            });
            this.rows = [...this.rows]
            // console.log(this.rows);
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas asociadas a los clientes',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
    });
  }

  getPoliciesByHealthRisk() {
    this.listClients.forEach(client => {
      const path = 'riesgo-salud/listar-riesgos-salud-por-cliente?clientId=' + client.id;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            // console.log(JSON.stringify(genericResponse.answerList));
            genericResponse.answerList.forEach(answer => {
              this.rows.push({policy: answer.policyId, relationship: 'Vinculado'})
            });
            this.rows = [...this.rows]
            // console.log(this.rows);
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas asociadas a los clientes',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
    });
  }

  getPoliciesByHomeRisk() {
    this.listClients.forEach(client => {
      const path = 'riesgo-casa/listar-riesgos-casa-por-cliente?clientId=' + client.id;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            // console.log(JSON.stringify(genericResponse.answerList));
            genericResponse.answerList.forEach(answer => {
              this.rows.push({policy: answer.policyId, relationship: 'Vinculado'})
            });
            this.rows = [...this.rows]
            // console.log(this.rows);
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas asociadas a los clientes',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
    });
  }

  getPoliciesByLifeRisk() {
    this.listClients.forEach(client => {
      const path = 'riesgo-vida/listar-riesgos-vida-por-cliente?clientId=' + client.id;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            // console.log(JSON.stringify(genericResponse.answerList));
            genericResponse.answerList.forEach(answer => {
              this.rows.push({policy: answer.policyId, relationship: 'Vinculado'})
            });
            this.rows = [...this.rows]
            // console.log(this.rows);
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas asociadas a los clientes',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
    });
  }

  getPoliciesByMachineryBreakdownRisk() {
    this.listClients.forEach(client => {
      const path = 'riesgo-rotura-maquinaria/listar-riesgos-rotura-maquinaria-por-cliente?clientId=' + client.id;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            // console.log(JSON.stringify(genericResponse.answerList));
            genericResponse.answerList.forEach(answer => {
              this.rows.push({policy: answer.policyId, relationship: 'Vinculado'})
            });
            this.rows = [...this.rows]
            // console.log(this.rows);
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas asociadas a los clientes',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
    });
  }

  getPoliciesByMaritimeTransportRisk() {
    this.listClients.forEach(client => {
      const path = 'riesgo-rotura-maquinaria/listar-riesgos-rotura-maquinaria-por-cliente?clientId=' + client.id;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            // console.log(JSON.stringify(genericResponse.answerList));
            genericResponse.answerList.forEach(answer => {
              this.rows.push({policy: answer.policyId, relationship: 'Vinculado'})
            });
            this.rows = [...this.rows]
            // console.log(this.rows);
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas asociadas a los clientes',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
    });
  }

  getPoliciesByMultiRisk() {
    this.listClients.forEach(client => {
      const path = 'riesgo-multi/listar-riesgos-multi-por-cliente?clientId=' + client.id;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            // console.log(JSON.stringify(genericResponse.answerList));
            genericResponse.answerList.forEach(answer => {
              this.rows.push({policy: answer.policyId, relationship: 'Vinculado'})
            });
            this.rows = [...this.rows]
            // console.log(this.rows);
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas asociadas a los clientes',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
    });
  }

  getPoliciesByPersonalAccidentRisk() {
    this.listClients.forEach(client => {
      const path = 'riesgo-accidente-personal/listar-riesgos-accidente-personal-por-cliente?clientId=' + client.id;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            // console.log(JSON.stringify(genericResponse.answerList));
            genericResponse.answerList.forEach(answer => {
              this.rows.push({policy: answer.policyId, relationship: 'Vinculado'})
            });
            this.rows = [...this.rows]
            // console.log(this.rows);
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas asociadas a los clientes',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
    });
  }

  getPoliciesByPymeRisk() {
    this.listClients.forEach(client => {
      const path = 'riesgo-pyme/listar-riesgos-pyme-por-cliente?clientId=' + client.id;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            // console.log(JSON.stringify(genericResponse.answerList));
            genericResponse.answerList.forEach(answer => {
              this.rows.push({policy: answer.policyId, relationship: 'Vinculado'})
            });
            this.rows = [...this.rows]
            // console.log(this.rows);
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas asociadas a los clientes',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
    });
  }

  getPoliciesByRCCVehicleRisk() {
    this.listClients.forEach(client => {
      const path = 'riesgo-rcc-vehiculo/listar-riesgos-rcc-vehiculo-por-cliente?clientId=' + client.id;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            // console.log(JSON.stringify(genericResponse.answerList));
            genericResponse.answerList.forEach(answer => {
              this.rows.push({policy: answer.policyId, relationship: 'Vinculado'})
            });
            this.rows = [...this.rows]
            // console.log(this.rows);
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas asociadas a los clientes',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
    });
  }

  getPoliciesByRCCCyberRisk() {
    this.listClients.forEach(client => {
      const path = 'riesgo-rc-cyber/listar-riesgos-rcc-cyber-por-cliente?clientId=' + client.id;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            // console.log(JSON.stringify(genericResponse.answerList));
            genericResponse.answerList.forEach(answer => {
              this.rows.push({policy: answer.policyId, relationship: 'Vinculado'})
            });
            this.rows = [...this.rows]
            // console.log(this.rows);
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas asociadas a los clientes',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
    });
  }

  getPoliciesByRCEVehicleRisk() {
    this.listClients.forEach(client => {
      const path = 'riesgo-rc-cyber/listar-riesgos-rcc-cyber-por-cliente?clientId=' + client.id;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            // console.log(JSON.stringify(genericResponse.answerList));
            genericResponse.answerList.forEach(answer => {
              this.rows.push({policy: answer.policyId, relationship: 'Vinculado'})
            });
            this.rows = [...this.rows]
            // console.log(this.rows);
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas asociadas a los clientes',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
    });
  }

  getPoliciesByRCProfesionalRisk() {
    this.listClients.forEach(client => {
      const path = 'riesgo-rc-profesional/listar-riesgos-rc-profesional-por-cliente/?clientId=' + client.id;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            // console.log(JSON.stringify(genericResponse.answerList));
            genericResponse.answerList.forEach(answer => {
              this.rows.push({policy: answer.policyId, relationship: 'Vinculado'})
            });
            this.rows = [...this.rows]
            // console.log(this.rows);
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas asociadas a los clientes',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
    });
  }

  getPoliciesBySOATRisk() {
    this.listClients.forEach(client => {
      const path = 'riesgo-soat/listar-riesgos-soat-por-cliente?clientId=' + client.id;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            // console.log('SOAT');
            // console.log(JSON.stringify(genericResponse.answerList));
            genericResponse.answerList.forEach(answer => {
              // console.log(answer.policyId);
              this.rows.push({policy: answer.policyId, relationship: 'Vinculado'})
            });
            this.rows = [...this.rows]
            // console.log(this.rows);
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas asociadas a los clientes',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
    });
  }

  getPoliciesByVehicleRisk() {
    console.log('ENTRO A RIESGO VEHICULO');
    this.listClients.forEach(client => {
      const path = 'riesgo-vehiculo/listar-riesgos-vehiculo-por-cliente?clientId=' + client.id;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            // console.log(JSON.stringify(genericResponse.answerList));
            genericResponse.answerList.forEach(answer => {
              this.rows.push({policy: answer.policyId, relationship: 'Vinculado'})
            });
            this.rows = [...this.rows]
            // console.log(this.rows);
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas asociadas a los clientes',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
    });
  
  }
}
