export class HomeRiskModel {
    id: string;
    policyId: any;
    clientId: any;
    state: string;
    constructionYear: string;
    location: string;
    stratum: string;
    departmentNumber: string;
    insuredValue: string;
    validSince: string;
    validUntil: string;
    classOfInsuranceId: any;
    creatorId: any;
    lastUpdateId: any;
    createdDate: string;
    lastUpdateDate: string;

    public constructor() {
        this.id = null;
        this.policyId = '';
        this.clientId = '';
        this.state = '';
        this.constructionYear = '';
        this.location = '';
        this.stratum = '';
        this.departmentNumber = '';
        this.insuredValue = '';
        this.validSince = '';
        this.validUntil = '';
        this.classOfInsuranceId = '';
        this.creatorId = '';
        this.lastUpdateId = '';
        this.createdDate = '';
        this.lastUpdateDate = '';
    }
}
