import { FirebaseStorageService } from './../../shared/firebase/firebase-storage.service';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { CompanyModel } from 'models/entities/company-model';
import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { UserModel } from 'models/entities/user-model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import swal from 'sweetalert2';
import { DocumentTypeModel } from 'models/entities/document-type-model';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { CompanyConfigsComponent } from '../company-configs/company-configs.component';



@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  currentFileUpload: File;
  selectedExtension = '';
  user: UserModel = new UserModel();
  company: CompanyModel = new CompanyModel();
  isSignedUp = false;
  isSignUpFailed = false;
  errorMessage = '';
  roleTitle = this.tokenStorage.getAuthorities();
  passwordValidationError = '';
  isErrorPassword = false;
  typesRestrictions = ['jpg', 'PNG', 'png', 'JPGE'];
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  passwordConfirmation = '';
  termsAndConditions = false;
  listDocumentTypes: Array<DocumentTypeModel> = new Array();
  listCompanies;

  @Input() public modalInformacion;

  @ViewChild('f') registerForm: NgForm;
  @ViewChild('ft') registerFormTwo: NgForm;

  constructor(
    private modalService: NgbModal,
    private firebaseStorageService: FirebaseStorageService,
    private messageService: InfoMessagesService,
    private crudService: CrudServiceService,
    private tokenStorage: TokenStorageService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.getDocumentsTypes();
    // debugger;
    if(this.roleTitle==='ROLE_SUPER')
    {
    this.getCompanies();
    }
  }


  getDocumentsTypes() {
    const pathListDOcumentTypesUrl = 'tipos-de-documento/ver-por-objetivo?objective=Personal';
    this.listDocumentTypes = new Array();
    this.crudService.getModel(pathListDOcumentTypesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listDocumentTypes = genericResponse.answerList;
          this.user.documentTypeId.id = this.listDocumentTypes[0].id;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los tipos de documentos del sistema',
            'Problema consultando los tipos de documentos del sistema');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los tipos de documentos del sistema ' + JSON.stringify(error))
      }
    );
  }

  selectFile(event) {
    if (this.typesRestrictions.includes(event.target.files.item(0).name.split('.').pop())) {
      this.currentFileUpload = event.target.files.item(0);
      this.selectedExtension = event.target.files.item(0).name.split('.').pop()
    } else {
      this.currentFileUpload = undefined;
      swal({
        title: 'El tipo no es correcto', type: 'error',
        text: 'El archivo debe tener formato jpg, PNG, png ó JPEG, jpeg'
      })
    }
  }

  passwordValidation() {
    if (this.user.password !== this.passwordConfirmation) {
      this.passwordValidationError = '¡Las Contraseñas No Coinciden!';
      this.isErrorPassword = true;
      setTimeout(() => { this.isErrorPassword = false; }, 3000);
    } else {
    }
  }

  onSubmit() {
    if (this.tokenStorage.getAuthorities() === 'ROLE_SUPER') {
      this.user.role = 'ROLE_ADMIN';
    }
    if(this.tokenStorage.getAuthorities() === 'ROLE_ADMIN' || this.tokenStorage.getAuthorities() === 'ROLE_USER' )
    {
      this.user.companyId.id=this.tokenStorage.getCompanyId();
    }

    this.crudService.createModel('usuarios/crear', this.user).subscribe(
      genericResponse => {
        if (genericResponse.code === 201) {
          this.isSignedUp = true;
          this.isSignUpFailed = false;
          this.user = new UserModel();
          this.messageService.getInfoMessageCreate();
          this.activeModal.dismiss();
          this.passEntry.emit('Usuario Registrado');
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el usuario')
          this.errorMessage = genericResponse.answer;
          this.isSignedUp = false;
          this.isSignUpFailed = true;
        }
      },
      error => {
        this.errorMessage = error.error.message;
        this.isSignUpFailed = true;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  routeDashboard(){
    
  }


  getCompanies() {

    const path = 'empresa/compania/listar-companias'
    this.crudService.getModel(path).subscribe(
      result => {

        if (result.code == 200) {
          console.log(result)
          this.listCompanies = result.answerList;
        }

      },
      error => {
        this.messageService.getInfoMessageError();
      }
    )
  }

  openCreateCompany() {
    const modal = this.modalService.open(CompanyConfigsComponent, { windowClass: 'dark-modal' });
    modal.componentInstance.modal = true;
    modal.componentInstance.activeModal = modal;
    modal.componentInstance.emit.subscribe(
      result => {
        this.getCompanies();
        this.user.companyId.id = result.id;

      }
    )
  }


  upload(company: CompanyModel) {
    this.firebaseStorageService.uploadFile(company.id + '/' + 'logos', 'logo.' + this.selectedExtension, this.currentFileUpload).then(
      (responseFirebase) => {
        this.firebaseStorageService.loadFile(company.id + '/' + 'logos', 'logo.' + this.selectedExtension).then(
          (dowloadURL) => {
            company.logoUrl = dowloadURL
            this.modalService.dismissAll();
            this.passEntry.emit('Usuario Registrado');
          }
        ).catch(error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        })
      }, (error) => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    )
  }
}


