import { Component, OnInit, SimpleChanges, Input } from '@angular/core';
import { ClientModel } from 'models/entities/client-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import swal from 'sweetalert2';
import { formatDate } from '@angular/common';
import { ExportExcelService } from 'app/shared/excel/export-excel.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { DocumentTypeModel } from 'models/entities/document-type-model';
import { ManageClientsComponent } from '../manage-clients/manage-clients.component';
import { PageDataModel } from 'models/utilities/page-data-model';
import { SearchClientModel } from 'models/types/search-client-model';
import { ModalInfo } from 'models/entities/modal-info';

@Component({
  selector: 'app-list-clients',
  templateUrl: './list-clients.component.html',
  styleUrls: ['./list-clients.component.scss']
})
export class ListClientsComponent implements OnInit {
  @Input() modelSearchClient2: SearchClientModel = new SearchClientModel();

  modalInforClientModel = new ModalInfo();

  columns = [];
  temp: Array<ClientModel> = new Array();
  listDocumentTipe: Array<DocumentTypeModel> = new Array();
  page: PageDataModel = new PageDataModel();

  isSearching = false;

  constructor(
    private modalService: NgbModal,
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private excelService: ExportExcelService,
    private tokenStorage: TokenStorageService
  ) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit() {
    this.getClients(0);
    this.setPage({ offset: 0 });
  }

  setPage(pageInfo) {
    if (!this.isSearching) {
      this.getClients(pageInfo.offset);
    } else {
      this.getSearchClient(pageInfo.offset);
    }
  }

  getClients(page) {
    this.modelSearchClient2 = new SearchClientModel();

    const pathlistActivityTypeUrl =
      'cliente/ver-por-empresa-paginado?page=' + page;
    this.crudService.getModel(pathlistActivityTypeUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.temp = genericResponse.answerList;
          this.page.totalElements = genericResponse.variable;
          this.page.pageNumber = page;
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  }

  getDocumentType() {
    const pathListDocumentType = '';
    this.listDocumentTipe = [];
    this.crudService.getModel(pathListDocumentType).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listDocumentTipe = genericResponse.answerList;
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  }

  create() {
    const modal = this.modalService.open(ManageClientsComponent, {
      windowClass: '',
      size: 'lg',
      backdrop: 'static'
    });
    this.modalInforClientModel.isCreate = true;
    this.modalInforClientModel.isUpdate = false;
    this.modalInforClientModel.isView = false;
    modal.componentInstance.modelInfoClient = this.modalInforClientModel;
    modal.componentInstance.passEntry.subscribe(retorno => {
      if (retorno === 'ok') {
        this.getClients(0);
      } else if (retorno === 'close') {
        modal.dismiss();
      }
    });
  }

  edit(clientAux) {
    const modal = this.modalService.open(ManageClientsComponent, {
      windowClass: '',
      size: 'lg',
      backdrop: 'static'
    });

    if (clientAux.birthDate !== null) {
      const array = clientAux.birthDate.split('+');
      const date = formatDate(new Date(array[0]), 'yyyy-MM-dd', 'en');
      clientAux.birthDate = date;
    }

    modal.componentInstance.clientModel = { ...clientAux };
    this.modalInforClientModel.isCreate = false;
    this.modalInforClientModel.isUpdate = true;
    this.modalInforClientModel.isView = false;
    modal.componentInstance.modelInfoClient = this.modalInforClientModel;
    modal.componentInstance.passEntry.subscribe(update => {
      if (update === 'ok') {
        this.getClients(0);
      } else if (update === 'close') {
        modal.dismiss();
      }
    });
  }

  delete(id) {
    swal({
      title: '¿Está seguro que desea eliminar este Cliente?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then(result => {
      if (result.value) {
        this.deleteClient(id);
      }
    });
  }

  private deleteClient(id) {
    const pathDeleteClient = 'cliente/eliminar?id=' + id;

    this.crudService.deleteModel(pathDeleteClient).subscribe(
      (data: GenericResponseModel) => {
        if (data.code === 200) {
          this.messageService.getInfoMessageDelete();
          this.getClients(0);
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized(
          'warning',
          error.error.answer + '',
          'Problema con la eliminación'
        );
        console.error('EL error es: ' + JSON.stringify(error));
      }
    );
  }

  lookClient(clientAux) {
    const modal = this.modalService.open(ManageClientsComponent, {
      windowClass: '',
      size: 'lg',
      backdrop: 'static'
    });

    if (clientAux.birthDate !== null) {
      const array = clientAux.birthDate.split('+');
      const date = formatDate(new Date(array[0]), 'yyyy-MM-dd', 'en');
      clientAux.birthDate = date;
    }

    this.modalInforClientModel.isCreate = false;
    this.modalInforClientModel.isUpdate = false;
    this.modalInforClientModel.isView = true;
    modal.componentInstance.modelInfoClient = this.modalInforClientModel;
    modal.componentInstance.clientModel = { ...clientAux };
    modal.componentInstance.passEntry.subscribe(update => {
      if (update === 'ok') {
        this.getClients(0);
      } else if (update === 'close') {
        modal.dismiss();
      }
    });
  }

  getSearchClient(page) {
    const pathSearchClient =
      'cliente/ver-por-empresa-filtro?clientName=' +
      this.modelSearchClient2.clientName +
      '&documentNumberClient=' +
      this.modelSearchClient2.documentNumberClient +
      '&sellerName=' +
      this.modelSearchClient2.sellerName +
      '&page=' + page;

    this.crudService.getModel(pathSearchClient).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          if (genericResponse.answerList <= 0) {
            this.messageService.getInfoMessagePersonalized(
              'warning',
              '',
              'No hay coincidencias con la búsqueda'
            );
          } else {
            this.temp = genericResponse.answerList;
            this.page.totalElements = genericResponse.variable;
            this.page.pageNumber = page;

            this.temp = genericResponse.answerList;
          }
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  }

  exportAsXLSX(): void {
    let pathlistClients = '';
    if (
      this.modelSearchClient2.clientName === '' &&
      this.modelSearchClient2.documentNumberClient === '' &&
      this.modelSearchClient2.sellerName === ''
    ) {
      pathlistClients = 'cliente/ver-por-empresa';
    } else {
      pathlistClients =
        'cliente/ver-por-empresa-filtro?clientName=' +
        this.modelSearchClient2.clientName +
        '&documentNumberClient=' +
        this.modelSearchClient2.documentNumberClient +
        '&sellerName=' +
        this.modelSearchClient2.sellerName;
    }

    this.crudService.getModel(pathlistClients).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.temp = genericResponse.answerList;
          //  recorrer tem  y construir una lista json con cada cliente
          // y asignar el nombre de esa columna
          const clientsAux: any = [];
          this.temp.forEach(client => {
            clientsAux.push({
              'Nombre/Razón Social': client.nameOrBusinessName,
              'Vendedor': client.sellerUserId.completeName,
              'Número de Documento': client.documentNumber,
              'Teléfono': client.phone,
              'Celular': client.cellphone,
              'Email': client.email,
              'Estado': client.state,
            });
          });
          this.excelService.exportAsExcelFile(clientsAux, 'Clientes' + this.tokenStorage.getCompanyName());
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  }

}
