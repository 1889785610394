import { Component, OnInit, OnChanges, Input, ViewChild, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { CivilLiabilityHydrocarbonsShipmentRiskModel } from 'models/entities/civil-liability-hydrocarbons-shipment-risk.model';
import { NgForm } from '@angular/forms';
import { PolicyModel } from 'models/entities/policy-model';
import { ClientModel } from 'models/entities/client-model';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import {Subject, Observable, Subscription} from 'rxjs';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { formatDate } from '@angular/common';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import * as XLSX from 'ts-xlsx';
import {SharedService} from "../../shared/shared.service";

@Component({
  selector: 'app-civil-liability-hydrocarbons-shipment-risk-cru',
  templateUrl: './civil-liability-hydrocarbons-shipment-risk-cru.component.html',
  styleUrls: ['./civil-liability-hydrocarbons-shipment-risk-cru.component.scss']
})
export class CivilLiabilityHydrocarbonsShipmentRiskCruComponent implements OnInit, OnChanges {
  @Input() policyId = 1;
  @ViewChild('f') registerForm: NgForm;

  @Input() civilLiabilityHydrocarbonsShipmentRiskModel = new CivilLiabilityHydrocarbonsShipmentRiskModel();
  @Input() policyModel = new PolicyModel();

  flagSuccessfulUpdate: any;

  @Output() emitterSuccessfulUpdate: EventEmitter<boolean>;
  @Output() emitterActivityRecord: EventEmitter<any>;

  today = '';
  listClients: Array<ClientModel> = new Array();
  @ViewChild('instanceClient') instanceClient: NgbTypeahead;
  clickClient$ = new Subject<string>();
  focusClient$ = new Subject<string>();

  arrayBuffer: any;
  file: File;
  excelFile = new Array();
  fileSelected = false;
  fileName = 'Elegir archivo';
  idClient: any;

  riskInfoSubscription: Subscription;

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService,
    private sharedService: SharedService
  ) {
    this.emitterSuccessfulUpdate = new EventEmitter();
    this.emitterActivityRecord = new EventEmitter();
    this.today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  }

  ngOnInit() {
    this.civilLiabilityHydrocarbonsShipmentRiskModel.policyId = { id: this.policyModel.id };
    this.getClients();
    this.riskInfoSubscription = this.sharedService.getRiskInfo().subscribe(riskInfo => {
      const { client, validUntil, validSince } = riskInfo;
      this.civilLiabilityHydrocarbonsShipmentRiskModel.clientId = client;
      this.civilLiabilityHydrocarbonsShipmentRiskModel.validSince = validSince;
      this.civilLiabilityHydrocarbonsShipmentRiskModel.validUntil = validUntil;
    });
  }

  ngOnDestroy() {
    if (this.riskInfoSubscription) {
      this.riskInfoSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getClients();
  }

  onSubmit(form: NgForm) {
    const path = 'riesgo-responsabilidad-civil-hidrocarburos-carga/crear-riesgo-responsabilidad-civil-hidrocarburos-carga';
    const date = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.civilLiabilityHydrocarbonsShipmentRiskModel.state = 'INCLUIDO';
    this.civilLiabilityHydrocarbonsShipmentRiskModel.policyId = { id: this.policyModel.id };
    this.civilLiabilityHydrocarbonsShipmentRiskModel.classOfInsuranceId = { id: this.policyModel.classOfInsuranceId.id };
    this.civilLiabilityHydrocarbonsShipmentRiskModel.creatorId = { id: this.tokenStorage.getId() };
    this.civilLiabilityHydrocarbonsShipmentRiskModel.lastUpdateId = { id: this.tokenStorage.getId() };
    this.civilLiabilityHydrocarbonsShipmentRiskModel.lastUpdateDate = date;
    this.civilLiabilityHydrocarbonsShipmentRiskModel.createdDate = date;

    this.crudService.createModel(path, this.civilLiabilityHydrocarbonsShipmentRiskModel).subscribe(
      (data: GenericResponseModel) => {
        if (data.code === 200) {
          this.messageService.getInfoMessageCreate();
          if (this.civilLiabilityHydrocarbonsShipmentRiskModel.id === null) {
            this.sendActivityRecord('Se creó un riesgo con No. Registro ' + data.genericObject.id);
          } else {
            this.sendActivityRecord('Se modificó un riesgo con No. Registro ' + this.civilLiabilityHydrocarbonsShipmentRiskModel.id);
          }
          this.civilLiabilityHydrocarbonsShipmentRiskModel = new CivilLiabilityHydrocarbonsShipmentRiskModel();
          this.rebuildClient();
          form.form.markAsPristine();
          form.form.markAsUntouched();
          form.form.updateValueAndValidity();
          this.successfulUpdate(new Date().getSeconds());
        } else if (data.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', data.answer, 'No se creó el riesgo')
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized('error', error.error.answer + '', 'Error');
        console.error('El error es ', JSON.stringify(error));
      }
    );
  }

  sendActivityRecord(activity: any) {
    this.emitterActivityRecord.emit(activity);
  }

  getClients() {
    const pathListClientsUrl = 'cliente/listar-nombre-por-empresa';
    this.listClients = new Array();
    this.crudService.getModel(pathListClientsUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listClientsAux = genericResponse.answerList;
          listClientsAux.forEach(client => {
            this.listClients.push(client);
          });
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los clientes asociados a su empresa',
            'Problema consultando los clientes asociados a su empresa');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los clientes asociados a su empresa ' + JSON.stringify(error))
      }
    );
  }

  allIsRight() {
    return this.containsValidation(this.civilLiabilityHydrocarbonsShipmentRiskModel.clientId, this.listClients)
  }

  searchByNameClient = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    merge(this.focusClient$),
    merge(this.clickClient$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
    map(search => (search === '' ? this.listClients
      : this.listClients.filter(client => client.nameOrBusinessName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
  );

  formatterClient = (object: { nameOrBusinessName: string }) => object.nameOrBusinessName;

  rebuildClient() {
    this.policyModel.sellerAndInsuredEquals = false;
    this.policyModel.clientId = new ClientModel();
    this.policyModel.insuredId = new ClientModel();
  }

  containsValidation(objectToValidate, listToSearch: Array<any>) {
    for (const object of listToSearch) {
      if (object.id === objectToValidate.id) {
        return true;
      }
    }
  }

  successfulUpdate(value: any) {
    this.flagSuccessfulUpdate = value;
    this.emitterSuccessfulUpdate.emit(this.flagSuccessfulUpdate);
  }

  incomingfile(event) {
    this.file = event.target.files[0];
    this.fileSelected = true;
    this.fileName = this.file.name;
  }

  Upload() {
    const fileReader = new FileReader();
      fileReader.onload = (e) => {
        // Lectura de archivo de excel
        this.arrayBuffer = fileReader.result;
        const data = new Uint8Array(this.arrayBuffer);
        const arr = new Array();
        for (let i = 0; i !== data.length; ++i) { arr[i] = String.fromCharCode(data[i]); }
        const bstr = arr.join('');
        const workbook = XLSX.read(bstr, {type: 'binary'});
        const first_sheet_name = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[first_sheet_name];
        this.excelFile.push(XLSX.utils.sheet_to_json(worksheet, {raw: true}));
        let index = 0;
        let errors = [];

        if (this.excelFile[0].length > 0) {
          // Se revisa cada fila de la hoja de datos
          this.excelFile[0].forEach(element => {
            // Se revisa cada celda de la fila y se validan que tengan valores
            let idClient = '-1';

            // Se buscan el id del tipo documento y el numero de documento
            this.listClients.forEach(element => {
              if (element.documentTypeId.id.toString() === this.excelFile[0][index].idTipoDocumento.toString()
                  && element.documentNumber === this.excelFile[0][index].numeroDocumento.toString()) {
                  idClient = element.id;
              }
            });

          // Si el cliente existe en la lista de clientes cargada
          if (idClient !== '-1') {
            if (this.excelFile[0][index].nombreEmbarcacion !== undefined) {
             if (this.excelFile[0][index].validoDesde !== undefined) {
               if (this.excelFile[0][index].validoHasta !== undefined) {
                 if (this.excelFile[0][index].numeroPatente !== undefined) {
                   if (this.excelFile[0][index].tipo !== undefined) {
                    if (this.excelFile[0][index].servicio !== undefined) {
                      if (this.excelFile[0][index].propietario !== undefined) {
                          if (this.excelFile[0][index].di !== undefined) {
                            if (this.excelFile[0][index].zonaOperacion !== undefined) {
                              if (this.excelFile[0][index].capacidadPasajeros !== undefined) {
                                //  console.log(this.excelFile[0][index]);
                                const date = formatDate(new Date(), 'yyyy-MM-dd', 'en');
                                this.civilLiabilityHydrocarbonsShipmentRiskModel.createdDate = date;
                                this.civilLiabilityHydrocarbonsShipmentRiskModel.lastUpdateDate = date;
                                this.civilLiabilityHydrocarbonsShipmentRiskModel.state = 'INCLUIDO';
                                // tslint:disable-next-line:max-line-length
                                this.civilLiabilityHydrocarbonsShipmentRiskModel.validSince = formatDate(new Date((this.excelFile[0][index].validoDesde -
                                (25567 + 1)) * 86400 * 1000), 'yyyy-MM-dd', 'en');
                                // tslint:disable-next-line:max-line-length
                                this.civilLiabilityHydrocarbonsShipmentRiskModel.validUntil = formatDate(new Date((this.excelFile[0][index].validoHasta -
                                  (25567 + 1)) * 86400 * 1000), 'yyyy-MM-dd', 'en');
                                // tslint:disable-next-line:max-line-length
                                this.civilLiabilityHydrocarbonsShipmentRiskModel.classOfInsuranceId = { id: this.policyModel.classOfInsuranceId.id }
                                this.civilLiabilityHydrocarbonsShipmentRiskModel.clientId = { id: idClient};
                                this.civilLiabilityHydrocarbonsShipmentRiskModel.creatorId = { id: this.tokenStorage.getId() };
                                this.civilLiabilityHydrocarbonsShipmentRiskModel.lastUpdateId = { id: this.tokenStorage.getId() };
                                this.civilLiabilityHydrocarbonsShipmentRiskModel.policyId = { id: this.policyModel.id };
                                this.civilLiabilityHydrocarbonsShipmentRiskModel.boatName = this.excelFile[0][index].nombreEmbarcacion;
                                this.civilLiabilityHydrocarbonsShipmentRiskModel.patentNumber = this.excelFile[0][index].numeroPatente;
                                this.civilLiabilityHydrocarbonsShipmentRiskModel.type = this.excelFile[0][index].tipo;
                                this.civilLiabilityHydrocarbonsShipmentRiskModel.service = this.excelFile[0][index].servicio;
                                this.civilLiabilityHydrocarbonsShipmentRiskModel.owner = this.excelFile[0][index].propietario;
                                this.civilLiabilityHydrocarbonsShipmentRiskModel.di = this.excelFile[0][index].di;
                                this.civilLiabilityHydrocarbonsShipmentRiskModel.operatingZone = this.excelFile[0][index].zonaOperacion;
                                // tslint:disable-next-line:max-line-length
                                this.civilLiabilityHydrocarbonsShipmentRiskModel.passengerCapacity = this.excelFile[0][index].capacidadPasajeros;
                                //  console.log(JSON.stringify(this.civilLiabilityHydrocarbonsShipmentRiskModel));
                                this.uploadRiskMassively(this.civilLiabilityHydrocarbonsShipmentRiskModel);
                                this.civilLiabilityHydrocarbonsShipmentRiskModel = new CivilLiabilityHydrocarbonsShipmentRiskModel();
                                this.fileSelected = false;
                              } else {
                                console.error(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                                errors.push(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                                this.fileSelected = false;
                              }
                            } else {
                              console.error(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                              errors.push(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                              this.fileSelected = false;
                            }
                          } else {
                            console.error(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                            errors.push(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                            this.fileSelected = false;
                          }
                        } else {
                          console.error(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                          errors.push(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                          this.fileSelected = false;
                        }
                      } else {
                        console.error(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                        errors.push(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                        this.fileSelected = false;
                      }
                    } else {
                     console.error(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                     errors.push(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                     this.fileSelected = false;
                   }
                 } else {
                   console.error(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                   errors.push(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                   this.fileSelected = false;
                 }
               } else {
                 console.error(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
               errors.push(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
                 this.fileSelected = false;
               }
             } else {
               console.error(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
               errors.push(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
               this.fileSelected = false;
             }
           } else {
             console.error(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
             errors.push(' El registro de la fila ' + (index + 1) + ' no se pudo insertar.');
               this.fileSelected = false;
           }
          } else {
           errors.push( ' El cliente no existe en la fila ' + (index + 2));
               this.fileSelected = false;
          }
            if (errors.length > 0) {
              this.messageService.getInfoMessagePersonalized('error',
               errors, 'Se encontraron los siguientes errores');
            }
            index++
            }
          );
        } else {
          console.error('El archivo no tiene registros');
          this.messageService.getInfoMessagePersonalized('error',
            'El archivo no tiene registros', 'Error');
            this.fileSelected = false;
            this.file = null;
            // this.fileName = 'Elegir archivo';
        }
      }
      fileReader.readAsArrayBuffer(this.file);
  }

  uploadRiskMassively(civilLiabilityHydrocarbonsShipmentRiskModel: CivilLiabilityHydrocarbonsShipmentRiskModel) {
    const path = 'riesgo-responsabilidad-civil-hidrocarburos-carga/crear-riesgo-responsabilidad-civil-hidrocarburos-carga';
    this.crudService.createModel(path, civilLiabilityHydrocarbonsShipmentRiskModel).subscribe(
      (data: GenericResponseModel) => {
        if (data.code === 200) {
          // this.messageService.getInfoMessageCreate();
          if (civilLiabilityHydrocarbonsShipmentRiskModel.id === null) {
            this.sendActivityRecord('Se creó un riesgo con No. Registro ' + data.genericObject.id);
          } else {
            this.sendActivityRecord('Se modificó un riesgo con No. Registro ' + civilLiabilityHydrocarbonsShipmentRiskModel.id);
          }
          civilLiabilityHydrocarbonsShipmentRiskModel = new CivilLiabilityHydrocarbonsShipmentRiskModel();
          this.rebuildClient();
          this.successfulUpdate(new Date().getSeconds());
        } else if (data.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', data.answer, 'No se creó el riesgo')
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized('error', error.error.answer + '', 'Error');
        console.error('El error es ', JSON.stringify(error));
    });
  }

  downloadFile() {
    const link = document.createElement('a');
    link.download = 'civil-liability-hydrocarbons-shipment-risk-template';
    link.href = 'assets/risks-templates/civil-liability-hydrocarbons-shipment-risk-template.xlsx';
    link.click();
  }
}
