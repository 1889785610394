import {CompanyModel} from "./company-model";

export class PayStyleModel {
    id: string;
    style: string;
    numberOfInstallments: any;
    createdDate: string;
    lastUpdateDate: string;
    companyId: CompanyModel

    public constructor() {
        this.id = '';
        this.style = '';
        this.numberOfInstallments = '';
        this.createdDate = '';
        this.lastUpdateDate = '';
        this.companyId = null;
    }
}
