import { ActivitiesModule } from './../activities/activities.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientsRoutingModule } from './clients-routing.module';
import { SearchingClientsComponent } from './searching-clients/searching-clients.component';
import { ListClientsComponent } from './list-clients/list-clients.component';
import { PrincipalViewComponent } from './principal-view/principal-view.component';
import { ManageClientsComponent } from './manage-clients/manage-clients.component';
import { CruClientComponent } from './cru-client/cru-client.component';
import { ArchwizardModule } from 'angular-archwizard';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbTypeaheadModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedComponentsModule } from 'app/shared/components/shared-components.module';
import { PoliciesModule } from 'app/policies/policies.module';


@NgModule({
  imports: [
    NgbModule.forRoot(),
    CommonModule,
    ClientsRoutingModule,
    ArchwizardModule,
    ReactiveFormsModule,
    FormsModule,
    NgxDatatableModule,
    NgbTypeaheadModule,
    SharedComponentsModule,
    PoliciesModule,
    ActivitiesModule,
  ],
  declarations: [
    SearchingClientsComponent,
    ListClientsComponent,
    PrincipalViewComponent,
    ManageClientsComponent,
    CruClientComponent
  ],
  exports: [
    ListClientsComponent,
    CruClientComponent,
    ManageClientsComponent
  ],
  entryComponents: [
    CruClientComponent,
    ManageClientsComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ClientsModule { }
