import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { ModalInformationModel } from 'models/utilities/modal-information-model';
import { NgForm } from '@angular/forms';
import { AttachedInfoModel } from 'models/entities/attached-info-model';
import { RelationalFileModel } from 'models/utilities/relational-type-model';
import { BudgetModel } from 'models/entities/budget-model';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { PolicyModel } from 'models/entities/policy-model';
import { BudgetInPolicyComponent } from '../budget-in-policy/budget-in-policy.component';
import { id } from '@swimlane/ngx-datatable/release/utils';

@Component({
  selector: 'app-cru-attached',
  templateUrl: './cru-attached.component.html',
  styleUrls: ['./cru-attached.component.scss']
})
export class CruAttachedComponent implements OnInit {

  public relationalFileModel: RelationalFileModel = new RelationalFileModel();
  @Output() public passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('formAttached') attachedForm: NgForm;
  @ViewChild(BudgetInPolicyComponent) instanceBudgetInPolicy: BudgetInPolicyComponent;
  @Input() public attachedModel: AttachedInfoModel = new AttachedInfoModel();
  public lastAttached: AttachedInfoModel = new AttachedInfoModel();
  @Input() public budgetModel: BudgetModel = new BudgetModel();
  @Input() public policyModel: PolicyModel = new PolicyModel();
  @Input() public readonly = false;
  @Input() public fromList = false;
  canAddDocument = false;
  @Input() changesList = new Array();
  minRestriction: any = -1;

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorageService: TokenStorageService
  ) { }

  ngOnInit() {
    this.relationalFileModel.showTitle = false;
    this.relationalFileModel.maxNumberOfFiles = 1
    this.relationalFileModel.relationalId = this.attachedModel.id;
    this.relationalFileModel.relationalType = 'DOCUMENTS_ATTACHED_FILE'
    this.relationalFileModel.relationalObjetive = 'Anexo'
    this.relationalFileModel.relationalFileType = 'Archivo';
    this.relationalFileModel.relationalRestrictions = new Array();
    this.consultLastAttachedByPolicy();
  }

  allIsRight() {
    if (this.instanceBudgetInPolicy !== undefined) {
      return this.instanceBudgetInPolicy.containsValidation() && this.budgetModel.totalWithIva !== '0';
    } else if (!this.attachedModel.valuable) {
      return true;
    }
  }

  consultLastAttachedByPolicy() {
    const pathLastAttachedUrl = 'anexo/ver-ultimo-anexo-por-poliza?policyId=' + this.policyModel.id;
    this.crudService.getModel(pathLastAttachedUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          if (genericResponse.genericObject === null) {
            this.attachedModel.consecutive = 0;
            this.minRestriction = -1;
          } else {
            if (!this.readonly) {
              this.lastAttached = genericResponse.genericObject;
              this.attachedModel.consecutive = Number(this.lastAttached.consecutive) + 1;
              this.minRestriction = Number(this.lastAttached.consecutive);
            }
          }
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudó obtener el último anexo de la poliza',
            'Anexo no encontrado');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al crear el presupuesto: ' + JSON.stringify(error))
      }
    );
  }

  closeModal() {
    if (!this.canAddDocument) {
      this.passEntry.emit(null);
    } else {
      this.passEntry.emit(1);
    }
  }

  onSubmit() {
    if (this.attachedModel.valuable) {
      if (Number(this.attachedModel.consecutive) > this.minRestriction) {
        this.createBudget().then(
          () => {
            this.createAttached().then(
              () => {
                if (this.attachedModel.type === 'CREATE') {
                  // this.policyModel.state = 'CURRENT';
                  // this.crudService.createModel('poliza/crear-poliza', this.policyModel).subscribe(
                  //   (renovatePolicyResponse: GenericResponseModel) => {
                  //     if (renovatePolicyResponse.code === 200) {
                        this.messageService.getInfoMessagePersonalized('success', 'El proceso ha sido exitoso',
                          'Póliza creada en estado expedida');
                  //     } else {
                  //       this.messageService.getInfoMessagePersonalized('warning', 'No se pudo renovar la póliza con éxito',
                  //         'Problema renovando la poliza');
                  //     }
                  //   }, (error) => {
                  //     this.messageService.getInfoMessageError();
                  //     console.error('Error, no se pudo actualizar la póliza con éxito: ' + JSON.stringify(error))
                  //   }
                  // )
                } else {
                  this.messageService.getInfoMessagePersonalized('success', 'El proceso ha sido exitoso',
                    'Anexo creado con éxito');
                }
                this.canAddDocument = true;
              }
            ).catch(
              (error) => {
                console.error('Error creando anexo', error);
              }
            )
          }
        ).catch(
          (error) => {
            console.error('Error creando presupuesto', error);
          }
        )
      } else {
        this.messageService.getInfoMessagePersonalized('warning', 'EL consecutivo debe ser como mínimo: '
          + (Number(this.lastAttached.consecutive) + 1), 'Problema en el consecutivo');
        this.attachedModel.consecutive = Number(this.lastAttached.consecutive) + 1;
      }
    } else {
      this.createAttached().then(
        () => {
          if (this.attachedModel.type === 'CREATE') {
            this.messageService.getInfoMessagePersonalized('success', 'El proceso ha sido exitoso',
              'Póliza creada en estado expedida');
          } else {
            this.messageService.getInfoMessagePersonalized('success', 'El proceso ha sido exitoso',
              'Anexo creado con éxito');
          }
          this.canAddDocument = true;
        }
      ).catch(
        (error) => {
          console.error('Error creando anexo', error);
        }
      )
    }
  }

  createBudget(): Promise<BudgetModel> {
    return new Promise((resolve, reject) => {
      this.budgetModel.creatorId.id = this.tokenStorageService.getId();
      this.budgetModel.createdDate = new Date().toUTCString();
      const pathCreateBudgetUrl = 'presupuesto/crear-presupuesto';
      this.crudService.createModel(pathCreateBudgetUrl, this.budgetModel).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.budgetModel = genericResponse.genericObject;
            resolve();
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudo crear el presupuesto',
              'Problema creando presupuesto');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al crear el presupuesto: ' + JSON.stringify(error))
          reject(error);
        }
      );
    })
  }

  createAttached(): Promise<AttachedInfoModel> {
    return new Promise((resolve, reject) => {
      this.attachedModel.creatorId.id = this.tokenStorageService.getId();
      this.attachedModel.createdDate = new Date().toUTCString();
      this.attachedModel.policyId.id = this.policyModel.id;

      this.attachedModel.descriptionOfChanges = '';
      this.changesList.forEach(change => {
        this.attachedModel.descriptionOfChanges = change + '/'
      });
      this.attachedModel.descriptionOfChanges =
        this.attachedModel.descriptionOfChanges.substring(0, this.attachedModel.descriptionOfChanges.length - 1);

      let pathCreateAttachedUrl = '';
      if (!this.attachedModel.valuable) {
        this.attachedModel.budgetId = null;
        pathCreateAttachedUrl = 'anexo/crear-o-actualizar-anexo-no-valorable';
      } else {
        this.attachedModel.budgetId = this.budgetModel;
        pathCreateAttachedUrl = 'anexo/crear-o-actualizar-anexo-valorable';
      }

      console.log(this.attachedModel);
      this.crudService.createModel(pathCreateAttachedUrl, this.attachedModel).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.attachedModel = genericResponse.genericObject;
            this.relationalFileModel.relationalId = this.attachedModel.id;
            resolve();
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudo crear el anexo',
              'Problema creando anexo');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al crear el anexo: ' + JSON.stringify(error))
        }
      );
    })
  }

}
