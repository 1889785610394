import { ExportExcelService } from './../../shared/excel/export-excel.service';
import { ClientModel } from 'models/entities/client-model';
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { ActivityModel } from 'models/entities/activity';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { SearchClientModel } from 'models/types/search-client-model';
import { Observable, Subject, Observer} from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ELEMENT_MARKER } from '@angular/core/src/render3/interfaces/i18n';
import { PdfService } from 'app/shared/pdf/pdf.service';
import { SharedService } from 'app/shared/shared.service';
import { UserModel } from 'models/entities/user-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateActivityComponent } from '../create-activity/create-activity.component';



@Component({
  selector: 'app-managment-activity',
  templateUrl: './managment-activity.component.html',
  styleUrls: ['./managment-activity.component.scss']
})
export class ManagmentActivityComponent implements OnInit {

  public canShowLoading = false;
  public listActivitys: Array<ActivityModel> = new Array();
  public client=new ClientModel();
  public filter={startDate:new Date(),finalDate:new Date(),responsable:new UserModel(),status:'',client:'',companyId:this.tokenServiceCtrl.getCompanyId()};
  public listTypes: any [];
  public listStates: any [];
  public listUsers: any []; 
  dataLoad=false;
  listSeacrhClient: any;
  listClients:any;
  temp: any;
  justRead = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('instanceClient') instanceClient: NgbTypeahead;
  modelSearchClient: SearchClientModel = new SearchClientModel();
  focusClient$ = new Subject<string>();
  clickClient$ = new Subject<string>();
  focusClientIn$ = new Subject<string>();
  clickClientIn$ = new Subject<string>();
  logo:any
  @ViewChild('contentToConvert') contentDiv: ElementRef;
  focusUser$ = new Subject<string>();
  clickUser$ = new Subject<string>();
  focusUserIn$ = new Subject<string>();
  @ViewChild('instanceUser') instanceUser: NgbTypeahead;
  print:boolean=false;

  constructor(  private messageCtrl:InfoMessagesService,
    private serviceCtrl:CrudServiceService,
    private tokenServiceCtrl:TokenStorageService,
    private excelService: ExportExcelService,
    private modalService:NgbModal,
    private pdfService: PdfService,
    private sharedService:SharedService) { }

  ngOnInit() {
    
   
    this.getActivitys();
    this.getStatusActivity();
    this.getUsers();
    this.getClients();

    setTimeout(() => {
      this.imageLoad();
    }, 3000);
  }


  imageLoad(){
    this.getBase64ImageFromURL(this.sharedService.getLogoUrlCompany()).subscribe(base64data => {     
      // this is the image as dataUrl
     this.logo = 'data:image/jpg;base64,' + base64data;
     
     
    });
  }

  formatterUser = (object: { completeName: string }) => object.completeName



  searchByNameUser = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    merge(this.focusUserIn$),
    merge(this.clickUser$.pipe(filter(() => !this.instanceUser.isPopupOpen()))),
    map(search => (search === '' ? this.listUsers
      : this.listUsers.filter(user => user.completeName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
  );

  getBase64ImageFromURL(url: string) {

    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
          // This will call another method that will create image from url
          img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
           observer.error(err);
        };
      } else {
          observer.next(this.getBase64Image(img));
          observer.complete();
      }
    });
 }


 getBase64Image(img: HTMLImageElement) {
  // We create a HTML canvas object that will create a 2d image

  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  var ctx = canvas.getContext("2d");
  // This will draw image    
  ctx.drawImage(img, 0, 0);
  // Convert the drawn image to Data URL
  var dataURL = canvas.toDataURL("image/png");
return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}


  captureScreen() {
    this.justRead = true;
    setTimeout(() => {
      const dataToConvert = this.contentDiv.nativeElement;
      this.pdfService.captureScreen(dataToConvert, 295, 'landscape').then(
        () => {
          this.passEntry.emit(1);
        }
      )
    }, 2000);
  }

  submitForm() {
    if (  this.modelSearchClient.clientName === "" &&
          this.modelSearchClient.documentNumberClient === "" &&
          this.modelSearchClient.sellerName === "" ) {
          this.messageCtrl.getInfoMessagePersonalized(
          "warning",
          "",
          "Los Filtros Están Vacíos "
      );
    } else {
      
      this.getSearchClient();
    }
  }

  validateFilter()
  {
    if(this.filter.client==='' || this.filter.responsable.id==='' || this.filter.status==='')
    {
      return true
    }
    else{
      return false;
    }
  }


  getSearchClient() {
   
    const pathSearchClient ="cliente/ver-por-empresa-filtro?clientName=" + this.modelSearchClient.clientName +
    "&documentoNumberClient=" + this.modelSearchClient.documentNumberClient + "&sellerName=" + this.modelSearchClient.sellerName;
      
    this.serviceCtrl.getModel(pathSearchClient).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listSeacrhClient = genericResponse.answerList;
                if (this.listSeacrhClient.length <= 0) {
            this.messageCtrl.getInfoMessagePersonalized(
              "warning",
              "",
              "No hay coincidencias con la búsqueda"
            );
          } else {
            this.temp = this.listSeacrhClient;
          }
        } else {
          this.messageCtrl.getInfoMessageError();
        }
      },
      error => {
        this.messageCtrl.getInfoMessageError();
        console.error("EL ERROR ES: " + JSON.stringify(error));
      }
    );
}

  getStatusActivity(){
    let path='empresa/estado-actividad/listar-estado-actividad-no-finalizadora';
      this.serviceCtrl.getModel(path).subscribe(
        result=>{
        
          if(result.code===200)
          {
            this.listStates=result.answerList        
          }
        },
        error=>{
         this.messageCtrl.getInfoMessageDelete();
        }
      )
    }

    exportExcel(){
      this.excelService.exportAsExcelFile(this.listActivitys,'Actividades' + this.tokenServiceCtrl.getCompanyName());

    }

    getClients() {
      const pathListClientsUrl = 'cliente/ver-por-empresa';
      this.listClients = new Array();
      this.serviceCtrl.getModel(pathListClientsUrl).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            const listClientsAux = genericResponse.answerList;
            listClientsAux.forEach(client => {
              this.listClients.push(client);
            });
          } else {
            this.messageCtrl.getInfoMessagePersonalized('warning', 'No se pudieron listar los clientes asociados a su empresa',
              'Problema consultando los clientes asociados a su empresa');
          }
        },
        error => {
          this.messageCtrl.getInfoMessageError();
          console.error('Error al cargar los clientes asociados a su empresa ' + JSON.stringify(error))
        }
      );
    }

    formatterClient = (object: { nameOrBusinessName: string }) => object.nameOrBusinessName;



    searchByNameClient = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusClientIn$),
      merge(this.clickClient$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
      map(search => (search === '' ? this.listClients
        : this.listClients.filter(client => client.nameOrBusinessName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
    );

    getUsers(){
      let path='usuarios/listar-vendedores-y-gestores';
      this.serviceCtrl.getModel(path).subscribe(
        result=>{
          if(result.code===200)
          {
             this.listUsers=result.answerList;
          }
        },
        error=>{
         this.messageCtrl.getInfoMessageDelete();
        }
      )
    }

  newActivity()
  {
    const modal = this.modalService.open(CreateActivityComponent, {
      windowClass: '',
      size: 'lg',
      backdrop: 'static'
    });
    
    modal.componentInstance.isModal=true;
   
    modal.componentInstance.passEntry.subscribe(result=>
      {
        if(result==='close'){      
      
         this.getActivitys();            
          modal.dismiss();
        }
      })
  }



  filterActivitys(){
    
    let path='actividad/filtrar';
    console.log(this.filter);
    this.serviceCtrl.createModel(path,this.filter).subscribe(
      result=>{
       console.log(result);
        if(result.code===200)
        {
           this.listActivitys=result.answerList;
           this.listActivitys.forEach(element => {
            if(element.relationalEntityType==='CLIENTE')
            {
              
              this.searchClient(element)

            }
          });
        }
      }
      ,error=>{
        this.messageCtrl.getInfoMessageError();
      }
    )
  }


  getActivitys(){
    let path='actividad/actividades-por-compania?companyId='+this.tokenServiceCtrl.getCompanyId();
    this.serviceCtrl.getModel(path).subscribe(
      result=>{
       
        if(result.code===200)
        {
          
          this.listActivitys=result.answerList;
          this.listActivitys.forEach(element => {
            if(element.relationalEntityType==='CLIENTE')
            {
              
              this.searchClient(element)

            }
          });
          this.dataLoad=true;
        }
      }
      ,error=>{
        this.messageCtrl.getInfoMessageError();
      }
    )
  }

  searchClient(activity)
  {
    let path='cliente/ver-por-id?clientId='+activity.relationalEntityId
    this.serviceCtrl.getModel(path).subscribe(
      result=>{
           if(result.code===200)
           {
             console.log(result);
            let client=result.genericObject;
            
            activity.relationalEntityType=client.nameOrBusinessName;
            console.log(activity);
           }
           else{
                   this.messageCtrl.getInfoMessageError();
           }
      },
      error=>{
        this.messageCtrl.getInfoMessageError();
      }
    )
  }

}
