export class HullBoatRiskModel {
    id: string;
    policyId: any;
    clientId: any;
    state: string;
    serie: string;
    model: string;
    brand: string;
    circulationZone: string;
    vrInsured: string;
    validSince: string;
    validUntil: string;
    classOfInsuranceId: any;
    creatorId: any;
    lastUpdateId: any;
    createdDate: string;
    lastUpdateDate: string;

    public constructor() {
        this.id = null;
        this.policyId = '';
        this.clientId = '';
        this.state = '';
        this.serie = '';
        this.model = '';
        this.brand = '';
        this.circulationZone = '';
        this.vrInsured = '';
        this.validSince = '';
        this.validUntil = '';
        this.classOfInsuranceId = '';
        this.creatorId = '';
        this.lastUpdateId = '';
        this.createdDate = '';
        this.lastUpdateDate = '';
    }
}
