import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommissionsRoutingModule } from './commissions-routing.module';
import { ManageCommissionsComponent } from './manage-commissions/manage-commissions.component';
import { SearchCommissionsComponent } from './search-commissions/search-commissions.component';
import { ListCommissionsComponent } from './list-commissions/list-commissions.component';
import { ResultCommissionsComponent } from './result-commissions/result-commissions.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule, NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ArchwizardModule } from 'angular-archwizard';
import { NgxLoadingModule } from 'ngx-loading';

@NgModule({
  declarations: [
    ManageCommissionsComponent, 
    SearchCommissionsComponent, 
    ListCommissionsComponent, 
    ResultCommissionsComponent],
  imports: [
    CommonModule,
    CommissionsRoutingModule,
    FormsModule,
    NgbModalModule,
    NgbModule,
    NgxDatatableModule,
    ArchwizardModule,
    NgxLoadingModule,
    ReactiveFormsModule
  ],
  exports: [
    ManageCommissionsComponent, 
    SearchCommissionsComponent, 
    ListCommissionsComponent, 
    ResultCommissionsComponent,
    NgxDatatableModule,
    FormsModule,
    NgxLoadingModule,
    NgbModule,
  ],
  entryComponents: [
    ResultCommissionsComponent
    ],
    providers: [
      NgbActiveModal
    ]
})
export class CommissionsModule { }
