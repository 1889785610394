import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import swal from 'sweetalert2';
import { ContractualCivilLiabilityShipmentRiskModel } from 'models/entities/contractual-civil-liability-shipment-risk.model';

@Component({
  selector: 'app-contractual-civil-liability-shipment-risk-list',
  templateUrl: './contractual-civil-liability-shipment-risk-list.component.html',
  styleUrls: ['./contractual-civil-liability-shipment-risk-list.component.scss']
})
export class ContractualCivilLiabilityShipmentRiskListComponent implements OnInit, OnChanges {
  @Input() policyId: any = 0;
  @Output() emitterContractualCivilLiabilityShipment: EventEmitter<any> = new EventEmitter();
  @Output() emitterActivityRecord: EventEmitter<any>;
  @Input() successfulUpdateFlag: any;

  excelFile = [];

  rows = [];
  temp: string[];
  errorMessage: string;
  dato: any;
  datoAux = new Array();

  arrayBuffer: any;
  file: File;

  columns = [
    { name: 'id', prop: 'id' },
    { name: 'policyId', prop: 'policyId' },
    { name: 'clientId', prop: 'clientId' },
    { name: 'state', prop: 'state' },
    { name: 'boatName', prop: 'boatName' },
    { name: 'patentNumber', prop: 'patentNumber' },
    { name: 'passengerCapacity', prop: 'passengerCapacity' },
    { name: 'operatingZone', prop: 'operatingZone' },
    { name: 'type', prop: 'type' },
    { name: 'service', prop: 'service' },
    { name: 'owner', prop: 'owner' },
    { name: 'di', prop: 'di' },
    { name: 'validSince', prop: 'validSince' },
    { name: 'validUntil', prop: 'validUntil' },
    { name: 'insuredValue', prop: 'insuredValue' },
  ];

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService
  ) {
    this.emitterActivityRecord = new EventEmitter();
  }

  ngOnInit() {
    this.getContractualCivilLiabilityShipmentRisk();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.successfulUpdateFlag !== undefined) {
      if (changes.successfulUpdateFlag.currentValue) {
        setTimeout(() => {
          this.ngOnInit();
        }, 500);
      }
    }
  }

  getContractualCivilLiabilityShipmentRisk() {
    if (this.policyId !== '') {
      setTimeout(() => {
        const path =
        // tslint:disable-next-line:max-line-length
        'riesgo-responsabilidad-civil-contractual-carga/listar-riesgos-responsabilidad-civil-contractual-carga-por-poliza?policyId=' + this.policyId;
        this.crudService.getModel(path).subscribe(
          (genericResponse: GenericResponseModel) => {
            if (genericResponse.code === 200) {
              this.datoAux = new Array();
              const risksAux = genericResponse.answerList;
              risksAux.forEach(element => {
                if (element.state === 'INCLUIDO') {
                  this.datoAux.push(element);
                }
              });
              risksAux.forEach(element => {
                if (element.state === 'EXCLUIDO') {
                  this.datoAux.push(element);
                }
              });
              this.dato = this.datoAux
              this.temp = [...this.dato];
              this.rows = this.dato;
            } else {
              this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los riesgos',
                'Problema consultando los riesgos');
            }
          },
          error => {
            console.error('Error al cargar los riesgos: ' + JSON.stringify(error))
            this.messageService.getInfoMessagePersonalized('warning', error.error.answer + '', 'Error al cargar los riesgos');
          }
        );
      }, 500);
    }
  }


  deleteRisk(id) {
    swal({
      title: '¿Está seguro que desea eliminar este riesgo?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.deleteRiskAction(id);
      }
    });
  }

  deleteRiskAction(id) {
    // tslint:disable-next-line:max-line-length
    const path = 'riesgo-responsabilidad-civil-contractual-carga/eliminar-riesgo-responsabilidad-civil-contractual-carga?id=' + id;
    this.crudService.deleteModel(path).subscribe(
      (data: GenericResponseModel) => {
        this.sendActivityRecord('Se eliminó el riesgo con No. Registro: ' + id);
        this.messageService.getInfoMessageDelete();
        this.getContractualCivilLiabilityShipmentRisk();
      },
      error => {
        this.messageService.getInfoMessagePersonalized('warning', error.error.answer + '', 'Problema con la eliminación');
        console.error('Error al borrar Riesgo Salud', error);
      }
    );
  }

  excludeRisk(ContractualCivilLiabilityShipmentToExclude: ContractualCivilLiabilityShipmentRiskModel) {
    const path = 'riesgo-responsabilidad-civil-contractual-carga/crear-riesgo-responsabilidad-civil-contractual-carga';
    ContractualCivilLiabilityShipmentToExclude.state = 'EXCLUIDO';
    this.crudService.createModel(path, ContractualCivilLiabilityShipmentToExclude).subscribe(
      (data: GenericResponseModel) => {
        if (data.code === 200) {
          this.sendActivityRecord('Se excluyó el riesgo con No. Registro: ' + ContractualCivilLiabilityShipmentToExclude.id);
          this.messageService.getInfoMessagePersonalized('success', 'Se excluyó el riesgo Salud de la póliza', 'Exclusión exitosa')
          this.ngOnInit();
        } else if (data.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', data.answer, 'No se creó el riesgo')
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized('error', error.error.answer + '', 'Error');
        console.error('El error es ', JSON.stringify(error));
      }
    );
  }

  getContractualCivilLiabilityShipmentToEdit(ContractualCivilLiabilityShipmentToEdit: ContractualCivilLiabilityShipmentRiskModel) {
    this.emitterContractualCivilLiabilityShipment.emit(ContractualCivilLiabilityShipmentToEdit);
  }

  sendActivityRecord(activity: any) {
    this.emitterActivityRecord.emit(activity);
  }

}
