export class ClientInfoRegisterModel {
    id: any;
    registerDate: any;
    completeName: any;
    email: any;
    cellphone: any;

    public constructor () {
        this.id = '';
        this.registerDate = '';
        this.completeName = '';
        this.email = '';
        this.cellphone = '';
    }

}
