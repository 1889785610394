import { UserModel } from 'models/entities/user-model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import swal from 'sweetalert2';
import { UpdateUserComponent } from '../update-user/update-user.component';
import { CreateUserComponent } from '../create-user/create-user.component';
import { ToPairProfileComponent } from '../to-pair-profile/to-pair-profile.component';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { PlansModel } from 'models/entities/plans-model';
import { DataSource } from 'ng2-smart-table/lib/data-source/data-source';
import { select } from '@ngrx/store';


@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent implements OnInit {

  rows = [];
  temp: [];
  board: string;
  errorMessage: string;
  dato: any;
  button = [];
  email: any;
  isSignedUp = false;
  isSignUpFailed = false;
  roleTitle = this.tokenStorage.getAuthorities();
  nombreEmpresa: any;

  columns = [
    { prop: 'completeName' },
    { name: 'id' },
    { prop: 'email' }
  ];
  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private crudServices: CrudServiceService,
    private modalService: NgbModal,
    private tokenStorage: TokenStorageService,
    private messageService: InfoMessagesService
  ) {
  }

  ngOnInit() {
    this.configPage();
    this.nombreEmpresa = this.tokenStorage.getCompanyName();
  }

  configPage() {
    if (this.tokenStorage.getAuthorities() === 'ROLE_SUPER') {
      this.crudServices.getModel('usuarios/super/listar-todos').subscribe(
        genericResponse => {
          if (genericResponse.code === 200) {
            console.log(genericResponse);
            this.dato = genericResponse.answerList;
            this.temp = this.dato;
            this.rows = this.dato;
            this.dato.forEach(element => {
              if(element.role==='ROLE_ADMIN')
              {
                element.role='Administrador'
              }
              else if(element.role==='ROLE_SELLER')
              {
                element.role='Vendedor'
              }
              else if(element.role==='ROLE_USER')
              {
                element.role='Gestor'
              }
              else{
                element.role='Superadministrador'
              }
            });
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron usuarios')
          }
        },
        error => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    } else {
      this.crudServices.getModel('usuarios/listar-vendedores-y-gestores').subscribe(
        genericResponse => {
          if (genericResponse.code === 200) {
            console.log(genericResponse);
            this.dato = genericResponse.answerList;
            this.temp = this.dato;
            this.rows = this.dato;
            this.dato.forEach(element => {
              if(element.role==='ROLE_ADMIN')
              {
                element.role='Administrador'
              }
              else if(element.role==='ROLE_SELLER')
              {
                element.role='Vendedor'
              }
              else if(element.role==='ROLE_USER')
              {
                element.role='Gestor'
              }
              else{
                element.role='Superadministrador'
              }
            });
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron usuarios')
          }
        }, error => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    }
  }

  onList(id) {
    this.crudServices.getModel('usuarios/buscar-por-id?userId=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          const modalRef = this.modalService.open(UpdateUserComponent, {
            windowClass: '', size: 'lg',
          });
          console.log(genericResponse.genericObject);
          modalRef.componentInstance.userInfoUpdate = genericResponse.genericObject;
          modalRef.componentInstance.isView = true;
        } else if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontro el usuario')
        }
      },
      error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }


  updateFilter(event){
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      return d.email.toLowerCase().indexOf(val) !== -1 || d.completeName.toLowerCase().indexOf(val) !== -1 || d.role.toLowerCase().indexOf(val) !== -1;

    });
    this.rows = temp;
    this.table.offset = 0;
  }

  onUpdateUser(id) {
    this.crudServices.getModel('usuarios/buscar-por-id?userId=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          const modalRef = this.modalService.open(UpdateUserComponent, {
            windowClass: '', size: 'lg',
          });
          modalRef.componentInstance.userInfoUpdate = genericResponse.genericObject;
          modalRef.componentInstance.passEntry.subscribe((userUpdated: any) => {
            console.log(userUpdated)
            const selectedPlan: any = userUpdated.activatedPlanId;
            if(selectedPlan!==null){
              userUpdated.activatedPlanId = new PlansModel()
              userUpdated.activatedPlanId.id = selectedPlan;
            }
    
            userUpdated.documentTypeId={
              id:userUpdated.documentTypeId
            };
            debugger;
            swal({
              title: '¿Está seguro que desea realizar la actualización?',
              showCancelButton: true,
              confirmButtonText: 'Aceptar',
              type: 'warning'
            }).then((result) => {
              if (result.value) {
                debugger;
                this.crudServices.createModel('usuarios/actualizar', userUpdated).subscribe(
                  genericUpdatedResponse => {
                    if (genericUpdatedResponse.code === 200) {
                      this.messageService.getInfoMessageUpdate().then(
                        (response) => {
                          this.configPage();
                          this.modalService.dismissAll();
                        }
                      )
                    } if (genericUpdatedResponse.code === 400) {
                      this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se realizo la actualización')
                    }
                  }, error => {
                    this.errorMessage = `${error.status}: ${error.error.message}`;
                    console.error(error);
                    this.messageService.getInfoMessageBadInternet();
                  })
              }
            });
          })
        } else if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontro el usuario')
        }
      },
      error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  onDelete(email) {
    this.crudServices.deleteModel('usuarios/borrar?correo=' + email).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.configPage();
        } else if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se elimino el usuario')
        }
      },
      error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        if (error.error.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', error.error.answer, 'Error eliminando el usuario')
        } else {
          this.messageService.getInfoMessageBadInternet();
        }
      }
    );
  }

  openRegister() {
    const modalRef = this.modalService.open(CreateUserComponent, {
      windowClass: 'modal', size: 'lg', backdrop: 'static'
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if (receivedEntry === 'Usuario Registrado') {
        this.configPage();
      } else {
        return false;
      }
    })
  }

  toPairProfile(id) {
    this.crudServices.getModel('usuarios/buscar-por-id?userId=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          const modalRef = this.modalService.open(ToPairProfileComponent, {
            windowClass: '', size: 'lg',
          });
          // Enviamos la información al modal hijo.
          modalRef.componentInstance.userInfoUpdate = genericResponse.genericObject;
        } else if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontro el usuario')
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  deleteWarning(email) {
    event.preventDefault();
    swal({
      title: '¿Está seguro que desea eliminar el registro?',
      text: 'No podrá recuperar el registro luego',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.onDelete(email);
      }
    })
  }

}
