
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { CrudServiceService } from '../../shared/backend/cruds/crud-service.service';
import { TokenStorageService } from '../../shared/storage-services/token-storage.service';
import { InfoMessagesService } from '../../shared/messages/info-messages.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { ComplementaryPlanRiskModel } from 'models/entities/complementary-plan-risk.model';
import swal from 'sweetalert2';

@Component({
  selector: 'app-complementary-plan-risk-list',
  templateUrl: './complementary-plan-risk-list.component.html',
  styleUrls: ['./complementary-plan-risk-list.component.scss']
})
export class ComplementaryPlanRiskListComponent implements OnInit, OnChanges {

  @Input() policyId: any = 0;
  @Output() emitterComplementaryPlan: EventEmitter<any> = new EventEmitter();
  @Output() emitterActivityRecord: EventEmitter<any>;
  @Input() successfulUpdateFlag: any;

  rows = [];
  temp: string[];
  errorMessage: string;
  dato: any;
  datoAux = new Array();

  columns = [
    { name: 'id', prop: 'id' },
    { name: 'policyId', prop: 'policyId' },
    { name: 'clientId', prop: 'clientId' },
    { name: 'state', prop: 'state' },
    { name: 'plan', prop: 'plan' },
    { name: 'validSince', prop: 'validSince' },
    { name: 'validUntil', prop: 'validUntil' },
    { name: 'insuredValue', prop: 'insuredValue' },
  ];

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService
  ) {
    this.emitterActivityRecord = new EventEmitter();
  }

  ngOnInit() {
    this.getComplementaryPlanRisks();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.successfulUpdateFlag !== undefined) {
      if (changes.successfulUpdateFlag.currentValue) {
        setTimeout(() => {
          this.ngOnInit();
        }, 500);
      }
    }
  }

  onCreate() {
  }

  getComplementaryPlanRisks() {
    if (this.policyId !== '') {
      setTimeout(() => {
        const pathListComplementaryPlanRisks = 'riesgo-plan-complementario/listar-riesgos-plan-complementario-por-poliza?policyId=' + this.policyId;
        this.crudService.getModel(pathListComplementaryPlanRisks).subscribe(
          (genericResponse: GenericResponseModel) => {
            if (genericResponse.code === 200) {
              this.datoAux = new Array();
              const risksAux = genericResponse.answerList;
              risksAux.forEach(element => {
                if (element.state === 'INCLUIDO') {
                  this.datoAux.push(element);
                }
              });
              risksAux.forEach(element => {
                if (element.state === 'EXCLUIDO') {
                  this.datoAux.push(element);
                }
              });
              this.dato = this.datoAux
              // this.dato.sort(function(risk1, risk2){return risk1.id - risk2.id;});
              this.temp = [...this.dato];
              this.rows = this.dato;
            } else {
              this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los riesgos',
                'Problema consultando los riesgos');
            }
          },
          error => {
            console.error('Error al cargar los riesgos: ' + JSON.stringify(error))
            this.messageService.getInfoMessagePersonalized('warning', error.error.answer + '', 'Error al cargar los riesgos');
          }
        );
      }, 500);
    }
  }

  deleteRisk(id) {
    swal({
      title: '¿Está seguro que desea eliminar este riesgo?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.deleteRiskAction(id);
      }
    });
  }

  deleteRiskAction(id) {
    const path = 'riesgo-plan-complementario/eliminar-riesgo-plan-complementario?id=' + id;
    this.crudService.deleteModel(path).subscribe(
      (data: GenericResponseModel) => {
        this.sendActivityRecord('Se eliminó el riesgo con No. Registro: ' + id);
        this.messageService.getInfoMessageDelete();
        this.getComplementaryPlanRisks();
      },
      error => {
        this.messageService.getInfoMessagePersonalized('warning', error.error.answer + '', 'Problema con la eliminación');
        console.error('Error al borrar Riesgo Plan Complementario', error);
      }
    );
  }

  excludeRisk(complementaryPlanToExclude: ComplementaryPlanRiskModel) {
    const path = 'riesgo-plan-complementario/crear-riesgo-plan-complementario';
    complementaryPlanToExclude.state = 'EXCLUIDO';
    this.crudService.createModel(path, complementaryPlanToExclude).subscribe(
      (data: GenericResponseModel) => {
        if (data.code === 200) {
          this.sendActivityRecord('Se excluyó el riesgo con No. Registro: ' + complementaryPlanToExclude.id);
          this.messageService.getInfoMessagePersonalized('success', 'Se excluyó el riesgo Plan Complementario de la póliza', 'Exclusión exitosa')
          this.ngOnInit();
        } else if (data.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', data.answer, 'No se creó el riesgo')
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized('error', error.error.answer + '', 'Error');
        console.error('El error es ', JSON.stringify(error));
      }
    );
  }

  getComplementaryPlanToEdit(complementaryPlanToEdit: ComplementaryPlanRiskModel) {
    this.emitterComplementaryPlan.emit(complementaryPlanToEdit);
    console.log(complementaryPlanToEdit);
  }

  sendActivityRecord(activity: any) {
    this.emitterActivityRecord.emit(activity);
  }
}
