import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  errorMessage: any;
  form: any = {};
  passwordValidationError = '';
  isErrorPassword = false;

  @ViewChild('f') planForm: NgForm;
  constructor(
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.form.newPasswordConfirm = '';
    this.form.newPassword = '';
    this.form.oldPassword = '';
  }

  changePassword() {

    if (this.form.newPassword === this.form.newPasswordConfirm) {
      const passwordInfoUpdate = {
        'idUser': '-1',
        'oldPassword': this.form.oldPassword,
        'newPassword': this.form.newPassword
      }
      this.crudServices.createModel('usuarios/actualizar/contrasena', passwordInfoUpdate).subscribe(
        genericResponse => {
          if (genericResponse.code === 200) {
            this.messageService.getInfoMessageUpdate().then(
              (response) => {
                this.modalService.dismissAll();
              }
            )
          } else if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Actualizacion fallida')
          }

        },
        error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
          this.errorMessage = JSON.stringify(error.error.mensaje);
        }
      );
    } else {
      this.passwordValidationError = '¡Las Contraseñas Nuevas No Coinciden!';
      this.isErrorPassword = true;
      setTimeout(() => { this.isErrorPassword = false; }, 3000);
    }
  }

  closeWindow() {
    this.modalService.dismissAll();
  }
}
