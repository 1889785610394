import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { ModuleModel } from 'models/entities/module-model';
import { AuthService } from './../../shared/auth/auth.service';
import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ListmoduleComponent } from '../listmodule/listmodule.component';
import swal from 'sweetalert2';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';

@Component({
  selector: 'app-createonly-module',
  templateUrl: './createonly-module.component.html',
  styleUrls: ['./createonly-module.component.scss']
})
export class CreateonlyModuleComponent implements OnInit {

  public moduleInfo = new ModuleModel()
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  public isCreatedModule = true;
  errorMessage: any;
  form: any = {};
  @ViewChild('f') createModuleForm: NgForm;


  constructor(
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private modalService: NgbModal) { }

  ngOnInit() {
  }

  onSubmit() {
    this.crudServices.createModel('modulos/super/crear', this.moduleInfo).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.isCreatedModule = true;
          this.modalService.dismissAll();
          this.passEntry.emit('Módulo Creado');
        }
        if (genericResponse.code === 400) {
          this.isCreatedModule = false;
          this.messageService.getInfoMessagePersonalized('error', genericResponse.answer, 'Fallo la creación')
        }
      },
      error => {
        console.log(error);
        this.isCreatedModule = false;
        this.errorMessage = error.error.answer;
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  closeWindow() {
    this.modalService.dismissAll();
  }


}
